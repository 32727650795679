/* eslint-disable */
import npaxios from '~/plugins/non-proxy-axios'
import common from '~/mixins/mixin'
import * as S3 from 'aws-sdk/clients/s3'
import * as Sentry from '~/plugins/sentry'

if (process.client) {
  window.dataLayer = window.dataLayer || [];
}

export const state = () => ({
  buckets: {
    addresses: {},
    users: {},
    items: {},
    stories: {},
    orders: {},
    variants: {},
    propertyValues: {},
    propertyTypes: {"1":{"id":1,"createdAt":1407673824000,"updatedAt":1463860681000,"name":"size","typeName":"CLOTHES_SIZES","description":"Clothes Sizes"},"2":{"id":2,"createdAt":1407673705000,"updatedAt":1463860709000,"name":"color","typeName":"COLORS","description":"Colors"},"3":{"id":3,"createdAt":1407673728000,"updatedAt":1463860686000,"name":"size","typeName":"SHOES_SIZES","description":"Shoe Sizes"},"4":{"id":4,"createdAt":1407673728000,"updatedAt":1463860686000,"name":"shirt colors","typeName":"SHIRT_COLORS","description":"Shirt Colors"},"5":{"id":5,"createdAt":1554823674000,"updatedAt":1554823674000,"name":"shirt sizes","typeName":"SHIRT_SIZES","description":"Shirt Sizes"},"6":{"id":6,"createdAt":1554823707000,"updatedAt":1554823707000,"name":"pants sizes","typeName":"PANTS_SIZES","description":"Pants Sizes"},"7":{"id":7,"createdAt":1589461322000,"updatedAt":1617786047000,"name":"men shirt sizes","typeName":"MEN_SHIRT_SIZES","description":"Men Sizes"},"8":{"id":8,"createdAt":1589461322000,"updatedAt":1617789495000,"name":"women shirt sizes","typeName":"WOMEN_SHIRT_SIZES","description":"Women Sizes"}},
    categories: [
      {
        "id": 2,
        "createdAt": 1423656792000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Short",
        "itemsNum": 0,
        "nameLower": "short",
        "pushDescriptor1": "short top",
        "pushDescriptor2": "this short top",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1154920,
        "itemId2": 1117946,
        "itemId3": 1154580
      },
      {
        "id": 5,
        "createdAt": 1423164396000,
        "updatedAt": 1649413849000,
        "image": null,
        "name": "Underwear",
        "itemsNum": 0,
        "nameLower": "underwear",
        "pushDescriptor1": "underwear",
        "pushDescriptor2": "this underwear",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1086327,
        "itemId2": 1078592,
        "itemId3": 1075818
      },
      {
        "id": 6,
        "createdAt": 1423164362000,
        "updatedAt": 1664015499000,
        "image": null,
        "name": "Swimwear",
        "itemsNum": 0,
        "nameLower": "swimwear",
        "pushDescriptor1": "swimsuit",
        "pushDescriptor2": "this swimsuit",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1154927,
        "itemId2": 1150714,
        "itemId3": 1150694
      },
      {
        "id": 7,
        "createdAt": 1435139631000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Joggers",
        "itemsNum": 0,
        "nameLower": "joggers",
        "pushDescriptor1": "joggers",
        "pushDescriptor2": "these joggers",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1154535,
        "itemId2": 1155325,
        "itemId3": 1090638
      },
      {
        "id": 8,
        "createdAt": 1423656730000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Skirts",
        "itemsNum": 0,
        "nameLower": "skirts",
        "pushDescriptor1": "skirt",
        "pushDescriptor2": "this skirt",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1139573,
        "itemId2": 1105267,
        "itemId3": 745731
      },
      {
        "id": 10,
        "createdAt": 1423236331000,
        "updatedAt": 1556819138000,
        "image": "http://files.parsetfss.com/5e76067c-8985-45fa-a6d4-073790b74d85/tfss-e620983e-3003-4e24-9c22-ea222d1355d9-Home.png",
        "name": "Home",
        "itemsNum": 0,
        "nameLower": "home",
        "pushDescriptor1": "household item",
        "pushDescriptor2": "this household item",
        "hideFromHomeFeed": true,
        "order": 6,
        "itemId1": 268073,
        "itemId2": 315163,
        "itemId3": 314895
      },
      {
        "id": 13,
        "createdAt": 1423654997000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Jackets & Coats",
        "itemsNum": 0,
        "nameLower": "jackets & coats",
        "pushDescriptor1": "jacket",
        "pushDescriptor2": "this jacket",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1154315,
        "itemId2": 1153571,
        "itemId3": 1155358
      },
      {
        "id": 15,
        "createdAt": 1444328465000,
        "updatedAt": 1664015499000,
        "image": null,
        "name": "Polo shirts",
        "itemsNum": 0,
        "nameLower": "polo shirts",
        "pushDescriptor1": "polo shirt",
        "pushDescriptor2": "this polo short",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1154895,
        "itemId2": 1155756,
        "itemId3": 1138992
      },
      {
        "id": 16,
        "createdAt": 1423656909000,
        "updatedAt": 1660905065000,
        "image": null,
        "name": "Sunglasses",
        "itemsNum": 0,
        "nameLower": "sunglasses",
        "pushDescriptor1": "sunglasses",
        "pushDescriptor2": "these sunglasses",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1141480,
        "itemId2": 1141972,
        "itemId3": 1124866
      },
      {
        "id": 17,
        "createdAt": 1423236246000,
        "updatedAt": 1568889029000,
        "image": null,
        "name": "Phone Accessories",
        "itemsNum": 0,
        "nameLower": "phone accessories",
        "pushDescriptor1": "phone accessory",
        "pushDescriptor2": "this phone accessory",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 475139,
        "itemId2": 357641,
        "itemId3": 357641
      },
      {
        "id": 18,
        "createdAt": 1423164387000,
        "updatedAt": 1663669866000,
        "image": null,
        "name": "Jackets & Coats",
        "itemsNum": 0,
        "nameLower": "jackets & coats",
        "pushDescriptor1": "coat",
        "pushDescriptor2": "this coat",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1153979,
        "itemId2": 944828,
        "itemId3": 1148368
      },
      {
        "id": 19,
        "createdAt": 1423236184000,
        "updatedAt": 1663151441000,
        "image": "http://files.parsetfss.com/5e76067c-8985-45fa-a6d4-073790b74d85/tfss-77e40843-4fe3-40d9-85a3-8eb57ccb59bc-Gadgets.png",
        "name": "Gadgets",
        "itemsNum": 0,
        "nameLower": "gadgets",
        "pushDescriptor1": "gadget",
        "pushDescriptor2": "this gadget",
        "hideFromHomeFeed": true,
        "order": 5,
        "itemId1": 1151740,
        "itemId2": 362707,
        "itemId3": 361555
      },
      {
        "id": 20,
        "createdAt": 1438260674000,
        "updatedAt": 1525257018000,
        "image": null,
        "name": "Kitchen ",
        "itemsNum": 0,
        "nameLower": "kitchen ",
        "pushDescriptor1": "kitchenware",
        "pushDescriptor2": "this kitchenware item",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 298735,
        "itemId2": 307413,
        "itemId3": 288466
      },
      {
        "id": 21,
        "createdAt": 1423164042000,
        "updatedAt": 1664101872000,
        "image": "http://files.parsetfss.com/5e76067c-8985-45fa-a6d4-073790b74d85/tfss-9425a46f-0ff0-4312-b1e3-f5d1a3fc8cf8-Men.jpg",
        "name": "Men's",
        "itemsNum": 0,
        "nameLower": "men's",
        "pushDescriptor1": "item",
        "pushDescriptor2": "this item",
        "hideFromHomeFeed": false,
        "order": 3,
        "itemId1": 1155386,
        "itemId2": 1155405,
        "itemId3": 1154563
      },
      {
        "id": 23,
        "createdAt": 1434285425000,
        "updatedAt": 1545561014000,
        "image": null,
        "name": "Fragrance",
        "itemsNum": 0,
        "nameLower": "fragrance",
        "pushDescriptor1": "perfume",
        "pushDescriptor2": "this perfume",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 235423,
        "itemId2": 271955,
        "itemId3": 20016
      },
      {
        "id": 24,
        "createdAt": 1423655117000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Short Sleeve",
        "itemsNum": 0,
        "nameLower": "short sleeve",
        "pushDescriptor1": "short sleeve shirt",
        "pushDescriptor2": "this short sleeve shirt",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1154563,
        "itemId2": 1155325,
        "itemId3": 1154939
      },
      {
        "id": 25,
        "createdAt": 1423237042000,
        "updatedAt": 1664101872000,
        "image": "http://files.parsetfss.com/5e76067c-8985-45fa-a6d4-073790b74d85/tfss-7337870d-730b-4bad-8343-cf94bd499a1c-Recommended.png",
        "name": "Recommended",
        "itemsNum": 0,
        "nameLower": "recommended",
        "pushDescriptor1": "recommended item",
        "pushDescriptor2": "this recommended item",
        "hideFromHomeFeed": false,
        "order": 1,
        "itemId1": 1155386,
        "itemId2": 1155405,
        "itemId3": 1154563
      },
      {
        "id": 26,
        "createdAt": 1423236082000,
        "updatedAt": 1663497061000,
        "image": null,
        "name": "Glasses",
        "itemsNum": 0,
        "nameLower": "glasses",
        "pushDescriptor1": "glasses",
        "pushDescriptor2": "these glasses",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 854347,
        "itemId2": 890248,
        "itemId3": 890248
      },
      {
        "id": 28,
        "createdAt": 1423166390000,
        "updatedAt": 1649068267000,
        "image": null,
        "name": "Boys",
        "itemsNum": 0,
        "nameLower": "boys",
        "pushDescriptor1": "boy's item",
        "pushDescriptor2": "this boy's item",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 537126,
        "itemId2": 533987,
        "itemId3": 473808
      },
      {
        "id": 29,
        "createdAt": 1423656745000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Jeans",
        "itemsNum": 0,
        "nameLower": "jeans",
        "pushDescriptor1": "jeans",
        "pushDescriptor2": "this pair of jeans",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1155405,
        "itemId2": 1154563,
        "itemId3": 1155777
      },
      {
        "id": 30,
        "createdAt": 1434285435000,
        "updatedAt": 1491141335000,
        "image": null,
        "name": "Skin Care",
        "itemsNum": 0,
        "nameLower": "skin care",
        "pushDescriptor1": "skin care cream",
        "pushDescriptor2": "this cosmetics item",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": null,
        "itemId2": null,
        "itemId3": null
      },
      {
        "id": 31,
        "createdAt": 1423164346000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Tops",
        "itemsNum": 0,
        "nameLower": "tops",
        "pushDescriptor1": "top",
        "pushDescriptor2": "this top",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1152408,
        "itemId2": 1154329,
        "itemId3": 1115906
      },
      {
        "id": 32,
        "createdAt": 1434285179000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Bras, Panties & Shapewear",
        "itemsNum": 0,
        "nameLower": "bras, panties & shapewear",
        "pushDescriptor1": "underwear",
        "pushDescriptor2": "this underwear item",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1154914,
        "itemId2": 1154548,
        "itemId3": 1149571
      },
      {
        "id": 33,
        "createdAt": 1423236294000,
        "updatedAt": 1541673016000,
        "image": null,
        "name": "Other",
        "itemsNum": 0,
        "nameLower": "other",
        "pushDescriptor1": "special item",
        "pushDescriptor2": "this item",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 374870,
        "itemId2": 359405,
        "itemId3": 361479
      },
      {
        "id": 34,
        "createdAt": 1437567508000,
        "updatedAt": 1663929069000,
        "image": null,
        "name": "Chinos",
        "itemsNum": 0,
        "nameLower": "chinos",
        "pushDescriptor1": "chinos",
        "pushDescriptor2": "this pair of chinos",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1153547,
        "itemId2": 1154912,
        "itemId3": 1154543
      },
      {
        "id": 35,
        "createdAt": 1424796324000,
        "updatedAt": 1613045186000,
        "image": null,
        "name": "Dress Shirts",
        "itemsNum": 0,
        "nameLower": "dress shirts",
        "pushDescriptor1": "dress shirt",
        "pushDescriptor2": "this dress shirt",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 317491,
        "itemId2": 325246,
        "itemId3": 321920
      },
      {
        "id": 36,
        "createdAt": 1424069961000,
        "updatedAt": 1663842659000,
        "image": null,
        "name": "Necklace",
        "itemsNum": 0,
        "nameLower": "necklace",
        "pushDescriptor1": "necklace",
        "pushDescriptor2": "this necklace",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1154257,
        "itemId2": 1154555,
        "itemId3": 1150696
      },
      {
        "id": 37,
        "createdAt": 1423235983000,
        "updatedAt": 1651919454000,
        "image": null,
        "name": "Sandals",
        "itemsNum": 0,
        "nameLower": "sandals",
        "pushDescriptor1": "sandals",
        "pushDescriptor2": "these sandals",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1104368,
        "itemId2": 465243,
        "itemId3": 461825
      },
      {
        "id": 38,
        "createdAt": 1423164265000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Shoes",
        "itemsNum": 0,
        "nameLower": "shoes",
        "pushDescriptor1": "shoes",
        "pushDescriptor2": "this pair of shoes",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1153925,
        "itemId2": 1149580,
        "itemId3": 1097878
      },
      {
        "id": 39,
        "createdAt": 1423164091000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Accessories",
        "itemsNum": 0,
        "nameLower": "accessories",
        "pushDescriptor1": "accessory",
        "pushDescriptor2": "this accessory",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 688287,
        "itemId2": 1155334,
        "itemId3": 821066
      },
      {
        "id": 40,
        "createdAt": 1423655088000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "T-Shirt",
        "itemsNum": 0,
        "nameLower": "t-shirt",
        "pushDescriptor1": "T-Shirt",
        "pushDescriptor2": "this T-shirt",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1155405,
        "itemId2": 1154563,
        "itemId3": 1155325
      },
      {
        "id": 42,
        "createdAt": 1423236109000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Others",
        "itemsNum": 0,
        "nameLower": "others",
        "pushDescriptor1": "special item",
        "pushDescriptor2": "this special item",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1054768,
        "itemId2": 928110,
        "itemId3": 950359
      },
      {
        "id": 43,
        "createdAt": 1423655012000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Pants",
        "itemsNum": 0,
        "nameLower": "pants",
        "pushDescriptor1": "pants",
        "pushDescriptor2": "these pants",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1154315,
        "itemId2": 969878,
        "itemId3": 1155405
      },
      {
        "id": 44,
        "createdAt": 1423656878000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Bags",
        "itemsNum": 0,
        "nameLower": "bags",
        "pushDescriptor1": "bag",
        "pushDescriptor2": "this bag",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 976031,
        "itemId2": 828960,
        "itemId3": 991627
      },
      {
        "id": 45,
        "createdAt": 1441281055000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Sweaters",
        "itemsNum": 0,
        "nameLower": "sweaters",
        "pushDescriptor1": "sweater",
        "pushDescriptor2": "this sweater",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1154912,
        "itemId2": 1154559,
        "itemId3": 1154912
      },
      {
        "id": 48,
        "createdAt": 1423236149000,
        "updatedAt": 1613046895000,
        "image": null,
        "name": "Beauty & Perfumes",
        "itemsNum": 0,
        "nameLower": "beauty & perfumes",
        "pushDescriptor1": "cosmetics",
        "pushDescriptor2": "this beauty item",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 74402,
        "itemId2": 74402,
        "itemId3": 21146
      },
      {
        "id": 49,
        "createdAt": 1423164104000,
        "updatedAt": 1613046904000,
        "image": null,
        "name": "Beauty",
        "itemsNum": 0,
        "nameLower": "beauty",
        "pushDescriptor1": "beauty item",
        "pushDescriptor2": "this beauty item",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 235423,
        "itemId2": 271955,
        "itemId3": 20016
      },
      {
        "id": 50,
        "createdAt": 1423236273000,
        "updatedAt": 1529231421000,
        "image": null,
        "name": "Laptop Accessories",
        "itemsNum": 0,
        "nameLower": "laptop accessories",
        "pushDescriptor1": "laptop accessory",
        "pushDescriptor2": "this laptop accessory",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 331319,
        "itemId2": 318177,
        "itemId3": 317493
      },
      {
        "id": 53,
        "createdAt": 1423164072000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Clothing",
        "itemsNum": 0,
        "nameLower": "clothing",
        "pushDescriptor1": "clothing item",
        "pushDescriptor2": "this clothing item",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1154315,
        "itemId2": 1155386,
        "itemId3": 1153571
      },
      {
        "id": 57,
        "createdAt": 1434285452000,
        "updatedAt": 1491141639000,
        "image": null,
        "name": "Make Up",
        "itemsNum": 0,
        "nameLower": "make up",
        "pushDescriptor1": "make up item",
        "pushDescriptor2": "this make up item",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": null,
        "itemId2": null,
        "itemId3": null
      },
      {
        "id": 58,
        "createdAt": 1423235960000,
        "updatedAt": 1659522642000,
        "image": null,
        "name": "Dress",
        "itemsNum": 0,
        "nameLower": "dress",
        "pushDescriptor1": "dress",
        "pushDescriptor2": "this dress",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1134463,
        "itemId2": 1133223,
        "itemId3": 650569
      },
      {
        "id": 59,
        "createdAt": 1423166363000,
        "updatedAt": 1649068267000,
        "image": null,
        "name": "Kids",
        "itemsNum": 0,
        "nameLower": "kids",
        "pushDescriptor1": "kids' item",
        "pushDescriptor2": "this kids' item",
        "hideFromHomeFeed": true,
        "order": 7,
        "itemId1": 537126,
        "itemId2": 533987,
        "itemId3": 473808
      },
      {
        "id": 60,
        "createdAt": 1424070930000,
        "updatedAt": 1661423441000,
        "image": null,
        "name": "Rings",
        "itemsNum": 0,
        "nameLower": "rings",
        "pushDescriptor1": "ring",
        "pushDescriptor2": "this ring",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1141110,
        "itemId2": 1097037,
        "itemId3": 1124368
      },
      {
        "id": 63,
        "createdAt": 1423236256000,
        "updatedAt": 1663151441000,
        "image": null,
        "name": "Audio",
        "itemsNum": 0,
        "nameLower": "audio",
        "pushDescriptor1": "audio item",
        "pushDescriptor2": "this audio item",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1151740,
        "itemId2": 350433,
        "itemId3": 348832
      },
      {
        "id": 64,
        "createdAt": 1423236041000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Watches",
        "itemsNum": 0,
        "nameLower": "watches",
        "pushDescriptor1": "watch",
        "pushDescriptor2": "this watch",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1153582,
        "itemId2": 776526,
        "itemId3": 1043340
      },
      {
        "id": 65,
        "createdAt": 1423656914000,
        "updatedAt": 1655375454000,
        "image": null,
        "name": "Hats",
        "itemsNum": 0,
        "nameLower": "hats",
        "pushDescriptor1": "hat",
        "pushDescriptor2": "this hat",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1118515,
        "itemId2": 451337,
        "itemId3": 448560
      },
      {
        "id": 66,
        "createdAt": 1434284794000,
        "updatedAt": 1491141748000,
        "image": null,
        "name": "Hair",
        "itemsNum": 0,
        "nameLower": "hair",
        "pushDescriptor1": "hair item ",
        "pushDescriptor2": "this hair item ",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": null,
        "itemId2": null,
        "itemId3": null
      },
      {
        "id": 67,
        "createdAt": 1434285357000,
        "updatedAt": 1663842659000,
        "image": null,
        "name": "Tanks",
        "itemsNum": 0,
        "nameLower": "tanks",
        "pushDescriptor1": "tank top",
        "pushDescriptor2": "this tank top",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1115906,
        "itemId2": 1115283,
        "itemId3": 1154629
      },
      {
        "id": 68,
        "createdAt": 1423164012000,
        "updatedAt": 1664101872000,
        "image": "http://files.parsetfss.com/5e76067c-8985-45fa-a6d4-073790b74d85/tfss-8d3529ee-1515-4540-bda4-e29850e7e3df-Women.jpg",
        "name": "Women's",
        "itemsNum": 0,
        "nameLower": "women's",
        "pushDescriptor1": "item",
        "pushDescriptor2": "this item",
        "hideFromHomeFeed": false,
        "order": 4,
        "itemId1": 766339,
        "itemId2": 745656,
        "itemId3": 757763
      },
      {
        "id": 69,
        "createdAt": 1423655054000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Sweatshirts",
        "itemsNum": 0,
        "nameLower": "sweatshirts",
        "pushDescriptor1": "sweatshirt",
        "pushDescriptor2": "this sweatshirt",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1155386,
        "itemId2": 1155777,
        "itemId3": 1110912
      },
      {
        "id": 70,
        "createdAt": 1423235972000,
        "updatedAt": 1663929069000,
        "image": null,
        "name": "Boots",
        "itemsNum": 0,
        "nameLower": "boots",
        "pushDescriptor1": "boots",
        "pushDescriptor2": "these boots",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1154996,
        "itemId2": 1153604,
        "itemId3": 1151774
      },
      {
        "id": 72,
        "createdAt": 1434285135000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Jumpsuits & Rompers",
        "itemsNum": 0,
        "nameLower": "jumpsuits & rompers",
        "pushDescriptor1": "jumpsuit",
        "pushDescriptor2": "this jumpsuit",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 766339,
        "itemId2": 745656,
        "itemId3": 757763
      },
      {
        "id": 73,
        "createdAt": 1423236093000,
        "updatedAt": 1663929069000,
        "image": null,
        "name": "Belts",
        "itemsNum": 0,
        "nameLower": "belts",
        "pushDescriptor1": "belt",
        "pushDescriptor2": "this belt",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1154925,
        "itemId2": 1153555,
        "itemId3": 1134153
      },
      {
        "id": 74,
        "createdAt": 1433930247000,
        "updatedAt": 1500201019000,
        "image": null,
        "name": "Women's",
        "itemsNum": 0,
        "nameLower": "women's",
        "pushDescriptor1": "item",
        "pushDescriptor2": "this item",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 180933,
        "itemId2": 181245,
        "itemId3": 181245
      },
      {
        "id": 76,
        "createdAt": 1447695106000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Sweats and Hoodie",
        "itemsNum": 0,
        "nameLower": "sweats and hoodies",
        "pushDescriptor1": "hoodie",
        "pushDescriptor2": "this hoodie",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1064904,
        "itemId2": 764699,
        "itemId3": 1074180
      },
      {
        "id": 78,
        "createdAt": 1423236287000,
        "updatedAt": 1526466620000,
        "image": null,
        "name": "Camera",
        "itemsNum": 0,
        "nameLower": "camera",
        "pushDescriptor1": "camera item",
        "pushDescriptor2": "this photography item",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 321888,
        "itemId2": 267993,
        "itemId3": 256909
      },
      {
        "id": 79,
        "createdAt": 1423164356000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Bottoms",
        "itemsNum": 0,
        "nameLower": "bottoms",
        "pushDescriptor1": "bottoms",
        "pushDescriptor2": "these bottoms",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1155341,
        "itemId2": 1153917,
        "itemId3": 850258
      },
      {
        "id": 80,
        "createdAt": 1423287318000,
        "updatedAt": 1663929069000,
        "image": null,
        "name": "Caps",
        "itemsNum": 0,
        "nameLower": "caps",
        "pushDescriptor1": "cap",
        "pushDescriptor2": "this cap",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 859303,
        "itemId2": 508987,
        "itemId3": 1068196
      },
      {
        "id": 83,
        "createdAt": 1423656985000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "High Heels",
        "itemsNum": 0,
        "nameLower": "high heels",
        "pushDescriptor1": "high heel shoes",
        "pushDescriptor2": "this pair of high heel shoes",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1154296,
        "itemId2": 1151093,
        "itemId3": 1155770
      },
      {
        "id": 84,
        "createdAt": 1423164340000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Dresses",
        "itemsNum": 0,
        "nameLower": "dresses",
        "pushDescriptor1": "dress",
        "pushDescriptor2": "this dress",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1153576,
        "itemId2": 1120977,
        "itemId3": 1155800
      },
      {
        "id": 85,
        "createdAt": 1423236062000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Jewlery",
        "itemsNum": 0,
        "nameLower": "jewlery",
        "pushDescriptor1": "jewlery",
        "pushDescriptor2": "some jewlery",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 688287,
        "itemId2": 1155334,
        "itemId3": 821066
      },
      {
        "id": 86,
        "createdAt": 1424069976000,
        "updatedAt": 1664015499000,
        "image": null,
        "name": "Bracelet",
        "itemsNum": 0,
        "nameLower": "bracelet",
        "pushDescriptor1": "bracelet",
        "pushDescriptor2": "this bracelet",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1145969,
        "itemId2": 1151072,
        "itemId3": 1121632
      },
      {
        "id": 88,
        "createdAt": 1424069985000,
        "updatedAt": 1664015499000,
        "image": null,
        "name": "Earrings",
        "itemsNum": 0,
        "nameLower": "earrings",
        "pushDescriptor1": "earrings",
        "pushDescriptor2": "these earrings",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1151731,
        "itemId2": 1099665,
        "itemId3": 1099665
      },
      {
        "id": 89,
        "createdAt": 1423655072000,
        "updatedAt": 1663929069000,
        "image": null,
        "name": "Shorts",
        "itemsNum": 0,
        "nameLower": "shorts",
        "pushDescriptor1": "shorts",
        "pushDescriptor2": "these shorts",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1110912,
        "itemId2": 1154976,
        "itemId3": 1152941
      },
      {
        "id": 90,
        "createdAt": 1423236012000,
        "updatedAt": 1614162628000,
        "image": null,
        "name": "Sports",
        "itemsNum": 0,
        "nameLower": "sports",
        "pushDescriptor1": "sport item",
        "pushDescriptor2": "this sport item",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 815834,
        "itemId2": 318900,
        "itemId3": 341804
      },
      {
        "id": 91,
        "createdAt": 1423655125000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Long Sleeve",
        "itemsNum": 0,
        "nameLower": "long sleeve",
        "pushDescriptor1": "long sleeve shirt",
        "pushDescriptor2": "this long sleeve shirt",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1155405,
        "itemId2": 1154992,
        "itemId3": 1154949
      },
      {
        "id": 93,
        "createdAt": 1423235949000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Sneakers",
        "itemsNum": 0,
        "nameLower": "sneakers",
        "pushDescriptor1": "sneakers",
        "pushDescriptor2": "these sneakers",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1153925,
        "itemId2": 1149580,
        "itemId3": 1097878
      },
      {
        "id": 95,
        "createdAt": 1423655026000,
        "updatedAt": 1664015499000,
        "image": null,
        "name": "Shirts",
        "itemsNum": 0,
        "nameLower": "shirts",
        "pushDescriptor1": "shirt",
        "pushDescriptor2": "this shirt",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1154573,
        "itemId2": 1154965,
        "itemId3": 1154965
      },
      {
        "id": 96,
        "createdAt": 1423236073000,
        "updatedAt": 1663756252000,
        "image": null,
        "name": "Wallets",
        "itemsNum": 0,
        "nameLower": "wallets",
        "pushDescriptor1": "wallet",
        "pushDescriptor2": "this wallet",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 932233,
        "itemId2": 976499,
        "itemId3": 1005269
      },
      {
        "id": 97,
        "createdAt": 1423657000000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Flats",
        "itemsNum": 0,
        "nameLower": "flats",
        "pushDescriptor1": "flats",
        "pushDescriptor2": "this pair of flats",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1154556,
        "itemId2": 1149570,
        "itemId3": 1145171
      },
      {
        "id": 100,
        "createdAt": 1487165190000,
        "updatedAt": 1594816513000,
        "image": "http://files.parsetfss.com/5e76067c-8985-45fa-a6d4-073790b74d85/tfss-8d3529ee-1515-4540-bda4-e29850e7e3df-Women.jpg",
        "name": "Plus Size (New)",
        "itemsNum": 0,
        "nameLower": "plus size (new)",
        "pushDescriptor1": "plus size item",
        "pushDescriptor2": "this plus size item",
        "hideFromHomeFeed": false,
        "order": 8,
        "itemId1": 243495,
        "itemId2": 242093,
        "itemId3": 242007
      },
      {
        "id": 101,
        "createdAt": 1487165190000,
        "updatedAt": 1556819148000,
        "image": null,
        "name": "Subscriptions",
        "itemsNum": 0,
        "nameLower": "subscriptions",
        "pushDescriptor1": "subscriptions item",
        "pushDescriptor2": "this subscriptions item",
        "hideFromHomeFeed": false,
        "order": 9,
        "itemId1": 243922,
        "itemId2": null,
        "itemId3": null
      },
      {
        "id": 102,
        "createdAt": 1561903698000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Matching Sets",
        "itemsNum": null,
        "nameLower": "matching sets",
        "pushDescriptor1": "matching sets",
        "pushDescriptor2": "this matching set",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1154315,
        "itemId2": 1155386,
        "itemId3": 1153571
      },
      {
        "id": 103,
        "createdAt": 1561903831000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Shirt & Pants Sets",
        "itemsNum": null,
        "nameLower": "shirt & pant set",
        "pushDescriptor1": "shirt & pants set",
        "pushDescriptor2": "this set",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1154315,
        "itemId2": 1153571,
        "itemId3": 969878
      },
      {
        "id": 104,
        "createdAt": 1561903896000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Tracksuits",
        "itemsNum": null,
        "nameLower": "tracksuits",
        "pushDescriptor1": "tracksuit",
        "pushDescriptor2": "this tracksuit",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1155386,
        "itemId2": 1153543,
        "itemId3": 1090638
      },
      {
        "id": 105,
        "createdAt": 1561903907000,
        "updatedAt": 1664101872000,
        "image": null,
        "name": "Shorts Sets",
        "itemsNum": null,
        "nameLower": "shorts sets",
        "pushDescriptor1": "shorts set",
        "pushDescriptor2": "this shorts set",
        "hideFromHomeFeed": false,
        "order": null,
        "itemId1": 1110912,
        "itemId2": 1152083,
        "itemId3": 1093572
      }
    ],
    categoryTree: [
      {
        "id": 4,
        "createdAt": 1434285453000,
        "updatedAt": 1463867223000,
        "parentId": 15,
        "order": null,
        "categoryId": 57
      },
      {
        "id": 6,
        "createdAt": 1423656968000,
        "updatedAt": 1463867223000,
        "parentId": 70,
        "order": null,
        "categoryId": 93
      },
      {
        "id": 12,
        "createdAt": 1423236273000,
        "updatedAt": 1463867223000,
        "parentId": 60,
        "order": null,
        "categoryId": 50
      },
      {
        "id": 14,
        "createdAt": 1423236073000,
        "updatedAt": 1463867223000,
        "parentId": 13,
        "order": null,
        "categoryId": 96
      },
      {
        "id": 16,
        "createdAt": 1423656985000,
        "updatedAt": 1463867223000,
        "parentId": 70,
        "order": null,
        "categoryId": 83
      },
      {
        "id": 18,
        "createdAt": 1423236295000,
        "updatedAt": 1463867223000,
        "parentId": 60,
        "order": null,
        "categoryId": 33
      },
      {
        "id": 20,
        "createdAt": 1423236288000,
        "updatedAt": 1463867224000,
        "parentId": 60,
        "order": null,
        "categoryId": 78
      },
      {
        "id": 22,
        "createdAt": 1424069986000,
        "updatedAt": 1463867223000,
        "parentId": 57,
        "order": null,
        "categoryId": 88
      },
      {
        "id": 24,
        "createdAt": 1423236042000,
        "updatedAt": 1463867223000,
        "parentId": 13,
        "order": null,
        "categoryId": 64
      },
      {
        "id": 25,
        "createdAt": 1423236083000,
        "updatedAt": 1463867223000,
        "parentId": 13,
        "order": null,
        "categoryId": 26
      },
      {
        "id": 28,
        "createdAt": 1423236152000,
        "updatedAt": 1463867223000,
        "parentId": 13,
        "order": null,
        "categoryId": 48
      },
      {
        "id": 36,
        "createdAt": 1434285426000,
        "updatedAt": 1463867223000,
        "parentId": 15,
        "order": null,
        "categoryId": 23
      },
      {
        "id": 37,
        "createdAt": 1427297875000,
        "updatedAt": 1463867223000,
        "parentId": 1,
        "order": null,
        "categoryId": 21
      },
      {
        "id": 39,
        "createdAt": 1423236247000,
        "updatedAt": 1463867223000,
        "parentId": 60,
        "order": null,
        "categoryId": 17
      },
      {
        "id": 42,
        "createdAt": 1423656975000,
        "updatedAt": 1463867223000,
        "parentId": 70,
        "order": null,
        "categoryId": 70
      },
      {
        "id": 43,
        "createdAt": 1427297930000,
        "updatedAt": 1463867223000,
        "parentId": 1,
        "order": null,
        "categoryId": 68
      },
      {
        "id": 44,
        "createdAt": 1438260677000,
        "updatedAt": 1463867223000,
        "parentId": 60,
        "order": null,
        "categoryId": 20
      },
      {
        "id": 45,
        "createdAt": 1423656810000,
        "updatedAt": 1463867223000,
        "parentId": 11,
        "order": null,
        "categoryId": 91
      },
      {
        "id": 46,
        "createdAt": 1423656745000,
        "updatedAt": 1463867223000,
        "parentId": 77,
        "order": null,
        "categoryId": 29
      },
      {
        "id": 48,
        "createdAt": 1437567508000,
        "updatedAt": 1463867223000,
        "parentId": 88,
        "order": null,
        "categoryId": 34
      },
      {
        "id": 49,
        "createdAt": 1423656792000,
        "updatedAt": 1463867223000,
        "parentId": 11,
        "order": null,
        "categoryId": 2
      },
      {
        "id": 53,
        "createdAt": 1424070930000,
        "updatedAt": 1463867223000,
        "parentId": 57,
        "order": null,
        "categoryId": 60
      },
      {
        "id": 55,
        "createdAt": 1435140844000,
        "updatedAt": 1463867223000,
        "parentId": 13,
        "order": null,
        "categoryId": 44
      },
      {
        "id": 56,
        "createdAt": 1424070030000,
        "updatedAt": 1463867223000,
        "parentId": 70,
        "order": null,
        "categoryId": 90
      },
      {
        "id": 61,
        "createdAt": 1423236257000,
        "updatedAt": 1463867223000,
        "parentId": 60,
        "order": null,
        "categoryId": 63
      },
      {
        "id": 62,
        "createdAt": 1434285464000,
        "updatedAt": 1463867223000,
        "parentId": 15,
        "order": null,
        "categoryId": 42
      },
      {
        "id": 64,
        "createdAt": 1423236063000,
        "updatedAt": 1463867223000,
        "parentId": 13,
        "order": null,
        "categoryId": 85
      },
      {
        "id": 65,
        "createdAt": 1423657001000,
        "updatedAt": 1463867223000,
        "parentId": 70,
        "order": null,
        "categoryId": 97
      },
      {
        "id": 66,
        "createdAt": 1424796324000,
        "updatedAt": 1463867223000,
        "parentId": 11,
        "order": null,
        "categoryId": 35
      },
      {
        "id": 71,
        "createdAt": 1424069962000,
        "updatedAt": 1463867223000,
        "parentId": 57,
        "order": null,
        "categoryId": 36
      },
      {
        "id": 72,
        "createdAt": 1434285436000,
        "updatedAt": 1463867223000,
        "parentId": 15,
        "order": null,
        "categoryId": 30
      },
      {
        "id": 73,
        "createdAt": 1423287318000,
        "updatedAt": 1463867223000,
        "parentId": 13,
        "order": null,
        "categoryId": 80
      },
      {
        "id": 76,
        "createdAt": 1423656759000,
        "updatedAt": 1463867223000,
        "parentId": 77,
        "order": null,
        "categoryId": 89
      },
      {
        "id": 78,
        "createdAt": 1447688668000,
        "updatedAt": 1463867236000,
        "parentId": 77,
        "order": null,
        "categoryId": 7
      },
      {
        "id": 79,
        "createdAt": 1424069976000,
        "updatedAt": 1463867223000,
        "parentId": 57,
        "order": null,
        "categoryId": 86
      },
      {
        "id": 83,
        "createdAt": 1434285357000,
        "updatedAt": 1463867223000,
        "parentId": 11,
        "order": null,
        "categoryId": 67
      },
      {
        "id": 84,
        "createdAt": 1423656752000,
        "updatedAt": 1463867223000,
        "parentId": 77,
        "order": null,
        "categoryId": 43
      },
      {
        "id": 87,
        "createdAt": 1423236109000,
        "updatedAt": 1463867223000,
        "parentId": 13,
        "order": null,
        "categoryId": 42
      },
      {
        "id": 93,
        "createdAt": 1487165278000,
        "updatedAt": 1487165278000,
        "parentId": 92,
        "order": null,
        "categoryId": 21
      },
      {
        "id": 125,
        "createdAt": 1487165278000,
        "updatedAt": 1487165278000,
        "parentId": 92,
        "order": null,
        "categoryId": 74
      },
      {
        "id": 5,
        "createdAt": 1425225304000,
        "updatedAt": 1463867223000,
        "parentId": 3,
        "order": 0,
        "categoryId": 53
      },
      {
        "id": 34,
        "createdAt": 1423656879000,
        "updatedAt": 1463867223000,
        "parentId": 54,
        "order": 0,
        "categoryId": 44
      },
      {
        "id": 41,
        "createdAt": 1423235951000,
        "updatedAt": 1463867223000,
        "parentId": 81,
        "order": 0,
        "categoryId": 93
      },
      {
        "id": 51,
        "createdAt": 1423164340000,
        "updatedAt": 1463867223000,
        "parentId": 68,
        "order": 0,
        "categoryId": 84
      },
      {
        "id": 59,
        "createdAt": 1425225526000,
        "updatedAt": 1463867223000,
        "parentId": 5,
        "order": 0,
        "categoryId": 40
      },
      {
        "id": 68,
        "createdAt": 1423164073000,
        "updatedAt": 1463867223000,
        "parentId": 52,
        "order": 0,
        "categoryId": 53
      },
      {
        "id": 75,
        "createdAt": 1435139641000,
        "updatedAt": 1463867223000,
        "parentId": 88,
        "order": 0,
        "categoryId": 29
      },
      {
        "id": 80,
        "createdAt": 1425225849000,
        "updatedAt": 1463867223000,
        "parentId": 59,
        "order": 0,
        "categoryId": 24
      },
      {
        "id": 1,
        "createdAt": 1425475292000,
        "updatedAt": 1463867223000,
        "parentId": null,
        "order": 1,
        "categoryId": 25
      },
      {
        "id": 9,
        "createdAt": 1425225862000,
        "updatedAt": 1463867223000,
        "parentId": 59,
        "order": 1,
        "categoryId": 91
      },
      {
        "id": 19,
        "createdAt": 1435139631000,
        "updatedAt": 1463867223000,
        "parentId": 88,
        "order": 1,
        "categoryId": 7
      },
      {
        "id": 32,
        "createdAt": 1434285136000,
        "updatedAt": 1463867223000,
        "parentId": 68,
        "order": 1,
        "categoryId": 72
      },
      {
        "id": 57,
        "createdAt": 1423656892000,
        "updatedAt": 1463867223000,
        "parentId": 54,
        "order": 1,
        "categoryId": 85
      },
      {
        "id": 58,
        "createdAt": 1423235961000,
        "updatedAt": 1463867223000,
        "parentId": 81,
        "order": 1,
        "categoryId": 58
      },
      {
        "id": 70,
        "createdAt": 1423164266000,
        "updatedAt": 1463867223000,
        "parentId": 52,
        "order": 1,
        "categoryId": 38
      },
      {
        "id": 81,
        "createdAt": 1423164309000,
        "updatedAt": 1463867223000,
        "parentId": 3,
        "order": 1,
        "categoryId": 38
      },
      {
        "id": 88,
        "createdAt": 1425225724000,
        "updatedAt": 1463867223000,
        "parentId": 5,
        "order": 1,
        "categoryId": 43
      },
      {
        "id": 94,
        "createdAt": 1487261646000,
        "updatedAt": 1487261646000,
        "parentId": 93,
        "order": 1,
        "categoryId": 53
      },
      {
        "id": 97,
        "createdAt": 1487267138000,
        "updatedAt": 1487267138000,
        "parentId": 94,
        "order": 1,
        "categoryId": 40
      },
      {
        "id": 105,
        "createdAt": 1487267515000,
        "updatedAt": 1487267515000,
        "parentId": 95,
        "order": 1,
        "categoryId": 93
      },
      {
        "id": 110,
        "createdAt": 1487267586000,
        "updatedAt": 1487267586000,
        "parentId": 96,
        "order": 1,
        "categoryId": 26
      },
      {
        "id": 118,
        "createdAt": 1487269318000,
        "updatedAt": 1487269318000,
        "parentId": 97,
        "order": 1,
        "categoryId": 24
      },
      {
        "id": 122,
        "createdAt": 1487269430000,
        "updatedAt": 1487269430000,
        "parentId": 98,
        "order": 1,
        "categoryId": 34
      },
      {
        "id": 126,
        "createdAt": 1495098098000,
        "updatedAt": 1495098098000,
        "parentId": 125,
        "order": 1,
        "categoryId": 53
      },
      {
        "id": 127,
        "createdAt": 1495098321000,
        "updatedAt": 1495098321000,
        "parentId": 126,
        "order": 1,
        "categoryId": 40
      },
      {
        "id": 134,
        "createdAt": 1495098555000,
        "updatedAt": 1495098555000,
        "parentId": 127,
        "order": 1,
        "categoryId": 24
      },
      {
        "id": 138,
        "createdAt": 1495098625000,
        "updatedAt": 1495098625000,
        "parentId": 128,
        "order": 1,
        "categoryId": 34
      },
      {
        "id": 142,
        "createdAt": 1497343174000,
        "updatedAt": 1497343174000,
        "parentId": 141,
        "order": 1,
        "categoryId": 28
      },
      {
        "id": 143,
        "createdAt": 1497343195000,
        "updatedAt": 1497343195000,
        "parentId": 142,
        "order": 1,
        "categoryId": 31
      },
      {
        "id": 146,
        "createdAt": 1561903832000,
        "updatedAt": 1561903832000,
        "parentId": 145,
        "order": 1,
        "categoryId": 103
      },
      {
        "id": 11,
        "createdAt": 1423164347000,
        "updatedAt": 1463867223000,
        "parentId": 68,
        "order": 2,
        "categoryId": 31
      },
      {
        "id": 13,
        "createdAt": 1423164315000,
        "updatedAt": 1463867223000,
        "parentId": 3,
        "order": 2,
        "categoryId": 39
      },
      {
        "id": 26,
        "createdAt": 1434285672000,
        "updatedAt": 1463867223000,
        "parentId": 59,
        "order": 2,
        "categoryId": 67
      },
      {
        "id": 27,
        "createdAt": 1425225735000,
        "updatedAt": 1463867223000,
        "parentId": 5,
        "order": 2,
        "categoryId": 95
      },
      {
        "id": 38,
        "createdAt": 1423235973000,
        "updatedAt": 1463867223000,
        "parentId": 81,
        "order": 2,
        "categoryId": 70
      },
      {
        "id": 40,
        "createdAt": 1423656900000,
        "updatedAt": 1463867223000,
        "parentId": 54,
        "order": 2,
        "categoryId": 64
      },
      {
        "id": 54,
        "createdAt": 1423164092000,
        "updatedAt": 1463867223000,
        "parentId": 52,
        "order": 2,
        "categoryId": 39
      },
      {
        "id": 95,
        "createdAt": 1487261691000,
        "updatedAt": 1487261691000,
        "parentId": 93,
        "order": 2,
        "categoryId": 38
      },
      {
        "id": 98,
        "createdAt": 1487267149000,
        "updatedAt": 1487267149000,
        "parentId": 94,
        "order": 2,
        "categoryId": 43
      },
      {
        "id": 106,
        "createdAt": 1487267523000,
        "updatedAt": 1487267523000,
        "parentId": 95,
        "order": 2,
        "categoryId": 58
      },
      {
        "id": 111,
        "createdAt": 1487267613000,
        "updatedAt": 1487267613000,
        "parentId": 96,
        "order": 2,
        "categoryId": 48
      },
      {
        "id": 119,
        "createdAt": 1487269339000,
        "updatedAt": 1487269339000,
        "parentId": 97,
        "order": 2,
        "categoryId": 91
      },
      {
        "id": 123,
        "createdAt": 1487269435000,
        "updatedAt": 1487269435000,
        "parentId": 98,
        "order": 2,
        "categoryId": 29
      },
      {
        "id": 128,
        "createdAt": 1495098333000,
        "updatedAt": 1495098333000,
        "parentId": 126,
        "order": 2,
        "categoryId": 43
      },
      {
        "id": 135,
        "createdAt": 1495098580000,
        "updatedAt": 1495098580000,
        "parentId": 127,
        "order": 2,
        "categoryId": 91
      },
      {
        "id": 139,
        "createdAt": 1495098633000,
        "updatedAt": 1495098633000,
        "parentId": 128,
        "order": 2,
        "categoryId": 29
      },
      {
        "id": 145,
        "createdAt": 1561903699000,
        "updatedAt": 1561903699000,
        "parentId": null,
        "order": 2,
        "categoryId": 102
      },
      {
        "id": 147,
        "createdAt": 1561903897000,
        "updatedAt": 1561903897000,
        "parentId": 145,
        "order": 2,
        "categoryId": 104
      },
      {
        "id": 3,
        "createdAt": 1425475436000,
        "updatedAt": 1556818627000,
        "parentId": null,
        "order": 3,
        "categoryId": 21
      },
      {
        "id": 8,
        "createdAt": 1444328465000,
        "updatedAt": 1463867223000,
        "parentId": 59,
        "order": 3,
        "categoryId": 15
      },
      {
        "id": 10,
        "createdAt": 1423236013000,
        "updatedAt": 1463867223000,
        "parentId": 81,
        "order": 3,
        "categoryId": 90
      },
      {
        "id": 15,
        "createdAt": 1423164104000,
        "updatedAt": 1463867223000,
        "parentId": 52,
        "order": 3,
        "categoryId": 49
      },
      {
        "id": 67,
        "createdAt": 1423656909000,
        "updatedAt": 1463867223000,
        "parentId": 54,
        "order": 3,
        "categoryId": 16
      },
      {
        "id": 77,
        "createdAt": 1423164356000,
        "updatedAt": 1463867223000,
        "parentId": 68,
        "order": 3,
        "categoryId": 79
      },
      {
        "id": 86,
        "createdAt": 1425225749000,
        "updatedAt": 1463867223000,
        "parentId": 5,
        "order": 3,
        "categoryId": 89
      },
      {
        "id": 96,
        "createdAt": 1487261703000,
        "updatedAt": 1487261703000,
        "parentId": 93,
        "order": 3,
        "categoryId": 39
      },
      {
        "id": 99,
        "createdAt": 1487267188000,
        "updatedAt": 1487267188000,
        "parentId": 94,
        "order": 3,
        "categoryId": 95
      },
      {
        "id": 107,
        "createdAt": 1487267530000,
        "updatedAt": 1487267530000,
        "parentId": 95,
        "order": 3,
        "categoryId": 70
      },
      {
        "id": 112,
        "createdAt": 1487267635000,
        "updatedAt": 1487267635000,
        "parentId": 96,
        "order": 3,
        "categoryId": 85
      },
      {
        "id": 124,
        "createdAt": 1487269455000,
        "updatedAt": 1487269455000,
        "parentId": 98,
        "order": 3,
        "categoryId": 7
      },
      {
        "id": 129,
        "createdAt": 1495098344000,
        "updatedAt": 1495098344000,
        "parentId": 126,
        "order": 3,
        "categoryId": 95
      },
      {
        "id": 136,
        "createdAt": 1495098591000,
        "updatedAt": 1495098591000,
        "parentId": 127,
        "order": 3,
        "categoryId": 67
      },
      {
        "id": 140,
        "createdAt": 1495098640000,
        "updatedAt": 1495098640000,
        "parentId": 128,
        "order": 3,
        "categoryId": 7
      },
      {
        "id": 148,
        "createdAt": 1561903908000,
        "updatedAt": 1561903908000,
        "parentId": 145,
        "order": 3,
        "categoryId": 105
      },
      {
        "id": 17,
        "createdAt": 1447695107000,
        "updatedAt": 1463867227000,
        "parentId": 68,
        "order": 4,
        "categoryId": 76
      },
      {
        "id": 21,
        "createdAt": 1423235983000,
        "updatedAt": 1463867223000,
        "parentId": 81,
        "order": 4,
        "categoryId": 37
      },
      {
        "id": 35,
        "createdAt": 1423656915000,
        "updatedAt": 1463867223000,
        "parentId": 54,
        "order": 4,
        "categoryId": 65
      },
      {
        "id": 52,
        "createdAt": 1425475313000,
        "updatedAt": 1463867224000,
        "parentId": null,
        "order": 4,
        "categoryId": 68
      },
      {
        "id": 69,
        "createdAt": 1425225769000,
        "updatedAt": 1463867223000,
        "parentId": 5,
        "order": 4,
        "categoryId": 69
      },
      {
        "id": 100,
        "createdAt": 1487267194000,
        "updatedAt": 1487267194000,
        "parentId": 94,
        "order": 4,
        "categoryId": 89
      },
      {
        "id": 108,
        "createdAt": 1487267535000,
        "updatedAt": 1487267535000,
        "parentId": 95,
        "order": 4,
        "categoryId": 90
      },
      {
        "id": 113,
        "createdAt": 1487267640000,
        "updatedAt": 1487267640000,
        "parentId": 96,
        "order": 4,
        "categoryId": 44
      },
      {
        "id": 130,
        "createdAt": 1495098352000,
        "updatedAt": 1495098352000,
        "parentId": 126,
        "order": 4,
        "categoryId": 89
      },
      {
        "id": 137,
        "createdAt": 1495098605000,
        "updatedAt": 1495098605000,
        "parentId": 127,
        "order": 4,
        "categoryId": 15
      },
      {
        "id": 33,
        "createdAt": 1441281056000,
        "updatedAt": 1463867223000,
        "parentId": 5,
        "order": 5,
        "categoryId": 45
      },
      {
        "id": 50,
        "createdAt": 1423164388000,
        "updatedAt": 1463867223000,
        "parentId": 68,
        "order": 5,
        "categoryId": 18
      },
      {
        "id": 63,
        "createdAt": 1425475768000,
        "updatedAt": 1463867223000,
        "parentId": null,
        "order": 5,
        "categoryId": 10
      },
      {
        "id": 85,
        "createdAt": 1434284794000,
        "updatedAt": 1463867224000,
        "parentId": 54,
        "order": 5,
        "categoryId": 66
      },
      {
        "id": 101,
        "createdAt": 1487267219000,
        "updatedAt": 1487267219000,
        "parentId": 94,
        "order": 5,
        "categoryId": 69
      },
      {
        "id": 109,
        "createdAt": 1487267549000,
        "updatedAt": 1487267549000,
        "parentId": 95,
        "order": 5,
        "categoryId": 37
      },
      {
        "id": 114,
        "createdAt": 1487267648000,
        "updatedAt": 1487267648000,
        "parentId": 96,
        "order": 5,
        "categoryId": 64
      },
      {
        "id": 131,
        "createdAt": 1495098371000,
        "updatedAt": 1495098371000,
        "parentId": 126,
        "order": 5,
        "categoryId": 69
      },
      {
        "id": 29,
        "createdAt": 1423656730000,
        "updatedAt": 1463867223000,
        "parentId": 68,
        "order": 6,
        "categoryId": 8
      },
      {
        "id": 30,
        "createdAt": 1425225787000,
        "updatedAt": 1463867223000,
        "parentId": 5,
        "order": 6,
        "categoryId": 13
      },
      {
        "id": 47,
        "createdAt": 1434284815000,
        "updatedAt": 1463867223000,
        "parentId": 54,
        "order": 6,
        "categoryId": 73
      },
      {
        "id": 60,
        "createdAt": 1425475630000,
        "updatedAt": 1463867223000,
        "parentId": null,
        "order": 6,
        "categoryId": 19
      },
      {
        "id": 102,
        "createdAt": 1487267230000,
        "updatedAt": 1487267230000,
        "parentId": 94,
        "order": 6,
        "categoryId": 45
      },
      {
        "id": 115,
        "createdAt": 1487267667000,
        "updatedAt": 1487267667000,
        "parentId": 96,
        "order": 6,
        "categoryId": 96
      },
      {
        "id": 132,
        "createdAt": 1495098371000,
        "updatedAt": 1495098446000,
        "parentId": 126,
        "order": 6,
        "categoryId": 18
      },
      {
        "id": 23,
        "createdAt": 1423656920000,
        "updatedAt": 1463867223000,
        "parentId": 54,
        "order": 7,
        "categoryId": 42
      },
      {
        "id": 31,
        "createdAt": 1423164363000,
        "updatedAt": 1463867223000,
        "parentId": 68,
        "order": 7,
        "categoryId": 6
      },
      {
        "id": 74,
        "createdAt": 1425458934000,
        "updatedAt": 1463867223000,
        "parentId": 5,
        "order": 7,
        "categoryId": 5
      },
      {
        "id": 92,
        "createdAt": 1487165257000,
        "updatedAt": 1487165268000,
        "parentId": null,
        "order": 7,
        "categoryId": 100
      },
      {
        "id": 103,
        "createdAt": 1487267399000,
        "updatedAt": 1487267427000,
        "parentId": 94,
        "order": 7,
        "categoryId": 13
      },
      {
        "id": 116,
        "createdAt": 1487267671000,
        "updatedAt": 1487267671000,
        "parentId": 96,
        "order": 7,
        "categoryId": 80
      },
      {
        "id": 133,
        "createdAt": 1495098477000,
        "updatedAt": 1495098477000,
        "parentId": 126,
        "order": 7,
        "categoryId": 5
      },
      {
        "id": 7,
        "createdAt": 1434285180000,
        "updatedAt": 1463867223000,
        "parentId": 68,
        "order": 8,
        "categoryId": 32
      },
      {
        "id": 104,
        "createdAt": 1487267455000,
        "updatedAt": 1487267455000,
        "parentId": 94,
        "order": 8,
        "categoryId": 5
      },
      {
        "id": 117,
        "createdAt": 1487267682000,
        "updatedAt": 1487267682000,
        "parentId": 96,
        "order": 8,
        "categoryId": 42
      },
      {
        "id": 141,
        "createdAt": 1497343163000,
        "updatedAt": 1497343163000,
        "parentId": null,
        "order": 8,
        "categoryId": 59
      },
      {
        "id": 144,
        "createdAt": 1511941564000,
        "updatedAt": 1511941564000,
        "parentId": null,
        "order": 9,
        "categoryId": 101
      },
      {
        "id": 120,
        "createdAt": 1487269343000,
        "updatedAt": 1487269343000,
        "parentId": 97,
        "order": 10,
        "categoryId": 67
      },
      {
        "id": 121,
        "createdAt": 1487269399000,
        "updatedAt": 1487269399000,
        "parentId": 97,
        "order": 11,
        "categoryId": 15
      }
    ],
    pricesFilter: {},
    itemProperties: {},
    draftOrders: {},
    offers: {},
    shippingTypes: {},
    reviews: {},
    news: {},
    comments: {},
    disputes: {},
    screenTemplates: {}
  },
  cache: {},
  globals: {
    aactivation: "[{\"type\":\"session_start\",\"title\":\"Get a limited time credit\",\"message\":\"Add an item to the cart now and unlock a special price on your next order\",\"button\":\"Ok\",\"time\":90,\"timer\":30,\"uri\":null,\"cancel\":\"add_to_cart\",\"api\":\"/offers/generateActivationCredit?type=noatc\"},{\"type\":\"add_to_cart\",\"title\":\"Get a limited time credit\",\"message\":\"Get a special price if you complete your purchase within:\",\"button\":\"Complete Purchase\",\"time\":300,\"timer\":30,\"uri\":\"12\",\"cancel\":\"purchase\",\"api\":\"/offers/generateActivationCredit?type=atc\"}]"
  }
})

// noinspection JSUnusedGlobalSymbols
export const mutations = {
  updateBuckets(state, {key, result, saveToLocalStorage, rootState}) {
    if (result.objects) {
      const objects = Object.keys(result.objects);
      for (let k of objects) {
        switch (k) {
          case 'cells':
            if (key !== null) {
              state.cache[key] = result.objects[k]
            }
            break
          case 'globals':
            // merge 2 objects
            state.globals = {...state.globals, ...result.objects[k]}
            break
          case 'stories':
            break
          default:
            if (result.objects[k] || result.objects[k] === 0) {
              if (typeof result.objects[k] === 'object') {
                if (saveToLocalStorage) {
                  try {
                    //some issue with innApp instagram browser
                    rootState.localStorage.cache[k] = result.objects[k].slice(0, 20)
                  } catch (e) {
                  }
                }
                if (result.objects[k].forEach) {
                  result.objects[k].forEach((entity) => {
                    if (state.buckets[k]) {
                      state.buckets[k]['' + entity.id] = entity
                    }
                  })
                } else {
                  console.log('k', k);
                }
              } else {
                state.globals[k] = result.objects[k]
              }
            }
            break
        }
      }
    }
  },
  loadItemsToCache(state, {items}) {
    items.forEach((item) => {
      state.buckets.items['' + item.id] = item
    })
  },
  storeEntities(state, args) {
    state.cache[args.key] = args
  },
  deleteCache(state, args) {
    delete state.cache[args.key]
  },
  loadItemToCache(state, item) {
    state.buckets.items['' + item.id] = item
  },
  setCache(state, obj) {
    Object.keys(obj).forEach((k) => {
      state.cache[k] = obj[k]
    })
  },
  storeAddressHistory(state, addressHistory) {
    state.cache['addressHistory'] = addressHistory
  },
  clearGlobals(state) {
    state.globals = {}
  },
  increaseCartCount(state, numToAdd) {
    state.globals.cartCount += numToAdd
  },
  clearItemPropertiesAndVariants(state) {
    state.buckets.variants = {}
    state.buckets.itemProperties = {}
  },
  storeEntity(state, entity) {
    state.buckets[entity.bucket][entity.entity.id] = entity.entity
  },
  setPriceRangeFilter(state, priceRangeFilter) {
    state.cache.priceRangeFilter = priceRangeFilter
  },
  setSortFilter(state, sortFilter) {
    state.cache.sortFilter = sortFilter
  },
  setGenderFilter(state, genderFilter) {
    state.cache.genderFilter = genderFilter
  },
  addCurrentUserToBucket(state, currentUser) {
    state.buckets.users[currentUser.id] = currentUser
  },
  setLastPath(state, path) {
    state.cache.lastPath = path
  },
  setItemStoreId(state, storeId) {
    state.cache.storeId = storeId
  },
  updateItem(state, itemUpdate) {
    Object.keys(itemUpdate).forEach((k) => {
      if (k !== 'id') {
        state.buckets.items[itemUpdate.id] = itemUpdate[k]
      }
    })
  },
  updateUser(state, userUpdate) {
    state.buckets.users[userUpdate.id] = userUpdate
  }
}


export const actions = {
  getAddressesHistoryFromCache({commit, rootState}) {
    return rootState.api.cache.addressHistory
  },
  trackPurchase({commit, rootState}, params) {
    params.userId = rootState.localStorage.currentUser.id
    if (Intl.DateTimeFormat().resolvedOptions().timeZone !== 'Asia/Jerusalem' && location.hostname !== 'localhost' && location.hostname !== '127.0.0.1') {
      this.$gtag('event', 'purchase', {
        send_to: 'AW-468620727/3glJCJG-wuACELerut8B',
        value: 35.0,
        currency: 'USD'
      })
      this.$gtag('event', 'purchase', {
        'value': params.value,
        'transactionId': params.orderId,
        'transactionAffiliation': 'GOTit',
        'transactionTotal': params.value,
        'transactionTax': params.tax || 0,
        'transactionShipping': params.shippingPrice || 0,
        'transactionProducts': [{
          'sku': params.item.id,
          'name': params.item.name,
          'category': params.item.categories,
          'price': params.value,
          'quantity': 1
        }]
      })
      this.$fb.track('Purchase', params)
    }
  },
  trackPurchaseTest({commit, rootState}, params) {
    this.$gtag('event', 'purchase', {
      value: 35.0,
      currency: 'USD'
    })
  },
  trackEventTest({commit, rootState}, params) {
    this.$gtag('event', 'itzhar_test_gtag', params)
  },
  logSentryError({commit, rootState}, errorMessage) {
    Sentry.default.captureMessage(errorMessage)
  },
  trackEvent({commit, rootState}, params) {
    // Intl.DateTimeFormat().resolvedOptions().timeZone !== 'Asia/Jerusalem'
    if (location.hostname !== 'localhost' && location.hostname !== '127.0.0.1') {
      const fireRegularFirebaseEvent = (params) => {
        if (this.$fire && this.$fire.analytics) {
          this.$fire.analytics.logEvent(params.eventName, params.params)
        }
      }
      if (!params.params) {
        params.params = {}
      }
      if (rootState.localStorage.currentUser) {
        params.params.userId = rootState.localStorage.currentUser.id
      }
      if (params && params.eventName) {
        switch (params.eventName) {
            case 'item_view':
              this.$gtag('event', 'view_item', {
                currency: "USD",
                value: params.params.price,
                'items': [{
                  item_id: params.params.itemId,
                  item_name: params.params.item_name,
                  item_brand: params.params.brand,
                  item_category	: params.params.item_category,
                  price: params.params.price,
                  quantity: 1,
                  currency: 'USD'
                }]
              })
              this.$fb.query('trackCustom', params.eventName, params.params)
              break;
            case "view_item_list":
              this.$fb.query('trackCustom', params.eventName, params.params)
              break;
            case 'select_item':
              this.$fb.query('trackCustom', params.eventName, params.params)
              break;
            case 'item_wantit':
              this.$fb.query('trackCustom', params.eventName, params.params)
              if (this.$fire && this.$fire.analytics) {
                this.$fire.analytics.logEvent('add_to_wishlist', params.params)
              }
              break;
            case 'view_cart':
              this.$fb.query('trackCustom', params.eventName, params.params)
              break;
            case 'begin_checkout':
              this.$fb.query('trackCustom', params.eventName, params.params)
              break;
            case 'add_to_cart':
              this.$gtag('event', 'add_to_cart', {
                currency: "USD",
                value: params.params.price,
                'items': [{
                  item_id: params.params.itemId,
                  item_name: params.params.item_name,
                  item_brand: params.params.brand,
                  item_category	: params.params.item_category,
                  price: params.params.price,
                  quantity: 1,
                  currency: 'USD'
                }]
              })
              this.$fb.query('trackCustom', params.eventName, params.params)
              break;
            case 'remove_from_cart':
              this.$gtag('event', 'remove_from_cart', {
                currency: "USD",
                value: params.params.price,
                'items': [{
                  item_id: params.params.itemId,
                  item_name: params.params.item_name,
                  item_brand: params.params.brand,
                  item_category	: params.params.item_category,
                  price: params.params.price,
                  quantity: 1,
                  currency: 'USD'
                }]
              })
              this.$fb.query('trackCustom', params.eventName, params.params)
              break;
            case 'search_performed':
              if (this.$fire && this.$fire.analytics) {
                this.$fire.analytics.logEvent('search', params.params)
              }
              this.$fb.query('trackCustom', params.eventName, params.params)
              break;
            case 'credit_card_added':
              fireRegularFirebaseEvent(params)
              this.$fb.track(params.eventName, params.params)
              break;
            case 'registration_complete_during_credit_card':
            case 'registration_complete':
              fireRegularFirebaseEvent(params)
              this.$fb.query('trackCustom', params.eventName, params.params)
              break;
            case 'add_first_address':
            case 'add_address':
              fireRegularFirebaseEvent(params)
              this.$fb.query('trackCustom', params.eventName, params.params)
              break;
            case 'login_complete':
              if (this.$fire && this.$fire.analytics) {
                this.$fire.analytics.logEvent('login', params.params)
              }
              this.$fb.query('trackCustom', params.eventName, params.params)
              break;
            case 'ad_click':
              this.$fb.query('trackCustom', params.eventName, params.params)
              if (this.$fire && this.$fire.analytics) {
                //in ad click - not sure if firebase sdk already initialized SDK
                this.$fire.analytics.logEvent('ad_click', params.params)
              }
              break;
            default:
              fireRegularFirebaseEvent(params)
              this.$fb.query('trackCustom', params.eventName, params.params)
              break;
        }
      }
    }
  },
  handleIdentify({rootState}) {
    if (rootState.localStorage.currentUser) {
      Sentry.default.setUser({
        id: rootState.localStorage.currentUser.id,
        ip_address: rootState.localStorage.currentUser.lastIp,
        email: rootState.localStorage.currentUser.email,
        username: rootState.localStorage.currentUser.username
      })
    }
  },
  followEvent({commit, rootState}, paramss) {
    let eventName = 'user_follow'
    if (paramss.isFollowed === 'false') {
      eventName = 'user_unfollow'
    }
    paramss.userId = rootState.localStorage.currentUser.id
    if (eventName === 'user_follow') {
      paramss.followed_user_id = paramss.entityId
    } else {
      paramss.unfollowed_user_id = paramss.entityId
    }
    this.$fb.query('trackCustom', eventName, paramss)
    this.$fire.analytics.logEvent(eventName, paramss)
  },
  async usersLogin({commit, rootState}, credentials) {
    const params = {endpoint: `/users/login`, ...credentials}
    const res = await this.$axios.post(`/users/login`, common.methods.generateParams(rootState, params))
    if (!res.data.error) {
      commit('updateBuckets', {key: JSON.stringify(params), result: res.data})
      const result = common.methods.cellsToEntities(rootState, JSON.stringify(params))
      result[0].facebookToken = res.data.objects.facebookToken
      result[0].sessionToken = res.data.objects.sessionToken
      return result
    } else {
      return res.data
    }
  },
  async logout({commit, rootState}) {
    const params = {endpoint: `/users/logout`}
    const res = await this.$axios.post(`/users/logout`, common.methods.generateParams(rootState, params))
    return res
  },
  async setPassword({commit, rootState}, data) {
    const params = {endpoint: `/users/setPassword`, ...data}
    const res = await this.$axios.post(`/users/setPassword`, common.methods.generateParams(rootState, params))
    return res.data
  },
  async addCreditCard({commit, rootState}, data) {
    const params = {endpoint: `/stripe/addCreditCard`, ...data}
    const res = await this.$axios.post(`/stripe/addCreditCard`, common.methods.generateParams(rootState, params))
    return res.data
  },
  async sendSecurityReport({commit, rootState}, data) {
    const params = {endpoint: `/stripe/addCreditCard`, ...data}
    const res = await this.$axios.post(`/stripe/addCreditCard`, common.methods.generateParams(rootState, params))
    return res.data
  },
  async getTopSearches({commit, rootState}) {
    if (!rootState.localStorage.topSearches) {
      const res = await this.$axios.post(`/items/topSearch`, common.methods.generateParams(rootState, {}))
      commit('localStorage/storeTopSearches', res.data.objects.searches, {root: true})
      return res.data.objects.searches
    } else {
      var twentyFourHoursAgo = new Date();
      twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);
      if (rootState.localStorage.topSearches.lastUpdate < twentyFourHoursAgo) {
        // The date is over 24 hours ago
        const res = await this.$axios.post(`/items/topSearch`, common.methods.generateParams(rootState, {}))
        commit('localStorage/storeTopSearches', res.data.objects.searches, {root: true})
        return res.data.objects.searches
      } else {
        // The date is within the last 24 hours
        return rootState.localStorage.topSearches.searches
      }
    }
    return []
  },
  async createKlarnaCartSource({commit, rootState}) {
    const params = {endpoint: `/stripe/createKlarnaCartSource`}
    const res = await this.$axios.post(`/stripe/createKlarnaCartSource`, common.methods.generateParams(rootState, params))
    return res.data.objects.stripe
  },
  async isUserExists({commit, rootState}, data) {
    //email=rani@rimons.com&phone=972549717222
    const params = {endpoint: `/users/check`, ...data}
    const res = await this.$axios.post(`/users/check`, common.methods.generateParams(rootState, params))
    return res.data.objects.emailPhoneExists
  },
  async editCreditCard({commit, rootState}, data) {
    const params = {endpoint: `/stripe/editCreditCard`, ...data}
    const res = await this.$axios.post(`/stripe/editCreditCard`, common.methods.generateParams(rootState, params))
    return res.data
  },
  async removeCreditCard({commit, rootState}, data) {
    const params = {endpoint: `/stripe/removeCreditCard`, ...data}
    const res = await this.$axios.post(`/stripe/removeCreditCard`, common.methods.generateParams(rootState, params))
    return res.data
  },
  async register({commit, rootState}, registrationInfo) {
    const params = {endpoint: `/users/register`, ...registrationInfo}
    const res = await this.$axios.post(`/users/register`, common.methods.generateParams(rootState, params))
    commit('updateBuckets', {key: JSON.stringify(params), result: res.data})
    const result = common.methods.cellsToEntities(rootState, JSON.stringify(params))
    result[0].facebookToken = res.data.objects.facebookToken
    result[0].sessionToken = res.data.objects.sessionToken
    return result
  },
  async isUsernameExist({commit, rootState}, username) {
    const params = {endpoint: `/users/check`, username}
    const res = await this.$axios.post(`/users/check`, common.methods.generateParams(rootState, params))
    return res.data
  },
  async setDeviceToken({commit, rootState}, deviceToken) {
    const params = {endpoint: `/users/setDeviceToken`, deviceToken}
    try {
      this.$axios.post(`/users/setDeviceToken`, common.methods.generateParams(rootState, params))
    } catch (e) {
    }
  },
  async sendMonitorMessage({commit, rootState}, data) {
    Sentry.default.captureEvent({
      message: data
    })
    const params = {endpoint: `/globals/log`, data, monitor: true}
    const res = await this.$axios.post(`/globals/log`, common.methods.generateParams(rootState, params))
    return res.data
  },
  async sendLogMessage({commit, rootState}, data) {
    const params = {endpoint: `/globals/log`, data, monitor: false}
    const res = await this.$axios.post(`/globals/log`, common.methods.generateParams(rootState, params))
    return res.data
  },
  async generatePin({commit, rootState}, data) {
    const params = {endpoint: `/users/generatePin`, data}
    const res = await this.$axios.post(`/users/generatePin`, common.methods.generateParams(rootState, params))
    return res.data
  },
  async getStripePaymentIntent({commit, rootState}, data) {
    const res = await this.$axios.post(`https://stripe.gotitstores.com/paymentIntent`, common.methods.generateParams(rootState, {}))
    return res.data
  },
  async generateShortLink({commit, rootState}, {pin}) {
    if (rootState.localStorage.sessionToken) {
      const res = await this.$axios.get(`https://stripe.gotitstores.com/shortLink?sessionToken=${rootState.localStorage.sessionToken}&pin=${pin}&username=${rootState.localStorage.currentUser.username}`)
      return res.data
    } else {
      return null
    }
  },
  async getStripeClientSecret({commit, rootState}, data) {
    const res = await this.$axios.post(`https://stripe.gotitstores.com/secret`, common.methods.generateParams(rootState,{}))
    return res.data
  },
  async getCashAppClientSecret({commit, rootState}, data) {
    // const res = await this.$axios.post(`https://stripe.gotitstores.com/cash-app-secret`, common.methods.generateParams(rootState,{}))
    const res = await this.$axios.post(`https://stripe.gotitstores.com/cash-app-secret-dev`, common.methods.generateParams(rootState,{}))
    return res.data
  },
  async activationApi({commit, rootState}, { api }) {
    const res = await this.$axios.get(`https://server.gotitapp.net${api}&sessionToken=${rootState.localStorage.sessionToken}`)
    return res.data
  },
  async getAddressAutoComplete({commit, rootState}, placeId) {
    const res = await this.$axios.get(`https://stripe.gotitstores.com/autocomplete?sessionToken=${rootState.localStorage.sessionToken}&place_id=${placeId}`)
    return res.data
  },
  async getAddressInfo({commit, rootState}, placeId) {
    const res = await this.$axios.get(`https://stripe.gotitstores.com/addressInfo?sessionToken=${rootState.localStorage.sessionToken}&place_id=${placeId}`)
    return res.data
  },
  async payStripeApplePay({commit, rootState}, data) {
    const res = await this.$axios.post(`https://stripe.gotitstores.com/secret/pay`, data)
    return res.data
  },
  async getGlobals({commit, rootState}) {
    if (rootState.localStorage.sessionToken) {
      const params = {endpoint: '/users/globals'}
      const res = await this.$axios.post(`/users/globals`, common.methods.generateParams(rootState, params))
      commit('updateBuckets', {key: JSON.stringify(params), result: res.data})
      commit('localStorage/setCurrentUser', res.data.objects.users[0], {root: true})
      return res.data.objects
    } else {
      console.log('cannot get globals before login')
    }
  },
  async getUser({commit, rootState}, {id, cache}) {
    const result = await common.methods.genericApi({commit, rootState}, {endpoint: '/users/get', id, cache}, this.$axios)
    return result[0]
  },
  async usersGet({commit, rootState}, params) {
    const result = await common.methods.genericApi({
      commit,
      rootState
    }, {endpoint: '/users/select', ...params}, this.$axios)
    return result
  },
  async pricesFilter({commit, rootState}, params) {
    const result = await common.methods.genericApi({
      commit,
      rootState
    }, {endpoint: '/pricesFilter/select', ...params}, this.$axios)
    return result
  },
  async getReviews({commit, rootState}, params) {
    const result = await common.methods.genericApi({
      commit,
      rootState
    }, {endpoint: '/reviews/select', ...params}, this.$axios)
    return result
  },
  async addReview({commit, rootState}, params) {
    const result = await common.methods.genericApi({commit, rootState}, {endpoint: '/reviews/add', ...params}, this.$axios)
    return result
  },
  async updateReview({commit, rootState}, params) {
    const result = await common.methods.genericApi({
      commit,
      rootState
    }, {endpoint: '/reviews/update', ...params}, this.$axios)
    return result
  },
  async updateItem({commit, rootState}, item) {
    const res = await common.methods.genericApi({commit, rootState}, {endpoint: '/items/update', ...item}, this.$axios)
    return res[0]
  },
  async itemExecute({commit, rootState}, params) {
    const res = await common.methods.genericApi({commit, rootState}, {endpoint: '/items/execute', ...params}, this.$axios)
    return res[0]
  },
  async usersUpdate({commit, rootState}, user) {
    const result = await common.methods.genericApi({commit, rootState}, {endpoint: '/users/update', ...user}, this.$axios)
    return result
  },
  async getCategoriesTree({commit, rootState}, params) {
    const result = await common.methods.genericApi({
      commit,
      rootState
    }, {endpoint: '/categoryTree/select', ...params}, this.$axios)
    return result
  },
  async genericCall({commit, rootState}, params) {
    const result = await common.methods.genericApi({commit, rootState}, params, this.$axios)
    return result
  },
  async getCategories({commit, rootState}, params) {
    const result = await common.methods.genericApi({
      commit,
      rootState
    }, {endpoint: '/categories/select', ...params}, this.$axios)
    return result
  },
  async usersSelect({commit, rootState}, {filter, filterParam, skip, limit, cache}) {
    const result = await common.methods.genericApi({commit, rootState}, {
      endpoint: '/users/select',
      filter,
      filterParam,
      skip,
      limit,
      cache
    }, this.$axios)
    return result
  },
  async itemsSelect({commit, rootState}, {
    filter,
    filterParam,
    filterParam2,
    skip,
    limit,
    includeComments,
    mainFeed,
    includeProperties,
    gender,
    cache
  }) {
    const result = await common.methods.genericApi({commit, rootState}, {
      endpoint: '/items/select',
      filter,
      filterParam,
      filterParam2,
      skip,
      limit,
      includeComments,
      mainFeed,
      includeProperties,
      gender,
      cache
    }, this.$axios)
    return result
  },

  async ssrItemSelect({commit, rootState}, itemId) {
    const res = await npaxios.get(`/items/${itemId}`)
    return res.data
  },
  async ssrUserSelect({commit, rootState}, userId) {
    const res = await npaxios.get(`/users/${userId}`)
    return res.data
  },
  async draftOrdersSelect({commit, rootState}, {filter, filterParam, cache}) {
    const result = await common.methods.genericApi({commit, rootState}, {
      endpoint: '/draftOrders/select',
      filter,
      filterParam,
      cache
    }, this.$axios)
    return result
  },
  async propertyValuesSelect({commit, rootState}, {filter, filterParam, limit, cache}) {
    const result = await common.methods.genericApi({commit, rootState}, {
      endpoint: '/propertyValues/select',
      filter,
      filterParam,
      limit,
      cache
    }, this.$axios)
    return result
  },
  async externalChargeFailed({commit, rootState}, data) {
    const res = await this.$axios.post(`https://server.gotitapp.net/orders/externalChargeFailed`, common.methods.generateParams(rootState,data), this.$axios)
    return res.data
  },
  async draftOrdersAdd({commit, rootState}, draftOrder) {
    const result = await common.methods.genericApi({commit, rootState}, {
      endpoint: '/v2/draftOrders/add',
      ...draftOrder
    }, this.$axios)
    return result[0]
  },
  async draftOrdersUpdate({commit, rootState}, draftOrder) {
    const result = await common.methods.genericApi({commit, rootState}, {
      endpoint: '/draftOrders/update',
      ...draftOrder
    }, this.$axios)

    return result
  },
  async ordersUpdate({commit, rootState}, draftOrder) {
    const result = await common.methods.genericApi({commit, rootState}, {
      endpoint: '/orders/update',
      ...draftOrder
    }, this.$axios)

    return result
  },
  async createCart({commit, rootState}) {
    const res = await this.$axios.post(`/carts/add`, common.methods.generateParams(rootState, {}), this.$axios)
    commit('updateBuckets', {key: JSON.stringify({shopifyCheckout: 1}), result: res.data})
    return res.data.objects.carts[0]
  },
  async draftOrdersDelete({commit, rootState}, draftOrder) {
    const result = await common.methods.genericApi({commit, rootState}, {
      endpoint: '/draftOrders/delete',
      ...draftOrder
    }, this.$axios)
    return result
  },
  async getScreen({commit, rootState}, params) {
    params.endpoint = '/globals/getScreen'
    const res = await this.$axios.post(`/globals/getScreen`, common.methods.generateParams(rootState, params), this.$axios)
    commit('updateBuckets', {key: JSON.stringify(params), result: res.data})
    const result = res.data.objects.screenTemplates
    return result
    // return [{"components":[{"componentId":"toolbar","type":"ToolbarComponent"},{"componentId":"left_component","type":"LeftComponent"},{"componentId":"right_component","type":"RightComponent"},{"components":[{"type":"MainFeedItemComponent"}],"componentId":"center_component","stream":{"filter":"ALL","endpoint":"/items/select","gender":true,"limit":20,"includeComments":true,"includeProperties":true,"mainFeed":true},"spreading":"REPEAT","type":"ListComponent","parameters":{"width":"100%","height":"100%"}}],"componentId":"ROOT","type":"HomeRootComponent"}]
  },
  async shopifyCheckout({commit, rootState}) {
    const res = await this.$axios.post(`/shopify/checkout`, common.methods.generateParams(rootState, {}), this.$axios)
    commit('updateBuckets', {key: JSON.stringify({shopifyCheckout: 1}), result: res.data})
    return res.data.objects.draftOrders
  },
  async getUserAddresses({commit, rootState}) {
    let addressesHistory = await common.methods.genericApi({commit, rootState}, {
      endpoint: '/draftOrders/getAddresses'
    }, this.$axios)
    addressesHistory = addressesHistory.filter(item => {
      return item.city && item.city !== 'TEMP'
    })
    commit('storeAddressHistory', addressesHistory)
    return addressesHistory
  },
  async setCartAddress({commit, rootState}, params) {
    return await common.methods.genericApi({commit, rootState}, {
      endpoint: '/draftOrders/setCartAddress',
      ...params
    }, this.$axios)
  },
  async userExecute({commit, rootState}, params) {
    const res = await this.$axios.post(`/users/execute`, common.methods.generateParams(rootState, params))
    commit('updateBuckets', {key: JSON.stringify(params), result: res.data})
    if (res.data.objects) {
      return res.data.objects.users[0]
    } else {
      return null
    }
  },
  async oldCheckout({commit, rootState}, params) {
    const res = await this.$axios.post(`/orders/checkout`, common.methods.generateParams(rootState, params))
    commit('updateBuckets', {key: JSON.stringify(params), result: res.data})
    return res.data.objects.draftOrders
  },
  async adClick({commit, rootState}, params) {
    const res = await this.$axios.post(`/adClicks/add`, common.methods.generateParams(rootState, params))
    return res.data
  },
  async newCheckout({commit, rootState}, params) {
    const res = await this.$axios.post(`/orders/newCheckout`, common.methods.generateParams(rootState, params), {
      // headers: {
      //   'Access-Control-Expose-Headers': 'gotiterrormessage'
      // }
    })
    commit('updateBuckets', {key: JSON.stringify(params), result: res.data})
    return res.data.objects.draftOrders
  },
  async forgotPassword({commit, rootState}, params) {
    const res = await this.$axios.post(`/users/forgotPassword`, common.methods.generateParams(rootState, params))
    return res.data.objects
  },
  async findAvailableUsername({commit, rootState}, email) {
    const res = await this.$axios.post(`/users/findAvailableUsername?email=${email}`, common.methods.generateParams(rootState, {}))
    return res.data
  },
  async getPayPalToken({rootState}) {
    const res = await this.$axios.post(`/paypal/getToken`, common.methods.generateParams(rootState, {}))
    return res.data.objects.paypalToken
  },
  async setShippingTypes({commit, rootState}, params) {
    const res = await this.$axios.post(`/shopify/setShippingTypes`, common.methods.generateParams(rootState, params))
    commit('updateBuckets', {key: JSON.stringify(params), result: res.data})
    return res.data.objects.draftOrders
  },
  async selectCreditCards({commit, rootState}) {
    const res = await this.$axios.post(`/stripe/selectCreditCards`, common.methods.generateParams(rootState, {}))
    return res.data.objects.stripe
  },
  async selectNews({commit, rootState}, params) {
    const result = await common.methods.genericApi({commit, rootState}, {
      endpoint: '/news/select',
      ...params
    }, this.$axios)
    return result
  },
  async updateNew({commit, rootState}, params) {
    const res = await this.$axios.post(`/news/update`, common.methods.generateParams(rootState, params))
    return res
  },
  async sendComment({commit, rootState}, params) {
    const result = await common.methods.genericApi({commit, rootState}, {
      endpoint: '/comments/add',
      ...params
    }, this.$axios)
    return result[0]
  },
  async sendDisputeComment({commit, rootState}, params) {
    const result = await common.methods.genericApi({commit, rootState}, {
      endpoint: '/disputes/add',
      ...params
    }, this.$axios)
    return result[0]
  },
  async selectComments({commit, rootState}, params) {
    const result = await common.methods.genericApi({commit, rootState}, {
      endpoint: '/comments/select',
      ...params
    }, this.$axios)
    return result
  },
  async selectDisputes({commit, rootState}, params) {
    const result = await common.methods.genericApi({commit, rootState}, {
      endpoint: '/disputes/select',
      ...params
    }, this.$axios)
    return result
  },
  async selectOrders({commit, rootState}, params) {
    const result = await common.methods.genericApi({commit, rootState}, {
      endpoint: '/orders/select',
      ...params
    }, this.$axios)
    return result
  },
  async selectOrder({commit, rootState}, params) {
    const result = await common.methods.genericApi({commit, rootState}, {
      endpoint: '/orders/select',
      filter: 'GET',
      ...params
    }, this.$axios)
    return result[0]
  },
  async selectOffers({commit, rootState}, params) {
    const result = await common.methods.genericApi({commit, rootState}, {
      endpoint: '/offers/select',
      filter: 'GET',
      ...params
    }, this.$axios)
    return result
  },
  async selectStores({commit, rootState}, params) {
    const result = await common.methods.genericApi({commit, rootState}, {
      endpoint: '/users/select',
      ...params
    }, this.$axios)
    return result
  },
  loadCacheBuckets({commit, rootState}) {
    Object.keys(rootState.localStorage.cache).forEach(key => {
      rootState.localStorage.cache[key].forEach((entity) => {
        if (rootState.api.buckets[key]) {
          rootState.api.buckets[key]['' + entity.id] = entity
        }
      })
    })
  },
  async uploadImageNew({commit, rootState}, fileData) {
    return new Promise((resolve, reject) => {
      const bucket = new S3(
        {
          accessKeyId: process.env.AWS_ACCESS_KEY,
          secretAccessKey: process.env.AWS_SECRET_KEY
        }
      )
      const buf = new Buffer(fileData.replace(/^data:image\/\w+;base64,/, ''), 'base64')
      const dateTime = new Date().getTime()
      const params = {
        Bucket: 'app.gotit.images',
        Key: 'photo_' + common.methods.guid() + '_' + dateTime + '.jpg',
        Body: buf,
        ContentType: 'image/jpeg'
      }
      bucket.upload(params, function (err, data) {
        if (err) {
          reject(err)
        } else {
          resolve(data)
        }
      })
    })
  }
}
