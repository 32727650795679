import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _367c929e = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _6d90b0e0 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _679ee3ab = () => interopDefault(import('../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _098720d1 = () => interopDefault(import('../pages/create-set/index.vue' /* webpackChunkName: "pages/create-set/index" */))
const _be5e558c = () => interopDefault(import('../pages/credit-card/index.vue' /* webpackChunkName: "pages/credit-card/index" */))
const _7f6cddae = () => interopDefault(import('../pages/explore/index.vue' /* webpackChunkName: "pages/explore/index" */))
const _3d5bf812 = () => interopDefault(import('../pages/help-center/index.vue' /* webpackChunkName: "pages/help-center/index" */))
const _05fed4e8 = () => interopDefault(import('../pages/messages/index.vue' /* webpackChunkName: "pages/messages/index" */))
const _6db7fcd3 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _e2ac2d52 = () => interopDefault(import('../pages/offers/index.vue' /* webpackChunkName: "pages/offers/index" */))
const _01375125 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _17679881 = () => interopDefault(import('../pages/payment-methods/index.vue' /* webpackChunkName: "pages/payment-methods/index" */))
const _83cdf452 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _f83e8682 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _49638d7c = () => interopDefault(import('../pages/returns-policy/index.vue' /* webpackChunkName: "pages/returns-policy/index" */))
const _d5a807b0 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _13193b12 = () => interopDefault(import('../pages/security-report/index.vue' /* webpackChunkName: "pages/security-report/index" */))
const _7029ee92 = () => interopDefault(import('../pages/sell/index.vue' /* webpackChunkName: "pages/sell/index" */))
const _345ab5b3 = () => interopDefault(import('../pages/setPassword/index.vue' /* webpackChunkName: "pages/setPassword/index" */))
const _77bfefa3 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _3ce6bb43 = () => interopDefault(import('../pages/shipping-info/index.vue' /* webpackChunkName: "pages/shipping-info/index" */))
const _83e4722e = () => interopDefault(import('../pages/stories/index.vue' /* webpackChunkName: "pages/stories/index" */))
const _ff9ad3bc = () => interopDefault(import('../pages/track-order/index.vue' /* webpackChunkName: "pages/track-order/index" */))
const _7e0152ad = () => interopDefault(import('../pages/profile/edit.vue' /* webpackChunkName: "pages/profile/edit" */))
const _3b517cbe = () => interopDefault(import('../pages/items/_id.vue' /* webpackChunkName: "pages/items/_id" */))
const _477d4e66 = () => interopDefault(import('../pages/orders/_id.vue' /* webpackChunkName: "pages/orders/_id" */))
const _857a06cc = () => interopDefault(import('../pages/stores/_id.vue' /* webpackChunkName: "pages/stores/_id" */))
const _27335b06 = () => interopDefault(import('../pages/users/_id.vue' /* webpackChunkName: "pages/users/_id" */))
const _0f4418a5 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _367c929e,
    name: "about-us"
  }, {
    path: "/cart",
    component: _6d90b0e0,
    name: "cart"
  }, {
    path: "/contact-us",
    component: _679ee3ab,
    name: "contact-us"
  }, {
    path: "/create-set",
    component: _098720d1,
    name: "create-set"
  }, {
    path: "/credit-card",
    component: _be5e558c,
    name: "credit-card"
  }, {
    path: "/explore",
    component: _7f6cddae,
    name: "explore"
  }, {
    path: "/help-center",
    component: _3d5bf812,
    name: "help-center"
  }, {
    path: "/messages",
    component: _05fed4e8,
    name: "messages"
  }, {
    path: "/news",
    component: _6db7fcd3,
    name: "news"
  }, {
    path: "/offers",
    component: _e2ac2d52,
    name: "offers"
  }, {
    path: "/orders",
    component: _01375125,
    name: "orders"
  }, {
    path: "/payment-methods",
    component: _17679881,
    name: "payment-methods"
  }, {
    path: "/privacy-policy",
    component: _83cdf452,
    name: "privacy-policy"
  }, {
    path: "/profile",
    component: _f83e8682,
    name: "profile"
  }, {
    path: "/returns-policy",
    component: _49638d7c,
    name: "returns-policy"
  }, {
    path: "/search",
    component: _d5a807b0,
    name: "search"
  }, {
    path: "/security-report",
    component: _13193b12,
    name: "security-report"
  }, {
    path: "/sell",
    component: _7029ee92,
    name: "sell"
  }, {
    path: "/setPassword",
    component: _345ab5b3,
    name: "setPassword"
  }, {
    path: "/settings",
    component: _77bfefa3,
    name: "settings"
  }, {
    path: "/shipping-info",
    component: _3ce6bb43,
    name: "shipping-info"
  }, {
    path: "/stories",
    component: _83e4722e,
    name: "stories"
  }, {
    path: "/track-order",
    component: _ff9ad3bc,
    name: "track-order"
  }, {
    path: "/profile/edit",
    component: _7e0152ad,
    name: "profile-edit"
  }, {
    path: "/items/:id?",
    component: _3b517cbe,
    name: "items-id"
  }, {
    path: "/orders/:id",
    component: _477d4e66,
    name: "orders-id"
  }, {
    path: "/stores/:id?",
    component: _857a06cc,
    name: "stores-id"
  }, {
    path: "/users/:id?",
    component: _27335b06,
    name: "users-id"
  }, {
    path: "/",
    component: _0f4418a5,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
