<div/>

<script>
export default {
  name: 'AbstractComponent',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    entityId: String,
    // eslint-disable-next-line vue/require-default-prop
    componentDataId: Object,
    // eslint-disable-next-line vue/require-default-prop
    parentComponentData: Object
    // bucketName
    // fetchData()
  },
  data () {
    return {
      entity: null,
      componentData: null,
      readMoreActivated: false
    }
  },
  computed: {},
  mounted () {
    this.componentData = this.parentComponentData
    if (this.getActualEntityId()) {
      this.entity = this.$store.state.api.buckets[this.bucketName][this.getActualEntityId()]
    }
  },
  methods: {
    getActualEntityId () {
      return this.componentData && this.componentData.entityId ? this.componentData.entityId : this.entityId
    }
  }
}
</script>
