import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
Sentry.init({
  Vue,
  dsn: 'https://d8fc9ec7e9a44c989d3a47afa3c2ae91@o613385.ingest.sentry.io/6759697', // Enter your project's DSN here
  // Additional Module Options go here
  tracesSampleRate: 0.3,
  replaysSessionSampleRate: 0.0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded'
  ],
  integrations: [
    new BrowserTracing({
      tracingOrigins: ['gotitstores.com', /^\//],
      shouldCreateSpanForRequest: (url) => {
        // Do not create spans for outgoing requests to a `/health/` endpoint
        // return !url.match(/\/health\/?$/)
        return true
      }
    }),
    // eslint-disable-next-line import/namespace
    new Sentry.Replay()
  ],
  hooks: ['create', 'mount']
})

export default Sentry
