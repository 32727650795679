export default {
  methods: {
    TYPES: {
      ADDRESS: 'addresses',
      USER: 'users',
      ITEM: 'items',
      STORIES: 'stories',
      REVIEW: 'reviews',
      NEWS: 'news',
      COMMENT: 'comments',
      OFFER: 'offers',
      DISPUTE: 'disputes',
      CART: 'carts',
      DRAFT_ORDER: 'draftOrders',
      ORDER: 'orders',
      CATEGORY: 'categories',
      PRICES_FILTER: 'pricesFilter',
      CATEGORY_TREE: 'categoryTree',
      SCREEN_TEMPLATE: 'screenTemplates',
      ITEM_PROPERTY: 'itemProperties',
      PROPERTY_VALUE: 'propertyValues'
    },
    monthNames: [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
    ],
    generateParams (rootState, params) {
      if (rootState.localStorage.sessionToken) {
        params.sessionToken = rootState.localStorage.sessionToken
      }
      let result = ''
      Object.getOwnPropertyNames(params).forEach((param) => {
        if (params[param] !== null && params[param] !== undefined && param !== 'endpoint') {
          result += param + '=' + encodeURIComponent(params[param]) + '&'
        }
      })
      return result.slice(0, -1)
    },
    handleCurrentUserResult (context, data, skipPushRsgistration, notifyUserReady = true) {
      if (!data.error) {
        context.$store.commit('api/addCurrentUserToBucket', data[0])
        context.$store.commit('localStorage/setCurrentUser', data[0])
        // console.log(333333)
        // BOBO
        context.$store.commit('localStorage/setSessionToken', data[0].sessionToken)
        // context.$store.commit('localStorage/setSessionToken', 'eb5fcb4b-34d3-4d8c-b814-c7c2598cc72a')
        if (!skipPushRsgistration) {
          this.handalePushNotifications(context)
        }
        context.$store.dispatch('api/handleIdentify').then().catch()
        if (notifyUserReady) {
          context.$root.$emit('on_user_ready')
        }
      } else {
        alert(data.errorDescription)
      }
    },
    handalePushNotifications (context) {
      try {
        const messaging = this.$fire.messaging
        try {
          // dev reload invoke this line more then once...
          messaging.usePublicVapidKey('BNIsvlE7G3kIPuOMxLlk77PuKejwc9fOCBBuimMGXLkO3AH9y-jURGQsO19EKJNBgHOTDNf-hhIgH7s4e1d_muA')
        } catch (e) {
        }
        messaging.getToken().then((currentToken) => {
          if (currentToken) {
            context.$store.commit('localStorage/updatePushToken', { pushToken: currentToken })
            context.$store.dispatch('api/setDeviceToken', currentToken)
          } else {
            // Show permission request.
            console.log('No Instance ID token available. Request permission to generate one.')
            // Show permission UI.
            Notification.requestPermission().then((permission) => {
              console.log('permission', permission)
            })
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err)
        })
        messaging.onTokenRefresh(() => {
          messaging.getToken().then((refreshedToken) => {
            context.$store.commit('localStorage/updatePushToken', { pushToken: refreshedToken })
            context.$store.dispatch('api/setDeviceToken', refreshedToken)
          }).catch((err) => {
            console.log('Unable to retrieve refreshed token ', err)
          })
        })

        messaging.onMessage((payload) => {
          console.log('Message received. ', payload)
        })
      } catch (e) {
      }
    },
    cellsToEntities (rootState, key, saveToLocalStorage) {
      const result = []
      const cellsTypes = {}
      if (rootState.api.cache[key]) {
        rootState.api.cache[key].forEach((cell) => {
          if (this.TYPES[cell.type]) {
            cellsTypes[this.TYPES[cell.type]] = 1
          } else {
            console.log('cell.type', cell.type)
          }
          if (this.TYPES[cell.type]) {
            if (rootState.api.buckets[this.TYPES[cell.type]][cell.id]) {
              result.push(rootState.api.buckets[this.TYPES[cell.type]][cell.id])
            } else if (cell.type === 'ORDER') {
              cellsTypes[this.TYPES.DRAFT_ORDER] = 1
              result.push(rootState.api.buckets[this.TYPES.DRAFT_ORDER][cell.id])
            }
          }
        })
      }
      return result
    },
    async genericApi ({ commit, rootState }, params, $axios = null) {
      const cache = params.cache
      delete params[cache]
      if (rootState.localStorage.sessionToken) {
        params.sessionToken = rootState.localStorage.sessionToken
      }
      if (!cache || !rootState.api.cache[JSON.stringify(params)]) {
        // console.log(process.env.baseUrl + params.endpoint + '?' + this.generateParams(rootState, params))
        const prms = this.generateParams(rootState, params)
        const config = {
          method: 'post',
          url: params.endpoint,
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          },
          data: prms
        }
        const res = await $axios(config)
        // const res = await $axios.post(params.endpoint, this.generateParams(rootState, params))
        commit('updateBuckets', { key: JSON.stringify(params), result: res.data, saveToLocalStorage: params.saveToLocalStorage, rootState })
      }
      return this.cellsToEntities(rootState, JSON.stringify(params), params.saveToLocalStorage)
    },
    async getScreenComponentData (store, screen) {
      let componentData
      if (store.state.localStorage.componentDataCollection && store.state.localStorage.componentDataCollection[screen]) {
        componentData = store.state.localStorage.componentDataCollection[screen]
      } else {
        const cd = await store.dispatch('api/getScreen', {
          screen,
          cache: false
        })
        componentData = cd[0]
        store.commit('localStorage/storeComponentData', { screen, componentData })
      }
      return JSON.parse(JSON.stringify(componentData))
    },
    getFormattedDate (date) {
      date = new Date(date)
      const day = date.getDate()
      const monthIndex = date.getMonth()
      const year = date.getFullYear()
      return this.monthNames[monthIndex] + ' ' + day + ', ' + year
    },
    getItem (store, itemId) {
      return store.state.api.buckets.items[itemId]
    },
    getPropertyValue (store, pvId) {
      return store.state.api.buckets.propertyValues[pvId]
    },
    getUser (store, userId) {
      return store.state.api.buckets.users[userId]
    },
    isInstagramOrFacebookBrowser () {
      const ua = navigator.userAgent || navigator.vendor || window.opera
      const isFacebook = (ua.includes('FBAN')) || (ua.includes('FBAV'))
      const isInstagram = ua.includes('Instagram')
      return isFacebook || isInstagram
    },
    getCardType (number) {
      // visa
      let re = /^4/
      if (number.match(re) != null) {
        return 'visa'
      }

      // Mastercard
      // Updated for Mastercard 2017 BINs expansion
      if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) {
        return 'Mastercard'
      }

      // AMEX
      re = /^3[47]/
      if (number.match(re) != null) {
        return 'amex'
      }

      // Discover
      re = /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/
      if (number.match(re) != null) {
        return 'discover'
      }

      // Diners
      re = /^36/
      if (number.match(re) != null) {
        return 'diners'
      }

      // Diners - Carte Blanche
      re = /^30[0-5]/
      if (number.match(re) != null) {
        return 'diners - carte blanche'
      }

      // JCB
      re = /^35(2[89]|[3-8][0-9])/
      if (number.match(re) != null) {
        return 'jcb'
      }

      // Visa Electron
      re = /^(4026|417500|4508|4844|491(3|7))/
      if (number.match(re) != null) {
        return 'visa electron'
      }

      return ''
    },
    guid () {
      const nav = window.navigator
      const screen = window.screen
      let guid = nav.mimeTypes.length + ''
      guid += nav.userAgent.replace(/\D+/g, '')
      guid += nav.plugins.length
      guid += screen.height || ''
      guid += screen.width || ''
      guid += screen.pixelDepth || ''

      return guid
    },
    removeDuplicates (arr) {
      const uniqueMap = {}
      arr.forEach((item, i) => {
        delete item.id
        uniqueMap[JSON.stringify(item)] = i
      })
      return uniqueMap
    },
    getMobileOperatingSystem () {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone'
      }
      if (/android/i.test(userAgent)) {
        return 'android'
      }
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'ios'
      }

      return 'unknown'
    }
  }
}
