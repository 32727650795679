<template>
  <transition v-if="showModal" name="modal">
    <div class="modal-mask" @click="showModal = false">
      <!--  v-drag    -->
      <div class="modal-wrapper flex-item-bottom">
        <div class="modal-container" :class="{'chat': type === 'CART_CHAT'}" @click.stop="doNothing()">
          <div v-if="type === 'CART_CHAT'" class="chat_title">
            <div style="padding-bottom: 0.5em">
              Contact Us
            </div>
            <div style="padding-bottom: 0.5em; font-weight: normal">
              We usually reply in a few minutes
            </div>
            <img v-lazy-load :data-src="getAvatar()" class="avatar" alt="avatar">
            <img class="minimize" v-lazy-load data-src="/back.svg" @click.stop="showModal = false" alt="back">
          </div>
          <div v-else class="chat_title">
            <img v-lazy-load :data-src="getAvatar()" alt="avatar">
            <div>
              <div>{{ getTitle() }}</div>
              <div v-if="disputes.length > 0 && getDisputeStatus() !== 'CLOSED'">
                Dispute!
              </div>
            </div>
          </div>
          <div ref="comments" :class="{'extended': viewMoreComments}" class="comments_container">
            <template v-if="disputes.length > 0 && getDisputeStatus() !== 'CLOSED'">
              <div
                v-for="comment of reverseDisputes"
                :key="comment.id"
                :class="{'current_user': comment.type === 'USER' , 'other_user': comment.type !== 'USER'}"
                class="mfi_comment"
              >
                <div>{{ comment.createdAt | moment('from') }}</div>
                <div class="mfi_comment_inner">
                  <img class="comment_icon" v-lazy-load data-src="/comment.png" alt="comment">
                  <div>
                    <span
                      v-if="getDisputeUser(comment)"
                      class="pointer"
                      @click="showUser(getDisputeUser(comment))"
                    >{{ getDisputeUser(comment).name }}</span>:
                    <ReadMore
                      v-if="getDisputeUser(comment)"
                      :text="comment.message || comment.comment"
                      :max-char="20"
                    />
                    <div v-else class="system_message">
                      {{ comment.message }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="(reverseMessages.length === 0 || (!userWrote && autoOpen)) && type === 'CART_CHAT'">
              <div>
                <div
                  class="mfi_comment"
                >
                  <div class="mfi_comment_inner last_comment">
                    <div>
                      <span
                        class="pointer username"
                      >GOTit</span> {{ description }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                v-for="(comment, index) of reverseMessages"
                :key="comment.id"
                :class="{'current_user': currentUser && (comment.senderId === currentUser.id || comment.userId === currentUser.id), 'other_user': currentUser && (comment.senderId !== currentUser.id && comment.userId !== currentUser.id)}"
                class="mfi_comment"
              >
                <div>{{ comment.createdAt | moment('from') }}</div>
                <div class="mfi_comment_inner" :class="{'last_comment': index === reverseMessages.length - 1}">
                  <div>
                    <span
                      class="pointer username"
                      @click="showUser($store.state.api.buckets.users[comment.senderId || comment.userId])"
                    >{{
                      $store.state.api.buckets.users[comment.senderId || comment.userId].name
                    }}</span> {{ comment.message || comment.comment }}
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="spacer" />
          <template v-if="disputes.length > 0 && getDisputeStatus() !== 'CLOSED'">
            <div
              v-if="reverseDisputes.length > 3"
              class="view_more_comments pointer"
              @click="viewMoreComments = !viewMoreComments"
            >
              {{ viewMoreComments ? 'Less' : 'View ' }}<span v-if="!viewMoreComments"> {{ reverseDisputes.length - 3 }} more comments</span>
            </div>
          </template>
          <template v-else-if="type !== 'CART_CHAT'">
            <div
              v-if="reverseMessages.length > 3"
              class="view_more_comments pointer"
              @click="viewMoreComments = !viewMoreComments"
            >
              {{ viewMoreComments ? 'Less' : 'View ' }}<span v-if="!viewMoreComments"> {{ reverseMessages.length - 3 }} more comments</span>
            </div>
          </template>
          <div v-if="$store.state.localStorage.currentUser.id > 0 || type === 'CART_CHAT'" class="mfi_leave_comment">
            <input ref="comment" v-model="userComment" type="text" placeholder="Add a comment" @keyup.enter="onEnter">
            <div :class="{'disabled': !userComment, 'enabled': userComment}" class="post" @click="sendComment()">
              Send
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import CommentsComponent from './CommentsComponent'
import utils from '~/mixins/utils'
import ReadMore from '~/components/ReadMore'

export default {
  name: 'ChatComponent',
  comments (newVal, oldVal) {
    this.scrollToBottom()
  },
  // eslint-disable-next-line vue/no-unused-components
  components: { ReadMore, CommentsComponent },
  mixins: [utils],
  data () {
    return {
      showModal: false,
      type: 'ORDER',
      messagesType: 'comments',
      description: 'Hey 👋🏽 Customer care here and we will be happy to answer any question you may have today. We are real people and not a bot 😀 If you need anything please write us back!',
      autoOpen: false,
      messages: [],
      userWrote: false,
      alreadyOpened: false,
      disputes: [],
      reverse: false,
      socket: null,
      filter: '',
      userComment: '',
      entityId: '',
      viewMoreComments: false,
      currentUser: null
    }
  },
  computed: {
    reverseMessages () {
      if (this.reverse) {
        return this.messages.slice().reverse()
      } else {
        return this.messages
      }
    },
    reverseDisputes () {
      if (this.reverse) {
        return this.disputes.slice().reverse()
      } else {
        return this.disputes
      }
    }
  },
  mounted () {
    this.currentUser = this.$store.state.localStorage.currentUser
    if (this.currentUser === null) {
      this.$root.$on('on_user_ready', () => {
        this.currentUser = this.$store.state.localStorage.currentUser
      })
    }
    if (!this.registered) {
      this.registered = true
      this.$root.$on('show_chat', (meta) => {
        this.autoOpen = meta.autoOpen || false
        this.type = meta.type || 'PRIVATE'
        if (this.type === 'CART_CHAT' && this.autoOpen && this.alreadyOpened) {
          return
        }
        this.user = meta.user || null
        this.order = meta.order || null
        this.item = meta.item || null
        this.description = meta.description || 'Hey 👋🏽 Customer care here and we will be happy to answer any question you may have today. We are real people and not a bot 😀 If you need anything please write us back!'
        if (this.type === 'CART_CHAT') {
          this.alreadyOpened = true
          if (this.socket == null) {
            this.socket = this.$nuxtSocket({
              name: 'main',
              reconnection: true,
              query: { sessionToken: this.$store.state.localStorage.sessionToken }
            })
            this.socket.on(this.$store.state.localStorage.currentUser.id.toString(), (data) => {
              this.fetchMessages()
            })
            this.socket.on(this.$store.state.localStorage.sessionToken, (data) => {
              this.fetchMessages()
            })
          }
        }
        this.prepareChat()
        this.fetchMessages().then(() => {
          this.scrollToBottom()
          setTimeout(() => {
            this.scrollToBottom()
          }, 300)
        })
        this.showModal = true
      })
    }
  },
  methods: {
    scrollToBottom () {
      setTimeout(() => {
        const comments = this.$refs.comments
        if (comments) {
          comments.scrollTop = comments.scrollHeight
        }
      }, 400)
    },
    getDisputeUser (dispute) {
      if (dispute.type === 'SYSTEM') {
        return null
      } else {
        const order = this.$store.state.api.buckets.orders[dispute.orderId]
        if (dispute.type === 'USER') {
          return this.$store.state.api.buckets.users[order.buyerId]
        } else if (dispute.type === 'STORE') {
          return this.$store.state.api.buckets.users[order.sellerId]
        } else if (dispute.type === 'GOTIT') {
          return {
            id: 'GOTit',
            name: 'GOTit Customer Care',
            username: 'GOTit'
          }
        }
      }
    },
    showUser (user) {
      if (user.store) {
        this.$router.push({
          path: '/stores/' + this.encrypt(user.id)
        })
      } else {
        this.$router.push({
          path: '/users/' + this.encrypt(user.id)
        })
      }
    },
    getDisputeStatus () {
      if (this.disputes.length === 0) {
        return 'GOTIT'
      } else {
        return this.disputes[this.disputes.length - 1].status
      }
    },
    onEnter () {
      this.sendComment()
    },
    async sendComment () {
      if (this.userComment) {
        // alert(this.type)
        // return
        // eslint-disable-next-line no-unreachable
        switch (this.type) {
          case 'CUSTOMER_CARE':
            if (this.getDisputeStatus() === 'CLOSED') {
              // BOBO
              // showReopenDisputeDialog(view, message);
            } else {
              const comment = {
                orderId: this.order.id,
                type: 'USER',
                message: this.userComment,
                createdAt: new Date(),
                status: this.getDisputeStatus()
              }
              const newComment = await this.$store.dispatch('api/sendDisputeComment', comment)
              newComment.createdAt = new Date()
              this.disputes.push(newComment)
              this.scrollToBottom()
            }
            break
          case 'CART_CHAT': {
            this.$store.dispatch('api/sendLogMessage', 'chat_message_sent').then().catch()
            const data = {
              text: this.userComment,
              sessionToken: this.$store.state.localStorage.sessionToken,
              senderId: this.$store.state.localStorage.currentUser.id,
              screen: window.location.pathname
            }
            if (window.location.pathname.startsWith('/cart')) {
              data.draftOrders = Object.values(this.$store.state.api.buckets.draftOrders)
              data.cartTotals = {
                total: this.$store.state.api.globals.cartTotal,
                shipping: this.$store.state.api.globals.cartShippingTotal,
                credit: this.$store.state.api.globals.cartCredit,
                items: this.$store.state.api.globals.cartCount
              }
            } else if (window.location.pathname.startsWith('/items/')) {
              data.itemId = parseInt(this.decrypt(this.$route.params.id))
            }

            this.userWrote = true
            this.socket.emit('message', data)
            if (window.location.pathname.startsWith('/cart')) {
              this.$store.dispatch('api/sendComment', {
                text: `${this.userComment}`,
                type: 'PRIVATE',
                senderId: this.$store.state.localStorage.currentUser.id,
                screen: window.location.pathname,
                objectId: '344535',
                draftOrders: JSON.stringify(Object.values(this.$store.state.api.buckets.draftOrders)),
                cartTotals: JSON.stringify({
                  total: this.$store.state.api.globals.cartTotal,
                  shipping: this.$store.state.api.globals.cartShippingTotal,
                  credit: this.$store.state.api.globals.cartCredit,
                  items: this.$store.state.api.globals.cartCount
                })
              }).then((newComment) => {
                newComment.createdAt = new Date()
                this.messages.push(newComment)
                this.$root.$emit('refresh-comments', newComment)
                this.scrollToBottom()
              })
            } else if (window.location.pathname.startsWith('/items/')) {
              this.$store.dispatch('api/sendComment', {
                text: `${this.userComment}`,
                type: 'PRIVATE',
                senderId: this.$store.state.localStorage.currentUser.id,
                screen: window.location.pathname,
                objectId: '344535',
                itemId: parseInt(this.decrypt(this.$route.params.id))
              }).then((newComment) => {
                newComment.createdAt = new Date()
                this.messages.push(newComment)
                this.$root.$emit('refresh-comments', newComment)
                this.scrollToBottom()
              })
            } else {
              this.$store.dispatch('api/sendComment', {
                text: `${this.userComment}`,
                type: 'PRIVATE',
                senderId: this.$store.state.localStorage.currentUser.id,
                screen: window.location.pathname,
                objectId: '344535'
              }).then((newComment) => {
                newComment.createdAt = new Date()
                this.messages.push(newComment)
                this.$root.$emit('refresh-comments', newComment)
                this.scrollToBottom()
              })
            }
            break
          }
          case 'OFFER':
          case 'ITEM':
          case 'ORDER':
          case 'PRIVATE':
            this.$store.dispatch('api/sendComment', {
              text: this.userComment,
              type: this.type,
              senderId: this.$store.state.localStorage.currentUser.id,
              objectId: this.entityId
            }).then((newComment) => {
              newComment.createdAt = new Date()
              this.messages.push(newComment)
              this.$root.$emit('refresh-comments', newComment)
              this.scrollToBottom()
            })
            break
          default:
            break
        }
        this.userComment = ''
      }
    },
    getAvatar () {
      return this.user.profilePhotoThumbnail || this.user.image
    },
    getTitle () {
      return this.user.name
    },
    getUser (userId) {
      return this.$store.state.api.buckets.users[userId]
    },
    getOrder (orderId) {
      return this.$store.state.api.buckets.orders[orderId]
    },
    getItem (userId) {
      return this.$store.state.api.buckets.items[userId]
    },
    prepareChat () {
      this.filter = this.type
      switch (this.type) {
        case 'ITEM':
          break
        case 'ORDER':
          this.entityId = this.order.id
          this.user = this.getUser(this.order.sellerId)
          break
        case 'CUSTOMER_CARE':
          this.filter = 'USER'
          this.messagesType = 'disputes'
          this.entityId = this.user.id
          break
        case 'CART_CGAT':
        case 'PRIVATE':
          this.filter = 'PRIVATE'
          this.entityId = this.user.id
          break
        case 'OFFER':
          break
      }
    },
    doNothing () {

    },
    async fetchMessages () {
      let filterParam = ''
      switch (this.type) {
        case 'ITEM':
          break
        case 'ORDER':
          filterParam = this.order.id
          this.disputes = await this.$store.dispatch('api/selectDisputes', {
            filter: 'USER',
            filterParam
          })
          break
        case 'CUSTOMER_CARE':
          this.filter = 'USER'
          this.messagesType = 'disputes'
          break
        case 'CART_CHAT':
        case 'PRIVATE':
          this.filter = 'PRIVATE'
          filterParam = this.user.id
          break
        case 'OFFER':
          break
      }
      this.messages = await this.$store.dispatch('api/selectComments', {
        filter: this.filter,
        filterParam
      })
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  transition: opacity .3s ease;
}

.mobile_design .modal-mask {
  padding: 1em;
}

.modal-wrapper {
  display: inline-flex;
  width: 410px;
  max-height: 800px;
  margin: 1.5em;
}

.mobile_design .modal-wrapper {
  margin: 0.5em;
  max-width: calc(100% - 1em);
  min-height: 160px;
}

.modal-container {
  width: 410px;
  /*margin: 0px auto;*/
  /*font-family: 'HKGroteskRegular', serif;*/
  padding: 0.5em;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
}

.modal-container.chat {
  padding: 0;
}

.flex-item-bottom {
  order: 1;
  flex: 0 1 auto;
  align-self: flex-end;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.message {
  margin-top: 1em;
  margin-bottom: 1em;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footer > div {
  flex-grow: 1;
  text-align: center !important;
}

.margin_right {
  margin-right: 1em;
}

.dialog_title {
  width: 0;
}

.dialog_title.open {
  width: 100%;
  -webkit-transition: width 2s; /* Safari prior 6.1 */
  transition: width 2s;
}

.chat_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 0.3em;
  /*font-family: HKGroteskBold;*/
  font-weight: bold;
}
.chat .chat_title {
  background: linear-gradient(135deg, rgb(0, 0, 0) 0%, rgb(77,77,77) 100%);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

.chat_title img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.3em;
}

.chat .chat_title .avatar {
  border: 1px solid white;
}

.comments_container {
  max-height: 500px;
  min-height: 300px;
  overflow-y: auto;
}

.chat .comments_container {
  padding: 0.5em;
}

.comment_icon {
  width: 26px;
  height: 26px;
  opacity: 0.4;
  margin-right: 0.5em;
}

.mfi_comment {
  border-radius: 5px;
  margin-bottom: 0.3em;
  /*padding: 0.3em;*/
}

.current_user {
  /*background-color: rgba(237, 73, 86, 0.08);*/
}

.other_user {
  /*background-color: rgba(75, 92, 237, 0.08);*/
}

.mfi_comment_inner {
  display: flex;
  align-items: center;
  text-align: left;
  margin-right: 1em;
  border-bottom: 1px solid rgba(77, 78, 84, 0.23);
  padding-bottom: 0.5em;
}

.last_comment {
  border-bottom: unset;
}

.mfi_comment_inner div {
  width: 100%;
  word-wrap: break-word; /* All browsers since IE 5.5+ */
  overflow-wrap: break-word; /* Renamed property in CSS3 draft spec */
}

.mfi_leave_comment {
  display: flex;
  border-top: 1px solid rgba(127, 130, 139, 0.24);
  align-items: center;
  margin-top: 0.7em;
  padding-right: 0.7em;
  padding-top: 0.7em;
}

.chat .mfi_leave_comment {
  margin: 0.5em;
}

.mfi_leave_comment .post {
  color: #ed4956;
  /*font-family: HKGroteskBold;*/
  font-weight: bold;
  cursor: pointer;
}

.mfi_leave_comment .post.disabled {
  opacity: 0.5;
  cursor: unset;
}

.mfi_leave_comment img svg path {
  fill: #ed4956 !important;
}

.mfi_leave_comment input {
  flex-grow: 1;
  border: none;
  /*background-color: rgba(242, 242, 242, 1);*/
  line-height: 32px;
  height: 32px;
  font-weight: 400;
  font-size: 13px;
  color: #333333;
  padding-right: 0.7em;
}

.view_more_comments {
  text-align: center;
  /*font-family: 'HKGroteskBold';*/
  font-weight: bold;
}

.extended {
  max-height: 400px;
}

.comments_container::-webkit-scrollbar {
  -webkit-appearance: none;
}

.comments_container::-webkit-scrollbar:vertical {
  width: 11px;
}

.comments_container::-webkit-scrollbar:horizontal {
  height: 11px;
}

.comments_container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}

.username {
  /*font-family: 'HKGroteskBold';*/
  font-weight: bold;
}

.minimize {
  position: absolute;
  right: 2em;
  margin-top: -5em;
  width: 20px !important;
  height: 20px !important;
  transform: rotate(-90deg);
}
</style>
