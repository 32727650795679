import Vue from 'vue'
import Vuesax from 'vuesax'

import 'vuesax/dist/vuesax.css'
Vue.use(Vuesax, {
  // options here
})

// import Vue from 'vue'
// import { vsButton, vsPopup, vsRadio, vsInput, vsLoading } from 'vuesax'
// import 'vuesax/dist/vuesax.css'
//
// Vue.use(vsButton)
// Vue.use(vsRadio)
// Vue.use(vsPopup)
// Vue.use(vsInput)
// Vue.use(vsLoading)

