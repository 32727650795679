<template>
  <transition v-if="showModalProp" name="modal">
    <div class="modal-mask" @click.stop="hideModal()">
      <div class="modal-wrapper">
        <div class="modal-container" @click.stop="doNothing()">
          <div class="main">
            <BuyItemComponent
              :properties-only="true"
              :entity-id="itemProp ? itemProp.id + '' : item.id + ''"
              @itemAdded="itemAdded"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import BuyItemComponent from './BuyItemComponent'

export default {
  name: 'SlimItemPropertiesDialogComponent',
  components: {
    BuyItemComponent
  },

  data () {
    return {
      showModal: false,
      showModalProp: false,
      itemProp: null,
      item: null,
      showPropetiesOnly: false,
      itemComments: [],
      mainImage: '',
      vfOptions: {
        autoplay: false,
        enableGestures: true,
        autohideTime: this.item && !this.item.image2 ? 1500 : 0
      },
      vfImages: [],
      vfTransitions: ['slide']
    }
  },
  mounted () {
    this.$root.$on('show_slim_item_dialog', (item, args) => {
      this.showModalProp = true
      this.item = item
      this.showPropetiesOnly = (args && args.showPropetiesOnly) || false
      this.item = this.$store.state.api.buckets.items[item.sourceId]
      this.$fb.track('ViewContent', {
        page: 'SlimItemDialog',
        content_ids: item.id,
        content_name: item.name,
        content_type: 'product',
        currency: 'USD',
        value: item.price || 0,
        userId: this.$store.state.localStorage.currentUser.id
      })
      this.$store.dispatch('api/trackEvent', {
        eventName: 'select_item_properties_dialog_view',
        params: {
          path: this.$route.path,
          page: 'item_properties_dialog',
          itemId: this.item.id
        }
      }).then().catch()
      // this.showModal = true
    })
  },
  methods: {
    async fetchComments (id) {
      this.itemComments = await this.$store.dispatch('api/selectComments', {
        filter: 'ITEM',
        filterParam: id
      })
    },
    refreshComments (newComment) {
      this.itemComments.push(newComment)
    },
    hideModal () {
      this.$emit('hide')
      this.showModalProp = false
    },
    doNothing () {
    },
    itemAdded (storeId) {
      this.$store.commit('api/setLastPath', this.$route.name)
      this.$store.commit('api/setItemStoreId', storeId)
      this.$router.push({
        path: '/cart'
      })
      this.showModal = false
    }
  }
}
</script>

<style scoped>
  .modal-mask {
    position: absolute;
    z-index: 9998000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .modal-container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    width: 200px;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .main {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
  }

  .image_gallery {
    display: flex;
    flex-direction: column;
  }

  .image_gallery .top, .image_gallery .bottom {
    display: flex;
    flex-direction: row;
  }

  .thumbs {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .thumb:first-child {
    margin-top: 0;
  }

  .thumb:last-child {
    margin-bottom: 0;
  }

  .main, .item_description {
    width: 568px;
    margin-left: 1em;
  }

  .main img {
    border-radius: 3px;
  }

  .thumb {
    margin-bottom: 17px;
    height: 100px;
  }

  .thumb.selected {
    border: 1px solid #ed4956;
  }

  .box_container {
    border-radius: 3px;
    background-color: white;
    border: 1px solid #efefef;
  }

  .description {
    background-color: white;
  }

  .item_description {
    margin-top: 1em;
    padding: 1em;
  }

  .remove-paginator .flux-pagination, .remove-paginator .flux-controls {
    display: none !important;
  }

  .right, .item_details {
    flex-grow: 1;
    min-width: 400px;
  }

  .close {
    position: relative;
    margin-left: -2em;
  }
</style>
