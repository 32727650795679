<template>
  <div class="buy_item_component">
    <StoreReviewsDialogComponent />
    <div v-if="entity && sourceItem" class="item_details">
      <div v-if="!propertiesOnly && !$device.isMobileOrTablet" class="item_top_detalis">
        <img v-lazy-load :data-src="getItemUser().image" class="mfi_store_icon pointer" @click.stop="showUser(getItemUser())" alt="store image">
        <div class="right">
          <div v-if="getItemUser().name !== getStore().name" class="pointer" @click.stop="showUser(getItemUser())">
            {{ getItemUser().name }}
          </div>
          <div class="store_name">
            <img class="mfi_store_avatar" v-lazy-load data-src="/store_icon.jpg" alt="store icon">
            <span class="pointer" @click.stop="showUser(getStore())">{{ getStore().name }}</span>
          </div>
        </div>
        <div class="spacer" />
        <div
          v-title="'Wantit by users'"
          :class="{'want_it': entity.extra.isWantit === 'true'}"
          class="badge pointer"
          @click="onWantItClick()"
        >
          WANTit {{ entity.wantitCount }}
        </div>
        <div v-title="'GOTit by users'" class="badge">
          <img
            class="gotit_icon"
            v-lazy-load data-src="data:image/svg+xml;utf8,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;16&quot; height=&quot;16&quot; viewBox=&quot;-40, -40, 480,480&quot;><g><path d=&quot;M91.973 21.313 C -31.695 82.528,-40.111 280.701,77.513 361.797 C 212.459 454.835,400.000 383.096,400.000 238.439 L 400.000 180.478 310.018 182.739 L 220.036 185.000 220.018 232.691 C 220.000 279.624,220.436 280.278,247.500 273.899 C 286.032 264.818,263.911 287.407,222.697 299.226 C 150.877 319.824,80.882 178.501,137.518 127.246 C 186.074 83.304,290.337 91.970,303.163 141.014 C 306.877 155.219,317.178 160.000,344.064 160.000 L 380.000 160.000 380.000 85.000 L 380.000 10.000 340.000 10.000 C 318.000 10.000,300.000 14.270,300.000 19.490 C 300.000 24.709,284.395 22.459,265.321 14.490 C 214.996 -6.537,142.471 -3.683,91.973 21.313&quot;/></g></svg>"
            alt=""
            aria-hidden="true"
          >
          {{ entity.gotitCount }}
        </div>
        <div class="share_layout">
          <PopupDialog
            :type="'item'"
            :item="entity"
            :show-dots="true"
            @itemChanged="onItemChanged"
          />
        </div>
      </div>
      <template v-if="!propertiesOnly && !$device.isMobileOrTablet">
        <div class="item_details_title">
          {{ entity.name }}
        </div>
        <div class="price_n_rating">
          <PriceButton
            v-if="parseInt(sourceItem.price) !== 0"
            :item="sourceItem"
            class="price_button"
          />
          <div class="strech" />
          <star-rating
            v-if="sourceItem.reviewAvg"
            v-model="sourceItem.reviewAvg"
            :show-rating="false"
            :read-only="true"
            :star-size="20"
            class="rating"
          />
          <div
            v-if="sourceItem.reviewAvg"
            class="rating_clicker"
            @click="showRating()"
          />
        </div>
      </template>
      <template v-if="!sourceItem.soldOut && (!screen && screen !== 'create_set')">
        <p
          v-for="(propertyValue, key) of itemProps"
          :key="propertyValue.id"
        >
          <label class="property_name">{{ getPropertyName(key) }}</label>
          <span class="trick-select option" :class="{'add_margin_bottom': $device.isMobileOrTablet}">
            <select
              v-model="selectedProperties[key]"
              @change="onItemPropertyChange(getPropertyName(key), selectedProperties[key])"
            >
              <option
                :value="{ id: -2, name: getPropertyName(key) }"
                disabled
                selected
              >Select {{ getPropertyName(key) }}</option>
              <option
                v-for="property in propertyValue"
                :key="property.id"
                :value="property.id"
                :disabled="!getItemProperty(property.id) || getItemProperty(property.id).stock === 0"
              >{{ property.value + getPropertyItemStock(property.id) }}
              </option>
            </select>
          </span>
        </p>
      </template>
      <template v-if="propsTypeToValueMap && !sourceItem.soldOut && (!screen && screen !== 'create_set')">
        <p
          v-for="(option, key) of propsTypeToValueMap"
          ref="plsselect"
          :key="key"
        >
          <label class="property_name">{{ key }}</label>
          <span class="trick-select option">
            <select v-model="selectedProperties[key]" @change="onItemPropertyChange(key, selectedProperties[key])">
              <option :value="{id: -2, name: key}" :name="key" disabled selected>Select {{
                key
              }}</option>
              <option
                v-for="opt of option"
                :key="opt.id"
                :value="opt.value"
                :disabled="!opt.available"
              >{{ opt.value + getStock(opt) }}</option>
            </select>
          </span>
        </p>
      </template>
      <div
        v-if="parseInt(sourceItem.price) !== 0 && (!screen && screen !== 'create_set')"
        :class="{
          'disabled' : sourceItem.soldOut || !userIsReady,
          'renderButton': !renderButton,
          'propertiesOnly': propertiesOnly} "
        class="add_to_cart"
        @click="addToCart()"
      >
        {{
          addingToCart || !finishRequest || (Object.keys(itemProps).length === 0 && propertiesOnly) ? 'Please Wait...' : addedToCart ? 'Added To Cart!' : sourceItem.soldOut ? 'Sold Out' : 'Add To Cart'
        }}
      </div>
      <div
        v-if="parseInt(sourceItem.price) !== 0 && !propertiesOnly"
        class="overview"
      >
        <div class="overview_title">
          Overview
        </div>
        <div v-if="itemShippingTypes && itemShippingTypes.length === 1">
          <img v-lazy-load data-src="/truck.png" alt="shipping icon">
          {{
            itemShippingTypes[0].type + ' - ' + itemShippingTypes[0].description + ' ' + itemShippingTypes[0].businessDaysFrom + ' - ' +
              itemShippingTypes[0].businessDaysTo + ' days ' + (itemShippingTypes[0].cost === 0 ? 'Free' : '$' + itemShippingTypes[0].cost)
          }}
        </div>
        <div>
          <img class="pointer" v-lazy-load data-src="/return-purchase.png" @click="showStorePolicy()" alt="return purchase policy">
          <span class="pointer" @click="showStorePolicy()">Returns & exchanges </span>
          <span class="store_policy pointer" @click="showStorePolicy()">policy</span>
        </div>
      </div>
    </div>
    <div v-else-if="!entity && finishRequest">
      Item Not Fount
    </div>
  </div>
</template>
<script>
import PriceButton from './PriceButton'
import AbstractComponent from './AbstractComponent'
import PopupDialog from './PopupDialog'
import StoreReviewsDialogComponent from './StoreReviewsDialogComponent'
import utils from '~/mixins/utils'

export default {
  name: 'BuyItemComponent',
  components: { StoreReviewsDialogComponent, PopupDialog, PriceButton },
  extends: AbstractComponent,
  mixins: [utils],
  props: {
    item: Object,
    forceUpdate: {
      default: false
    },
    propertiesOnly: {
      default: false
    },
    screen: {
      default: null
    }
  },
  data () {
    return {
      userIsReady: true,
      productMarkupValue: 0,
      markupValue: 0,
      currentUser: null,
      priceRange: null,
      saleitPriceRange: null,
      sourceItem: null,
      renderButton: false,
      itemProps: {},
      isNew: true,
      isSubscriptionEdit: false,
      order: null,
      selectedShipping: null,
      addingToCart: false,
      addedToCart: false,
      finishRequest: false,
      selectedProperties: {},
      variantsSelectedProperties: null,
      currentVariant: null,
      itemShippingTypes: [],
      itemShippingTypesMap: {},
      draftOrder: null,
      draftOrderItem: null,
      shipping: '',
      phone: '',
      email: '',
      comment: '',
      cartId: '',
      price: '',
      total: '',
      propsTypeToValueMap: null,
      globalsReady: false
    }
  },
  computed: {
    areAllOptionsFilled () {
      if (this.sourceItem.variants) {
        return Object.keys(this.selectedProperties).length === this.sourceItem.options.length ||
          (this.sourceItem.options && this.sourceItem.options.length === 1 && this.sourceItem.options[0][0] === 'Title')
      } else {
        return this.itemProps !== null && (Object.keys(this.selectedProperties).length === Object.keys(this.itemProps).length)
      }
    }
  },
  watch: {
    entityId (newVal, oldVal) {
      // console.log('newVal', newVal)
    }
  },
  beforeMount () {
    this.bucketName = 'items'
    this.fetchData = (force) => {
      if (!this.$store.state.api.buckets.items[this.getActualEntityId()] || force) {
        // setTimeout(() => {
        //   this.init()
        // }, 1000)
        this.finishRequest = false
        this.$store.dispatch('api/itemsSelect', {
          filter: 'GET',
          filterParam: this.getActualEntityId(),
          includeComments: true,
          includeProperties: true
        }).then((res) => {
          this.entity = res[0]
          this.init()
        })
      } else {
        this.entity = this.$store.state.api.buckets.items[this.getActualEntityId()]
      }
    }
  },
  mounted () {
    this.currentUser = this.$store.state.localStorage.currentUser
    this.$root.$on('on_globals_ready', () => {
      this.currentUser = this.$store.state.localStorage.currentUser
      this.globalsReady = true
      if (this.entity && this.currentUser) {
        this.init()
      }
    })
    this.userIsReady = this.currentUser != null
    this.$root.$on('user_is_ready', (ready) => {
      this.userIsReady = ready
    })
    if (this.entity && this.currentUser) {
      this.init()
    }
  },
  methods: {
    getStock (opt) {
      return opt.stock > 0 && opt.stock <= 5 ? ` - ${opt.stock} Left` : !opt.stock || opt.stock === 0 ? ' - out of stock' : ''
    },
    getPropertyItemStock (propertyId) {
      const itemProperty = this.getItemProperty(propertyId)
      if (itemProperty && itemProperty.stock > 0 && itemProperty.stock <= 5) {
        return ` - ${itemProperty.stock} Left`
      } else if (!itemProperty || itemProperty.stock === 0) {
        return ' - out of stock'
      } else {
        return ''
      }
    },
    getItemProperty (id) {
      return Object.values(this.$store.state.api.buckets.itemProperties).filter((ipp) => {
        return ipp.propertyValueId === id && ipp.itemId === this.entity.sourceId
      })[0]
    },
    shareBy () {
      this.$root.$emit('show_popup_dialog' + this.entity.id, this.entity)
      // const itemUrl = ''
      // switch (type) {
      //   case 'facebook':
      //     this.openInNewTab('https://www.facebook.com/sharer.php?u=' + itemUrl)
      //     break
      // }
    },
    openInNewTab (url) {
      const win = window.open(url, '_blank')
      win.focus()
    },
    showStorePolicy () {
      // eslint-disable-next-line no-unused-vars
      const store = this.getStore()
      let message = ''
      if ((this.sourceItem.acceptReturns == null || this.sourceItem.acceptReturns === 0) && (this.sourceItem.acceptExchanges == null || this.sourceItem.acceptExchanges === 0)) {
        message = 'This sale is final, non-returnable and non-exchangeable'
      } else if (this.sourceItem.acceptReturns != null && this.sourceItem.acceptReturns > 0 && this.sourceItem.acceptExchanges != null && this.sourceItem.acceptExchanges > 0) {
        message = 'If for any reason you are not happy with your purchase, you may return or exchange the item within ' + this.sourceItem.acceptReturns + ' days of receiving the order. Please note that personalized, special-order items, and items damaged through normal wear and tear are not eligible for return or exchange'
      } else if (this.sourceItem.acceptExchanges != null && this.sourceItem.acceptExchanges > 0) {
        message = 'If for any reason you are not happy with your purchase, you may exchange the item within ' + this.sourceItem.acceptExchanges + ' days of receiving the order. Please note that personalized, special-order items, and items damaged through normal wear and tear are not eligible for exchange\n'
        message += 'This item is non-returnable'
      } else if (this.sourceItem.acceptReturns != null && this.sourceItem.acceptReturns > 0) {
        message = 'If for any reason you are not happy with your purchase, you may return the item within ' + this.sourceItem.acceptReturns + ' days of receiving the order. Please note that personalized, special-order items, and items damaged through normal wear and tear are not eligible for exchange<br/>'
        message += 'This item is non-returnable'
      }

      this.$root.$emit('show_dialog', {
        title: 'Store Return & Exchanges policy',
        message
      })
    },
    showStore () {
      this.$router.push({
        path: '/stores/' + this.encrypt(this.sourceItem.userId)
      })
    },
    async onWantItClick () {
      let wantit = 'false'
      const params = {
        id: this.entity.id,
        type: 'WANTIT'
      }
      let firebaseAction = 'item_wantit'
      if (!this.entity.extra) {
        wantit = 'true'
      } else if (this.entity.extra.isWantit === 'true') {
        wantit = 'false'
        firebaseAction = 'item_unwantit'
      } else {
        wantit = 'true'
      }
      params.typeParam = wantit
      this.entity = await this.$store.dispatch('api/itemExecute', params)
      this.$store.dispatch('api/trackEvent', {
        eventName: firebaseAction,
        params: {
          userId: this.$store.state.localStorage.currentUser.id,
          path: this.$route.path,
          itemId: this.entity.id
        }
      }).then().catch()
    },
    showUser (user) {
      if (user.store) {
        this.$router.push({
          path: '/stores/' + this.encrypt(user.id)
        })
      } else {
        this.$router.push({
          path: '/users/' + this.encrypt(user.id)
        })
      }
    },
    getItemUser () {
      return this.$store.state.api.buckets.users[this.entity.userId]
    },
    getStoreName () {
      if (this.$store.state.api.buckets.users[this.sourceItem.userId]) {
        return this.$store.state.api.buckets.users[this.sourceItem.userId].name
      } else {
        return ''
      }
    },
    getStore () {
      return this.$store.state.api.buckets.users[this.sourceItem.userId]
    },
    init () {
      if (this.entity.saleitPriceRange) {
        const strings = this.entity.saleitPriceRange
          .replace('$', '')
          .replace('$', '')
          .split(' - ')
        this.saleitPriceRange = new Array(2)
        this.saleitPriceRange[0] = this.getPriceBeforeMarkup(parseInt(strings[0], 10))
        this.saleitPriceRange[1] = this.getPriceBeforeMarkup(parseInt(strings[1], 10))
      }

      if (this.entity.priceRange) {
        const strings = this.entity.priceRange
          .replace('$', '')
          .replace('$', '')
          .split(' - ')
        this.priceRange = new Array(2)
        this.priceRange[0] = this.getPriceBeforeMarkup(parseInt(strings[0], 10))
        this.priceRange[1] = this.getPriceBeforeMarkup(parseInt(strings[1], 10))
      }

      if (this.entity.markupValue > -1) {
        this.productMarkupValue = this.entity.markupValue
      } else {
        this.productMarkupValue = this.entity.defaultMarkupValue
      }
      this.productMarkupValue = this.productMarkupValue || 0
      this.sourceItem = this.$store.state.api.buckets.items[this.entity.sourceId]
      this.entity = this.sourceItem
      const user = this.$store.state.api.buckets.users[this.sourceItem.userId]
      if (user.isShopify && !this.sourceItem.variants) {
        // this.fetchData(true)
        // return
      }

      if (user.isShopify && this.sourceItem.variants.length === 1) {
        this.currentVariant = this.$store.state.api.buckets.variants[this.sourceItem.variants[0].id]
      }

      if (this.sourceItem.options && this.sourceItem.options.length === 1 && this.sourceItem.options[0][0] === 'Title') {
        this.currentVariant = this.$store.state.api.buckets.variants[this.sourceItem.variants[0].id]

        // BOBO
        // this.sourceItem.options = []
      }

      if (this.currentVariant) {
        // BOBO
        // this.selectedProperties[]
      }
      this.initDraftOrder(this.sourceItem.id, true)
      if (this.sourceItem.variants) {
        if (!this.$store.state.api.buckets.variants[this.sourceItem.variants[0].id]) {
          // this.fetchData(true)
          // return
        }
        this.propsTypeToValueMap = this.buildPropertyTypeToPropertyValueMapNew(this.sourceItem)
        this.createVariantsIndex(this.sourceItem)
        Object.keys(this.propsTypeToValueMap).forEach((entry, index) => {
          // const propertyValuesArray = entry.value
          // this.variantsSelectedProperties[entry.key] = propertyValuesArray[0].id
          // this.variantsSelectedProperties[entry.key] = -2
        })
        if (this.currentVariant) {
          this.price = this.currentVariant.salePrice || this.currentVariant.price
          this.total = this.currentVariant.salePrice || this.currentVariant.price
        }
      } else {
        if (parseInt(this.sourceItem.price) === 0) {
          this.finishRequest = true
          return
        }
        this.itemProps = this.buildPropertyTypeToPropertyValueMap(this.sourceItem)
        // console.log('this.itemProps')
        // console.log(JSON.stringify(this.itemProps))
        // this.itemShippingTypes = []
        if (!this.sourceItem.shippingTypes) {
          // BOBO fix shipping and properties deletion
          this.fetchData(true)
          return
        }
        this.sourceItem.shippingTypes.forEach((st) => {
          this.itemShippingTypes.push(this.getShippingType(st.id))
          this.itemShippingTypesMap[st.id] = this.getShippingType(st.id)
        })
        if (this.itemShippingTypes.length > 0) {
          this.selectedShipping = this.itemShippingTypes[0].id
        }
        this.price = !this.isSubscriptionEdit
          ? ((this.sourceItem.discountPrice > 0)
              ? this.sourceItem.discountPrice
              : (this.sourceItem.price
                  ? this.sourceItem.price
                  : 0))
          : ((this.sourceItem.discountPrice > 0)
              ? this.sourceItem.discountPrice
              : (this.sourceItem.price
                  ? this.sourceItem.getPrice
                  : 0))
        this.shipping = !this.isSubscriptionEdit
          ? (this.draftOrder.shippingPrice
              ? this.draftOrder.shippingPrice
              : 0)
          : (this.order.shippingPrice
              ? this.order.shippingPrice
              : 0)
        this.total = this.price - this.calcCredit()
        if (Object.keys(this.itemProps).length === 0 && this.propertiesOnly) {
          this.addToCart()
        } else {
          Object.keys(this.itemProps).forEach((key) => {
            // BOBO auto properties selection
            // const propertyValuesArray = this.itemProps[key]
            // this.selectedProperties[key] = propertyValuesArray[0].id
            if (this.propertiesOnly) {
              const propertyValuesArray = this.itemProps[key]
              this.selectedProperties[key] = propertyValuesArray[0].id
            } else {
              this.selectedProperties[key] = { id: -2, name: this.getPropertyName(key) }
            }
          })
        }
      }
      this.finishRequest = true
      setTimeout(() => {
        if (this.entity) {
          this.$store.commit('api/loadItemToCache', this.entity)
        }
      }, 3000)
    },
    getShippingType (id) {
      return this.$store.state.api.buckets.shippingTypes[id]
    },
    getSelectedIndexForPropertyTypeNew (item, propertyType, propertyTypeIndex) {
      if (this.draftOrder.shopifySelectedVariantId) {
        this.currentVariant = this.$store.state.api.buckets.variants[this.draftOrder.shopifySelectedVariantId]
      }
      if (this.draftOrder.stringChosenProperties && !this.isNew) {
        this.setPrices(this.currentVariant, item)
        let selectedValues = this.draftOrder.stringChosenProperties.replace(/ /g, '').split('/')
        if (selectedValues.length > 0 && selectedValues[0].inclueds(',')) {
          selectedValues = selectedValues[0].split(',')
        }
        const propIdToType = this.buildPropertyIdToTypeMapNew(item)
        return propIdToType[propertyType].indexOf(selectedValues[propertyTypeIndex])
      } else {
        return -1
      }
    },
    createVariantsIndex (item) {
      item.variants.forEach((variant) => {
        const variant1 = this.$store.state.api.buckets.variants[variant.id]
        let sb = ''
        variant1.options.forEach((option) => {
          sb += option
        })
        if (!item.variantsIndex) {
          item.variantsIndex = {}
        }
        item.variantsIndex[sb] = variant1
      })
    },
    calcCredit () {
      if (this.sourceItem.subscriptionExtension) {
        return 0
      }
      const credit = this.currentUser.extra.credit ? parseInt('' + this.currentUser.extra.credit) : 0
      if (credit > 0 && new Date().getTime() < this.currentUser.extra.creditValidThru &&
        this.getUser(this.sourceItem.userId).isAdmin &&
        this.sourceItem.price > credit) {
        return credit
      }
      return 0
    },
    onItemChanged (item) {
      this.entity = item
    },
    showRating () {
      this.$root.$emit('show_reviews_dialog', this.sourceItem, 'ITEM')
    },
    buildPropertyTypeToPropertyValueMapNew (item) {
      const filteredVariants = []
      if (item.variants.length === 1) {
        this.currentVariant = this.$store.state.api.buckets.variants[item.variants[0].id]
      } else {
        item.variants.forEach((vrnt) => {
          const variant1 = this.$store.state.api.buckets.variants[vrnt.id]
          if (variant1.quantity > 0) {
            filteredVariants.push(variant1)
          }
        })
      }

      this.setPriceRange(filteredVariants, null, null)
      const propsMap = {}
      const numOfProps = item.options.length
      for (let i = 0; i < numOfProps; i++) {
        const option = item.options[i]
        const propertyType = option[0]
        if (numOfProps === 1 && propertyType.toLowerCase().includes('title')) {
          continue
        }
        const values = option.slice(1, option.length)
        const valuesArr = []
        for (let j = 0; j < option.slice(1, option.length).length; j++) {
          const propertyValue = {}
          propertyValue.value = values[j]
          propertyValue.position = j
          propertyValue.variant = propertyType
          propertyValue.variantPosition = i
          propertyValue.stock = 0
          valuesArr.push(propertyValue)
          filteredVariants.forEach((filteredVariant) => {
            if (filteredVariant.options[i] === propertyValue.value) {
              propertyValue.available = filteredVariant.quantity > 0
              propertyValue.stock = filteredVariant.quantity
            }
          })
        }
        propsMap[propertyType] = valuesArr
      }
      return propsMap
    },
    setPriceRange (filteredVariants, variant, variantsOrder) {
      if (variant) {
        if (variant.quantity === 0) {
          // findViewById(R.id.notes).setVisibility(View.VISIBLE);
          // final TextView variantMassage = findViewById(R.id.variant_massage);
          let options = ''
          if (variantsOrder) {
            variantsOrder.forEach((option) => {
              options += option + ', '
            })
            if (options.endsWith(', ')) {
              options = options.substring(0, options.length() - 2)
              options += ' - '
            }
          }
          this.buyView_notes = options + 'Out of stock'
          this.buyView_currentVariant = null
        } else {
          this.buyView_notes = ''
          this.buyView_itemPrice = '$' + this.getCurrentPrice(variant)
        }
      } else {
        let minPrice = -1
        let maxPrice = -1
        filteredVariants.forEach((filteredVariant) => {
          if (minPrice === -1) {
            minPrice = this.getCurrentPrice(filteredVariant)
            maxPrice = this.getCurrentPrice(filteredVariant)
          } else {
            if (this.getCurrentPrice(filteredVariant) > maxPrice) {
              maxPrice = this.getCurrentPrice(filteredVariant)
            }
            if (this.getCurrentPrice(filteredVariant) < minPrice) {
              minPrice = this.getCurrentPrice(filteredVariant)
            }
          }
        })

        if (filteredVariants.length === 0) {
          let options = ''
          if (variantsOrder != null) {
            variantsOrder.forEach((option) => {
              options += option + ', '
            })
            if (options.endsWith(', ')) {
              options = options.substring(0, options.length() - 2)
              options += ' - '
            }
          }
          this.buyView_notes = options + 'Out of stock'
          this.buyView_totalPrice = '$0'
          this.buyView_currentVariant = null
        } else if (minPrice === maxPrice) {
          this.buyView_notes = ''
          this.buyView_itemPrice = '$' + minPrice
        } else {
          this.buyView_notes = ''
          this.buyView_itemPrice = '$' + minPrice + ' - $' + maxPrice
        }
      }
    },
    initDraftOrder (id, isNew) {
      if (this.isSubscriptionEdit) {
        this.order = this.$store.state.api.buckets.orders[id]
        // TODO Order.revertModifications(order);
      } else if (!isNew) {
        this.draftOrder = this.$store.state.api.buckets.draftOrders[id]
        // TODO this.draftOrder = DraftOrder.revertModifications(draftOrder)
      } else {
        this.draftOrder = {}
        const newItem = this.getItem(id)
        const sourceId = newItem.isSource ? newItem.id : newItem.sourceId
        this.draftOrder.itemId = sourceId
        const sourceItem = this.getItem(sourceId)
        this.draftOrderItem = sourceItem
        const storeId = sourceItem.userId
        this.draftOrder.itemUserId = storeId
        this.draftOrder.userFromId = newItem.userId
        this.draftOrder.sellerId = sourceItem.userId
        this.draftOrder.userPrice = !sourceItem.saleitPrice ? sourceItem.price : sourceItem.saleitPrice
        this.draftOrder.createdAt = new Date()
        if (this.sourceOrderId !== 0) {
          const sourceOrder = this.$store.state.api.buckets.items[this.sourceOrderId]
          this.draftOrder = { ...this.draftOrder, ...sourceOrder }
        }
        this.draftOrder.cartId = 0
        this.comment = this.draftOrder.comment
        this.cartId = this.draftOrder.cartId
      }
      return null
    },
    getPriceBeforeMarkup (price) {
      const markupType = this.entity.markupType || this.markupType
      if (this.markupValue === 0 || this.markupValue === -1) {
        if (markupType === 'absolute') {
          price = price - (this.entity.defaultMarkupValue || 0)
        } else {
          price = price - (this.entity.defaultMarkupValue || 0)
        }
      } else {
        switch (markupType) {
          case 'absolute':
            price = price - (this.markupValue || 0)
            break
          case 'custom':
            price = price - (this.productMarkupValueTmp || 0)
            break
        }
      }
      return Math.round(price)
    },
    buildPropertyTypeToPropertyValueMap (item) {
      const propsMap = {}
      const numOfProps = item.properties ? item.properties.length : 0
      for (let i = 0; i < numOfProps; i++) {
        const propertyValue = this.$store.state.api.buckets.propertyValues[this.$store.state.api.buckets.itemProperties[item.properties[i].id].propertyValueId]
        if (propsMap[propertyValue.propertyType]) {
          propsMap[propertyValue.propertyType].push(propertyValue)
        } else {
          const arrayList = []
          arrayList.push(propertyValue)
          propsMap[propertyValue.propertyType] = arrayList
        }
      }
      Object.keys(propsMap).forEach((key) => {
        propsMap[key].sort((a, b) => (a.orderBy > b.orderBy) ? 1 : ((b.orderBy > a.orderBy) ? -1 : 0))
      })
      return propsMap
    },
    getItem (itemId) {
      return JSON.parse(JSON.stringify(this.$store.state.api.buckets.items[itemId]))
    },
    getUser (userId) {
      return JSON.parse(JSON.stringify(this.$store.state.api.buckets.users[userId]))
    },
    async addToCart () {
      if (this.draftOrder.subscriptionExtension || this.addedToCart || this.sourceItem.soldOut) {
        // TODO handle subscription
        return
      }
      if (Object.values(this.selectedProperties).filter(value => value.id === -2).length > 0) {
        const result = Object.values(this.selectedProperties).map(({ name }) => name)
        this.$root.$emit('show_dialog', {
          title: 'Please select ' + result.filter(item => item).join(', '),
          message: '',
          positiveButton: 'Ok',
          positiveButtonAction: () => {
          }
        })
        return
      }
      if (this.sourceItem.options && this.currentVariant === null) {
        // shopify item
        const arr = []
        this.sourceItem.options.forEach((optn) => {
          arr.push(this.selectedProperties[optn[0]])
        })
        if (arr.length > 0) {
          this.currentVariant = this.sourceItem.variantsIndex[arr.join('')]
        }
      }
      if (this.getUser(this.sourceItem.userId).isShopify && this.currentVariant === null) {
        // TODO U.gui.showAlertMessage(getContext(), "The size/color you selected is out of stock. please choose again", null);
        return
      }
      this.addingToCart = true

      const sourceItem = this.$store.state.api.buckets.items[this.sourceItem.sourceId]
      const store = this.$store.state.api.buckets.users[sourceItem.userId]
      this.$store.dispatch('api/sendLogMessage', 'add_to_cart_start').then().catch()
      this.$store.dispatch('api/trackEvent', {
        eventName: 'add_to_cart',
        params: {
          path: this.$route.path,
          itemId: this.sourceItem.id,
          brand: store.name,
          item_category: this.sourceItem.categories,
          item_name: this.sourceItem.name,
          // price: this.sourceItem.price || 0,
          price: (this.sourceItem.shopifySalePrice || this.sourceItem.shopifyPrice || this.sourceItem.saleitPrice || this.sourceItem.price || 0) - this.getUserCredit(),
          content_ids: this.sourceItem.id
        }
      }).then().catch()
      if (this.$store.state.api.globals.activation && this.$device.isMobileOrTablet) {
        const activations = JSON.parse(this.$store.state.api.globals.activation)
        const atcActivation = activations.filter(actvtn => actvtn.type === 'add_to_cart')[0]
        if (atcActivation) {
          this.$root.$emit('show_credit_timer_dialog', {
            activation: atcActivation
          })
        }
      }
      if (this.isNew) {
        if (this.sourceItem.variants) {
          this.draftOrder.shopifySelectedVariantId = this.currentVariant.id
        }
        this.draftOrder.selectedProperties = Object.values(this.selectedProperties).join(',')
        // eslint-disable-next-line no-unreachable
        this.draftOrder.createdAt = new Date()
        // super set
        // if (this.parentItem != null) {
        //   this.draftOrder.supersetParentId = this.parentItem
        // }
        this.draftOrder.draft = true
        if (this.selectedShipping) {
          const selectedShipping = this.itemShippingTypesMap[this.selectedShipping]
          if (selectedShipping) {
            this.draftOrder.shippingTypeId = selectedShipping.id
          }
          this.draftOrder.shippingPrice = selectedShipping.cost
        }
        // eslint-disable-next-line no-unused-vars
        const data = await this.$store.dispatch('api/draftOrdersAdd', this.draftOrder)
        this.$store.dispatch('api/sendLogMessage', 'add_to_cart_success').then().catch()
        this.$fb.track('AddToCart', {
          page: 'BuyItemComponent',
          content_ids: this.entity.id,
          content_name: this.entity.name,
          content_type: 'product',
          currency: 'USD',
          value: this.entity.price || 0,
          userId: this.$store.state.localStorage.currentUser.id
        })
        // eslint-disable-next-line no-unused-vars
        this.$store.dispatch('api/getGlobals')
          .then((globals) => {
            this.$root.$emit('on_globals_ready')
          })
        this.addingToCart = false
        this.addedToCart = true
        this.selectedShipping = null
        this.selectedProperties = {}
        this.$emit('itemAdded', this.entity.userId)
        this.entity = null
        setTimeout(() => {
          this.addedToCart = false
        }, 2000)
      }
    },
    onItemPropertyChange (propertyName, propertyValue) {
      this.renderButton = true
      this.$store.dispatch('api/trackEvent', {
        eventName: 'item_property_selected',
        params: {
          path: this.$route.path,
          propertyName,
          propertyValue,
          itemId: this.entity.id
        }
      }).then().catch()
      setTimeout(() => {
        this.renderButton = false
      }, 50)
    },
    onPropertyChanged (event, key) {
      this.draftOrder[key] = event.target.value
    },
    getCurrentPrice (variant) {
      if (variant.salePrice) {
        return parseInt(variant.salePrice + 0.5)
      } else {
        return parseInt(variant.price + 0.5)
      }
    },
    setPrices (variant, itemWithoutProps) {
      this.currentVariant = variant
      this.price = 0
      if (variant) {
        this.price = this.getCurrentPrice(variant)
      } else if (!itemWithoutProps.variants) {
        const orderItem = this.$store.state.api.buckets.items[this.draftOrder.itemId]
        this.price = !this.isSubscriptionEdit
          ? ((orderItem.discountPrice > 0) ? orderItem.discountPrice : (orderItem.price ? orderItem.price : 0))
          : ((orderItem.discountPrice > 0) ? orderItem.discountPrice : (orderItem.price ? orderItem.getPrice : 0))
      }
      const total = this.price - this.calcCredit()
      const filteredVariants = []
      if (itemWithoutProps.variants && variant) {
        itemWithoutProps.variants.forEach((vrnt) => {
          const variant1 = this.$store.state.api.buckets.variants[vrnt.id]
          if (variant1.quantity > 0) {
            filteredVariants.push(variant1)
          }
        })
        let variantsOrder = null
        if (variant) {
          variantsOrder = new Array[variant.options.length]()
          for (let i = 0; i < variant.options.length; i++) {
            variantsOrder[i] = variant.options[i]
          }
        }
        this.setPriceRange(filteredVariants, variant, variantsOrder)
        if (this.areAllOptionsFilled()) {
          this.buyView_totalPrice = '$' + total
        } else {
          this.buyView_totalPrice = '$0'
        }
      } else {
        this.buyView_itemPrice = '$' + this.price
        this.buyView_totalPrice = '$' + (total < 0 ? 0 : total)
      }
      this.enableAddToCartButton(variant)
    },
    enableAddToCartButton (variant) {
      if (this.draftOrder && (this.$store.state.api.buckets.users[this.draftOrder.sellerId].isShopify)) {
        if (variant) {
          if (variant.quantity === 0) {
            this.buyView_variantMassage = 'Out of stock'
            this.buyView_notes = ''
            this.currentVariant = null
          } else {
            this.buyView_notes = ''
            this.buyView_enableButtonUpdateCart = true
          }
        } else if (this.areAllOptionsFilled()) {
          this.buyView_notes = ''
          this.buyView_enableButtonUpdateCart = true
        } else {
          this.buyView_enableButtonUpdateCart = false
        }
      } else {
        this.buyView_enableButtonUpdateCart = false
      }
    },
    buildPropertyIdToTypeMapNew (item) {
      const propValueToType = {}
      const numOfProps = item.options.length
      for (let i = 0; i < numOfProps; i++) {
        const propertyType = item.options[i][0]
        const list = []
        for (let i1 = 1; i1 < item.options[i].length; i1++) {
          list.push(item.options[i][i1])
        }
        propValueToType[propertyType] = list
      }
      return propValueToType
    },
    buildPropertyIdToTypeMap (item) {
      const propValueToType = {}
      const numOfProps = item.properties.length
      for (let i = 0; i < numOfProps; i++) {
        const propertyValue = item.properties[i]
        propValueToType[propertyValue.id] = propertyValue.propertyType
      }
      return propValueToType
    },
    getCurrentUser () {
      return this.$store.state.localStorage.currentUser
    }
  }
}
</script>

<style>

.item_details {
  /*font-family: "HKGroteskRegular";*/
  text-align: left;
  background-color: white;
  padding: 1em;
  border-radius: 3px;
}

.mobile_design .item_details {
  padding-top: 0;
}

.mobile_design .modal-mask .item_details {
  padding-top: 1em;
}

.mobile_design .modal-mask .add_margin_bottom {
  margin-bottom: 1em;
}

.item_details_title {
  line-height: 25px;
  font-size: 20px;
  font-weight: 500;
  font-family: 'Questrial', sans-serif;
}

.mf_item_properties {
  display: grid;
  grid-template-columns: repeat(5, auto);
}

.mf_item_properties > div {
  text-align: left;
}

.add_to_cart {
  margin-top: 1em;
  text-align: center;
  padding: 1em;
  border-radius: 4px;
  background-color: #ed4956;
  box-shadow: 0px 0px 4px #7F828B;
  color: white;
  /*font-family: HKGroteskBold;*/
  font-weight: bold;
  font-size: 1.3em;
  cursor: pointer;
}

.add_to_cart.disabled {
  opacity: 0.5;
  pointer-events: none
}

.renderButton {
  pointer-events: unset !important;
  cursor: pointer !important;
}

.mfi_store_avatar {
  width: 17px;
  height: 17px;
}

.mfi_store_icon {
  width: 39px !important;
  height: 39px !important;
  border-radius: 50%;
}

.item_top_detalis {
  display: flex;
  margin-bottom: 1em;
}

.item_top_detalis .right {
  margin-left: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.trick-select.option {
  width: auto;
  display: block;
  margin: 0;
}

.trick-select .selectBox {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #EBECED;
  -webkit-padding-end: 30px;
  -moz-padding-end: 30px;
}

.item_details select {
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  vertical-align: middle;
  margin-top: 0.2em;
  outline: none;
  border: 0;
  width: 100%
}

.item_details select, .selectBox-dropdown, .trick-select .selectBox {
  background: url("/ic_dropdown.svg") #EBECED no-repeat calc(100% - 12px) 50%;
  -webkit-padding-end: 30px;
  -moz-padding-end: 30px;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  vertical-align: middle;
  padding: 10px 12px;
  line-height: 19px;
  border-radius: 5px;
  font-size: 14px;
  color: #393D4D;
  box-sizing: border-box;
  font-weight: 500;
  resize: none;
}

.price_button {
  font-size: 1.4em;
  cursor: unset;
  display: inline-block;
  border: unset;
}

.wantit {
  color: white;
  display: block;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.49);
  line-height: 20px;
  text-align: center;
  padding-left: 0.7em;
  padding-right: 0.7em;
  height: 26px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: none;
  align-self: center;
  border: 0;
  text-shadow: none;
  border-radius: 18px;
  font-size: 13px;
}

.spacer {
  flex-grow: 1;
}

.badge {
  align-self: center;
  display: flex;
  margin-right: 0.3em;
  background-color: #EBECED;
  border-radius: 5px;
  height: 20px;
  align-items: center;
  font-size: 0.85em;
  padding: 0.2em;
}

.badge img {
  width: 15px;
  height: 15px !important;
}

.overview {
  margin-top: 1em;
  border-top: 1px solid rgba(206, 210, 225, 0.32);
  border-bottom: 1px solid rgba(206, 210, 225, 0.32);
  padding: 1em;
}

.overview_title {
  /*font-family: 'HKGroteskBold';*/
  font-weight: bold;
}

.overview img {
  width: 16px;
  margin-right: 1em;
}

.store_policy {
  text-decoration: underline;
}

.share_layout {
  text-align: right;
  display: flex;
  align-items: center;
  align-content: center;
}

.store_name {
  align-self: start;
}

.price_n_rating {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.rating {
  height: 20px;
}

.rating_clicker {
  width: 100px;
  height: 25px;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.gotit_icon {
  margin-right: 0.4em;
  filter: invert(37%) sepia(61%) saturate(1880%) hue-rotate(329deg) brightness(96%) contrast(92%);
}

.want_it {
  background-color: #ed4956;
  color: white;
  /*font-family: 'HKGroteskBold', serif;*/
  font-weight: bold;
}

.propertiesOnly {
  font-size: 14px;
  width: 180px;
}

.property_name {
  white-space: nowrap;
}
</style>
