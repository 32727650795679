<template>
  <transition v-if="showModal" name="modal">
    <div @click="showModal = false" class="modal-mask">
      <div class="modal-wrapper">
        <div @click.stop="doNothing()" class="modal-container">
          <template>
            <h2>View most wanted items for</h2>
            <div v-for="gender of genders" :key="gender" class="devider" @click.stop="onGenderSelected(gender)">
              {{gender}}
            </div>
          </template>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'SelectGenderComponent',
  data () {
    return {
      showModal: false,
      cancelable: true,
      genders: ['All', 'Women\'s', 'Men\'s', 'Cancel']
    }
  },
  mounted () {
    this.$root.$on('select_most_wanted_gender', (arg) => {
      this.showModal = !this.showModal
    })
  },
  methods: {
    onGenderSelected (gender) {
      switch (gender) {
        case 'Cancel':
          break
        default:
          this.$root.$emit('most_wanted_gender_selected', gender)
          break
      }
      this.showModal = false
    },
    doNothing () {
    }
  }
}
</script>

<style scoped>
  .modal-mask {
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .mobile_design .modal-wrapper, .mobile_design .modal-mask {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .modal-container {
    width: 410px;
    margin: 0px auto;
    /*font-family: 'HKGroteskRegular', serif;*/
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
  }

  .mobile_design .modal-container {
    width: 90% !important;
    position: fixed;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  h2 {
    /*font-family: 'HKGroteskBold', serif;*/
    color: #4c505a;
  }

  .devider {
    border-top: 1px solid #EBECED;
    margin-top: 1em;
    padding-top: 1em;
    margin-bottom: 1em;
  }
</style>
