<template>
  <transition v-if="showModal" name="modal">
    <div class="modal-mask" @click="closeDownloadAppModal()">
      <div class="modal-wrapper">
        <div v-if="$store.state.localStorage.currentUser && $store.state.localStorage.currentUser.id < 0" class="top-title">
          Log in to continue
        </div>
        <div class="modal-container" @click.stop="doNothing()">
          <template v-if="state === 'login'">
            <img class="gotit-logo" v-lazy-load data-src="/gotit-logo.jpeg">
            <h3>GOTit is Better on the App</h3>
            <div class="description">
              Better discounts, Better deals. Only on the app
            </div>
            <template v-if="!forgetPassword">
              <div class="login_facebook open-app" @click="openApp()">
                <div>
                  Switch to the App
                </div>
              </div>
              <div class="or">
                OR
              </div>
              <div class="or_devider" />
              <div v-if="!isFacebookOrInstagramBrowser" class="login_facebook bbtn" @click="loginWithFacebook()">
                <img v-lazy-load data-src="/facebook.svg">
                <div>
                  Continue with Facebook
                </div>
              </div>
              <div v-if="!isFacebookOrInstagramBrowser" class="devider" />
            </template>
            <div class="modal-footer" :class="{'margin-top': isFacebookOrInstagramBrowser}">
              <slot name="footer">
                <div><span class="sign_up" @click.stop="login('login')">Login</span> <span class="new_user">or</span> <span class="sign_up" @click.stop="login('register')">Sign up</span></div>
              </slot>
            </div>
          </template>
          <template v-if="state === 'register'">
            <h2>Join GOTit</h2>
            <template v-if="registerStep === 1">
              <!--<div class="register_massage">
                GOTit is your place for ...
              </div>-->
              <div v-if="!isFacebookOrInstagramBrowser" class="login_facebook" @click="loginWithFacebook()">
                <img v-lazy-load data-src="/facebook.svg">
                <div>
                  Join with facebook
                </div>
              </div>
              <div v-if="!isFacebookOrInstagramBrowser" class="login_google" @click="loginWithGoogle()">
                <img v-lazy-load data-src="/google.svg">
                <div>
                  Join with Google
                </div>
              </div>
              <div class="devider" />
              <input v-model="email" :class="{'error': loginError}" class="login_input user_name" placeholder="Email address">
            </template>
            <template v-if="registerStep === 2">
              <div class="sub_title">
                User name
              </div>
              <div class="username">
                <input v-model="username" class="gotit_input" type="text" placeholder="User Name">
                <div v-if="validateUsername" class="lds-dual-ring" />
              </div>
              <span v-if="userNameError" class="username_taken">User name already taken</span>
              <div class="sub_title">
                Password
              </div>
              <input v-model="password" class="gotit_input gotit_register_password" type="password" placeholder="Password">
              <div class="sub_title">
                Name
              </div>
              <input v-model="name" class="gotit_input gotit_register_name" type="text" placeholder="Name">
              <!--<div class="sub_title">
                Phone
              </div>
              <input v-model="phone" class="gotit_input gotit_register_phone" type="phone" placeholder="Phone">-->
              <p class="gender_select">
                <label class="sub_title">Gender</label>
                <span class="trick-select option">
                  <select v-model="gender" class="select-boxes2">
                    <option
                      v-for="(gender, index) in genders"
                      :key="index"
                      :value="gender.toLowerCase()"
                    >{{ gender }}</option>
                  </select>
                </span>
              </p>
              <!--<vs-button @click="popupActive=true" color="primary" type="border">
                Choose Profile Image
              </vs-button>-->
              <vs-popup :active.sync="popupActive" class="holamundo hidden" title="Choose Profile Image">
                <div id="container">
                  <input
                    id="file"
                    ref="file"
                    type="file"
                    name="file"
                    tabIndex="-1"
                    class="inputfile"
                    @change="changeListner($event)"
                  >
                  <a id="choose_file" for="file" tabIndex="-1" class="pointer" @click="onImageClick(file)">Choose Image ></a>
                  <img
                    id="cropped_image"
                    ref="croppedImg"
                    v-lazy-load :data-src="imageData.image"
                    class="image"
                    @click="onImageClick()"
                  >
                  <div>
                    <img
                      v-if="!imageData['imag'] && !imageSrc"
                      id="placeholder"
                      v-lazy-load :data-src="getPlaceHolder()"
                    >

                    <vue-cropper
                      v-if="imageSrc"
                      ref="cropper"
                      v-lazy-load :data-src="imageSrc"
                      :aspect-ratio="1/1"
                      :initial-aspect-ratio="1/1"
                      :cropmove="cropImage"
                      alt="Source Image"
                    />
                    <vs-button v-if="cropImg" color="primary" type="border" @click="saveImage()">
                      OK
                    </vs-button>
                  </div>
                </div>
              </vs-popup>
            </template>

            <div :class="{'disabled': isJoinButtonDisabled}" class="login_gotit" @click.stop="join()">
              <div class="strech">
                Join GOTit
              </div>
            </div>
            <div v-if="registerStep === 1" class="already_gotit">
              <div><span class="new_user">Already in GOTit?</span> <span class="sign_up" @click.stop="changeState('login')">Log In</span></div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import common from '../mixins/mixin'

export default {
  name: 'DownloadAppComponent',
  data () {
    return {
      isFacebookOrInstagramBrowser: false,
      showModal: false,
      cancelable: false,
      forgetPassword: false,
      state: 'login',
      msg: '',
      email: '',
      loginError: '',
      resetPasswordSend: false,
      userNameError: false,
      registerStep: 1,
      hideCanvas: false,
      firebaseUser: null,
      isProfileImage: true,
      file: null,
      imageSrc: null,
      registering: false,
      popupActive: false,
      croppedImageSelected: false,
      onUsernameType: new Subject(),
      cropImg: null,
      validateUsername: false,
      authenticationg: false,
      imageData: {},
      genders: ['Male', 'Female', 'Other'],
      username: '',
      password: '',
      // phone: '',
      name: '',
      gender: '',
      reg: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    }
  },
  computed: {
    isJoinButtonDisabled () {
      if (this.registerStep === 1) {
        return !this.reg.test(this.email)
      } else if (this.registerStep === 2) {
        return !this.formIsValid() || this.registering
      } else {
        return false
      }
    }
  },
  watch: {
    username (newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.onUsernameType.next(newVal)
      }
    }
  },
  mounted () {
    this.isFacebookOrInstagramBrowser = this.isInstagramOrFacebookBrowser()
    this.$fire.auth.onAuthStateChanged((user) => {
      this.firebaseUser = user
    })

    this.showModal = this.$store.state.api.cache.showLoginMenu || false
    this.$root.$on('show_download_app_popup', (arg) => {
      this.showModal = !this.showModal
      this.cancelable = arg.cancelable || true
      this.msg = arg.msg || ''
      this.state = arg.state || 'login'
      this.changeState(this.state)
    })
    this.onUsernameType
      .pipe(debounceTime(400))
      .subscribe(async (userName) => {
        this.validateUsername = true
        this.userNameError = false
        this.userNameExists = false
        const exist = await this.$store.dispatch('api/isUsernameExist', userName)
        if (exist && exist.objects.usernameExists) {
          this.validateUsername = false
          this.userNameExists = exist.objects.usernameExists
          this.userNameError = this.userNameExists
        } else {
          this.userNameError = false
          this.validateUsername = false
        }
      })
  },
  methods: {
    isInstagramOrFacebookBrowser () {
      const ua = navigator.userAgent || navigator.vendor || window.opera
      const isFacebook = (ua.includes('FBAN')) || (ua.includes('FBAV'))
      const isInstagram = ua.includes('Instagram')
      return isFacebook || isInstagram
    },
    isEmailValid () {
      return this.reg.test(this.email)
    },
    replaceResetPasswordLayout (forgetPassword) {
      this.forgetPassword = forgetPassword
      this.resetPasswordSend = false
    },
    loginWithGoogle (pendingCred) {
      const provider = new this.$fireModule.auth.GoogleAuthProvider()
      provider.addScope('profile')
      provider.addScope('email')
      this.$fire.auth.signInWithPopup(provider)
        .then((result) => {
          this.showModal = false
          this.$vs.loading({
            type: 'border'
          })
          if (pendingCred) {
            result.user.linkWithCredential(pendingCred).then().catch()
          }
          // const token = result.credential.accessToken
          const displayName = result.user.displayName
          const email = result.user.email
          const imageUrl = result.additionalUserInfo.profile.picture.replace('=s96-c', '=s150-c')
          let googleId = this.firebaseUser.uid
          if (result.providerData && result.providerData.length === 2) {
            googleId = result.providerData[1].uid
          }
          let firstName = ''
          let lastName = ''
          if (displayName.includes(' ')) {
            firstName = displayName.split(' ')[0]
            lastName = displayName.split(' ')[1]
          } else {
            firstName = displayName
          }
          this.$store.dispatch('api/usersLogin', { googleId, imageUrl, email, firstName, lastName })
            .then((data) => {
              this.$store.dispatch('api/trackEvent', {
                eventName: 'login_complete',
                params: {
                  path: this.$route.path,
                  login_method: 'google',
                  userId: data[0].id
                }
              }).then().catch()
              common.methods.handleCurrentUserResult(this, data)
              this.$vs.loading.close()
              this.$router.replace({
                path: '/'
              })
            })
        }).catch((error) => {
          if (error.code === 'auth/account-exists-with-different-credential') {
            const pendingCred = error.credential
            // const email = error.email
            this.loginWithFacebook(pendingCred)
          /* this.$fire.auth.fetchSignInMethodsForEmail(email).then((methods) => {
            console.log('methods[0]', methods[0])
            const provider = methods[0]
            if (provider === 'google.com') {}
          }) */
          }
        })
    },
    loginWithFacebook () {
      const provider = new this.$fireModule.auth.FacebookAuthProvider()
      provider.addScope('public_profile')
      provider.addScope('email')
      this.$fire.auth.signInWithPopup(provider)
        .then((result) => {
          const token = result.credential.accessToken
          this.$store.dispatch('api/usersLogin', { facebookToken: token })
            .then((data) => {
              this.$store.dispatch('api/trackEvent', {
                eventName: 'login_complete',
                params: {
                  path: this.$route.path,
                  login_method: 'facebook',
                  userId: data[0].id
                }
              }).then().catch()
              common.methods.handleCurrentUserResult(this, data)
              this.$vs.loading.close()
              this.$router.replace({
                path: '/'
              })
            })
        })
    },
    changeState (state) {
      this.state = state
      switch (this.state) {
        case 'login':
          break
        case 'register':
          this.registerStep = 1
          break
      }
    },
    doNothing () {

    },
    closeDownloadAppModal () {
      this.showModal = false
      this.$store.dispatch('api/sendLogMessage', 'open_app_canceled').then().catch()
      this.$store.dispatch('api/trackEvent', {
        eventName: 'open_app_canceled'
      }).then().catch()
    },
    join () {
      switch (this.registerStep) {
        case 1:
          this.registerStep++
          break
        case 2:
          this.register()
          break
      }
    },
    login (state) {
      this.showModal = false
      setTimeout(() => {
        this.$root.$emit('toggle_login_menu', {
          state,
          cancelable: true
        })
      }, 100)
    },
    async resetPassword () {
      if (this.username) {
        const res = await this.$store.dispatch('api/forgotPassword', { username: this.username })
        if (res.response === 'OK') {
          this.resetPasswordSend = true
        }
      }
    },
    openApp () {
      // if (this.$store.state.localStorage.shortLink) {
      //   checkoutErrorMessage += '<br/>'
      //   checkoutErrorMessage += 'You can continue direcly to our app'
      //   checkoutErrorMessage += '<br/>'
      //   checkoutErrorMessage += '<a href="' + this.$store.state.localStorage.shortLink + '">Open app</a>'
      // }
      if ((this.$device.isAndroid || this.$device.isIos) && this.$store.state.localStorage.shortLink && this.$store.state.localStorage.currentUser != null && this.$store.state.localStorage.currentUser.id > 0) {
        this.$store.dispatch('api/sendLogMessage', 'short_link_clicked').then().catch()
        this.$store.dispatch('api/trackEvent', {
          eventName: 'short_link_clicked'
        }).then().catch()
        window.location = this.$store.state.localStorage.shortLink
        this.$store.commit('localStorage/setShortLink', '')
      } else {
        setTimeout(() => {
          if (this.$device.isIos) {
            window.location = 'https://apps.apple.com/sg/app/gotit-social-shopping/id1003017825'
          } else {
            window.location = 'https://xj45h.app.goo.gl/tWib'
          }
        }, 25)
        if (this.$device.isIos) {
          window.location = 'gotit://'
        } else if (this.$device.isAndroid) {
          window.location = 'gotitapp://'
        }
      }
    },
    formIsValid () {
      return this.email && this.username && this.password && /* this.phone && */ this.name && this.gender
    },
    onImageClick () {
      this.$refs.file.click()
    },
    changeListner (event) {
      const image = new Image()
      const file = event.target.files[0]
      // this.imageFile.emit(file)
      const myReader = new FileReader()
      myReader.onloadend = (loadEvent) => {
        image.src = loadEvent.target.result
        this.imageData.imag = image.src
        this.imageSrc = image.src
        // this.$refs.cropper.setImage(image)
        // this.emitCroppedImage()
      }
      myReader.readAsDataURL(file)
    },
    getPlaceHolder () {
      if (this.isProfileImage) {
        return '/avatar.png'
      } else {
        return '/item-placeholder.png'
      }
    },
    saveImage () {
      this.croppedImageSelected = true
      this.popupActive = false
    },
    cropImage () {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    getCropBoxData () {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4)
    },
    getData () {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4)
    },
    async register () {
      this.registering = true
      this.$vs.loading({
        type: 'border'
      })
      let image = ''
      if (this.cropImg) {
        const res = await this.$store.dispatch('api/uploadImageNew', this.cropImg)
        image = res.Location
      }
      const username = this.username
      const password = this.password
      const email = this.email
      const name = this.name
      // const phone = this.phone
      const gender = this.gender
      if (!image) {
        if (gender === 'female') {
          image = 'https://s3.amazonaws.com/app.gotit.static/female.png'
        } else {
          image = 'https://s3.amazonaws.com/app.gotit.static/defult_pic.png'
        }
      }
      const store = false
      const data = await this.$store.dispatch('api/register', {
        username,
        password,
        image,
        email,
        name,
        store,
        // phone,
        gender
      })
      // analytics.log(
      //   AppEventsConstants.EVENT_NAME_COMPLETED_REGISTRATION,
      //   AppEventsConstants.EVENT_PARAM_REGISTRATION_METHOD, if (U.`var`.isEmptyStr(User.current()!!.facebookId)) "Email" else "Facebook")
      this.$store.dispatch('api/trackEvent', {
        eventName: 'registration_complete',
        params: {
          path: this.$route.path,
          registration_method: 'email',
          userId: data[0].id
        }
      }).then().catch()
      this.$vs.loading.close()
      this.registering = false
      this.showModal = false
      common.methods.handleCurrentUserResult(this, data)
    }
  }
}
</script>

<style scoped>
  .modal-mask {
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .top-title {
    margin-top: -2em;
    color: white;
    /*font-family: "HKGroteskBold", serif;*/
    font-weight: bold;
  }

  .modal-wrapper {
    display: table-cell;
  }

  .mobile_design .modal-wrapper, .mobile_design .modal-mask {
    display: flex;
    align-items: flex-start;
  }

  .mobile_design .modal-wrapper {
    justify-content: center;
    align-items: flex-start;
    margin-top: 40%;
  }

  .modal-container {
    text-align: center;
    width: 410px;
    margin: 0px auto;
    /*font-family: 'HKGroteskRegular', serif;*/
    padding: 15px 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
  }

  .mobile_design .modal-container {
    max-width: 80% !important;
    position: fixed;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .login_error {
    color: red;
  }

  h2 {
    /*font-family: 'HKGroteskBold', serif;*/
    font-weight: bold;
    color: #4c505a;
  }

  .login_facebook, .login_google, .login_gotit, .login_register {
    background-color: white;
    width: 100%;
    border-radius: 5px;
    color: #4267b1;
    line-height: 38px;
    font-size: 13px;
    font-weight: bold;
    /*font-family: "HKGroteskBold", serif;*/
    font-weight: bold;
    padding-left: 12px;
    box-sizing: border-box;
    margin-bottom: 14px;
    border: unset;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .bbtn {
    margin-top: 0.5em !important;
  }
  .open-app {
    background-color: #ed4956;
    color: white;
  }

  .disabled {
    opacity: 0.5;
    cursor: unset;
  }

  .login_google {
    background-color: #f5f5f6;
    color: #4c505a;
  }

  .login_facebook img {
    /*filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(263deg) brightness(300%) contrast(100%);*/
  }

  .login_facebook img, .login_google img {
    height: 21px;
    width: 21px;
  }

  .login_input {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    vertical-align: middle;
    padding: 10px 12px;
    line-height: 19px;
    border-radius: 5px;
    font-size: 14px;
    background: #EBECED;
    color: #393D4D;
    box-sizing: border-box;
    width: 100%;
    font-weight: 500;
    resize: none;
    border: 0;
  }

  .login_input.error {
    border: 1px solid #ed4956;
  }

  .user_name, .password {
    margin-bottom: 1em;
  }

  .login_facebook div {
    text-align: center;
    flex-grow: 1;
    margin-left: -21px;
  }

  .login_google div {
    text-align: center;
    flex-grow: 1;
    margin-left: -21px;
  }

  .login_gotit, .login_register {
    background-color: #ed4956;
    text-align: center;
  }

  .modal-footer {
    text-align: center;
    color: #ed4956;
    font-size: 0.9em;
    /*font-family: 'HKGroteskBold', serif;*/
    font-weight: bold;
  }

  .sign_up {
    font-size: 1em;
    color: #ed4956;
    /*font-family: 'HKGroteskBold', serif;*/
    font-weight: bold;
    cursor: pointer;
  }

  .new_user {
    color: #8d919a;
    font-size: 0.9em;
    /*font-family: 'HKGroteskRegular', serif;*/
  }

  .back_to_login {

  }

  .reset_password_layout {
    display: flex;
    flex-direction: column;
  }

  .reset_password_sent {
    color: #8d919a;
    margin-bottom: 1em;
  }

  .already_gotit {
    text-align: center;
  }

  .login_facebook, .register_massage {
    margin-top: 1.5em;
  }

  .register_massage {
    color: #4c505a;
  }
  .sub_title {
    margin-top: 1em;
    color: #393d4d;
    font-size: 12px;
  }

  .gender_select {
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .username_taken {
    color: red;
  }

  .login_error_message {
    margin-bottom: 1em;
    margin-top: -1em;
    color: #b53a44;
  }

  .description {
    color: #4a4a4a;
  }

  .gotit-logo {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-bottom: 1em;
  }
  .or {
    margin-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    display: inline;
    background-color: white;
  }
  .or_devider {
    border-top: 1px solid #EBECED;
    margin-top: -0.75em;
  }
  .devider {
    border-top: 1px solid #EBECED;
    margin-top: -0.5em !important;
    margin-bottom:0.3em;
  }

  .margin-top {
    margin-top: 1em;
  }
</style>
