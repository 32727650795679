<template>
  <transition v-if="showModal" name="modal">
    <div @click="showModal = false" class="modal-mask">
      <div class="modal-wrapper">
        <div @click.stop="doNothing()" class="modal-container">
          Return request
          <div class="item_details">
            <div class="item_image_container">
              <img
                v-lazy-load :data-src="getItem(order.itemId).image"
                class="item_image"
              >
            </div>
            <div class="item_desctiption">
              <div>{{ getItem(order.itemId).name }}</div>
              <div>price: ${{ order.itemPrice }}</div>
              <div class="description">
                {{ getItem(order.itemId).description }}
              </div>
            </div>
          </div>
          <div class="stopper" />
          <div class="store_details">
            <div>
              <img
                v-lazy-load :data-src="getUser(order.sellerId).image"
                class="store_image"
              >
            </div>
            <div>
              <div>{{ getUser(order.sellerId).name }}</div>
              <div>{{ orderStatus[order.status] }}</div>
            </div>
          </div>
          <div>Reason for returning this item</div>
          <input v-model="reason" :disabled="this.order.returnTrackingNumber" class="gotit_input" placeholder="reason">
          <div>{{ order.status >= 8 ? 'Tracking Number:' : 'The moment we accept your request, we ask you to send us shipping tracking number' }}</div>
          <input v-if="order.status >= 8" v-model="trackingNumber" :disabled="this.order.returnTrackingNumber" class="gotit_input" placeholder="tracking #">
          <div class="return_request_actions">
            <div :class="{'disabled' : order.status <= 7 && !reason || order.status === 8 && !trackingNumber}" @click="openReturnRequest()" class="gotit_button">
              {{ getButtonText() }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ReturnRequestDialog',
  data () {
    return {
      order: null,
      reason: '',
      trackingNumber: '',
      showModal: false,
      orderStatus: ['Paid', 'Accepted', 'Rejected', 'Shipped', 'Delivery Confirmed', 'Order failed', 'Pending', 'Request Return', 'Return Accepted', 'Returned Rejected', 'Item Returned', 'Refunded']
    }
  },
  mounted () {
    this.$root.$on('show_return_request_dialog', (arg) => {
      this.order = arg.order
      this.order = this.getOrder(this.order.id)
      this.reason = this.order.returnReason
      this.trackingNumber = this.order.returnTrackingNumber
      this.showModal = true
    })
  },
  methods: {
    getButtonText () {
      if (this.order.status === 7) {
        return 'Update Return Request'
      } else if (this.order.returnTrackingNumber) {
        return 'Close'
      } else {
        return 'Open Return Request'
      }
    },
    async openReturnRequest () {
      if (this.order.returnTrackingNumber) {
        this.showModal = false
        return
      }
      this.$vs.loading({
        type: 'border'
      })
      const returnReason = this.reason
      let status = this.order.status
      if (status < 7) {
        status = 7
      }
      const returnTrackingNumber = this.trackingNumber
      const update = {
        id: this.order.id,
        status,
        returnReason,
        returnTrackingNumber
      }
      // eslint-disable-next-line no-unused-vars
      const updatedOrder = await this.$store.dispatch('api/ordersUpdate', update)
      this.$vs.loading.close()
      this.reason = ''
      this.trackingNumber = ''
      this.showModal = false

      // Rest.update(order);
    },
    doNothing () {

    },
    getItem (itemId) {
      return this.$store.state.api.buckets.items[itemId]
    },
    getOrder (orderId) {
      return this.$store.state.api.buckets.orders[orderId]
    },
    getUser (userId) {
      return this.$store.state.api.buckets.users[userId]
    }
  }
}
</script>

<style scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .mobile_design .modal-wrapper, .mobile_design .modal-mask {
    display: flex;
    align-items: flex-end;
  }

  .modal-container {
    width: 600px;
    margin: 0 auto;
    /*font-family: 'HKGroteskRegular', serif;*/
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
  }

  .mobile_design .modal-container {
    max-width: 100% !important;
    position: fixed;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .item_details {
    padding: unset;
  }
  .store_details {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgba(77, 78, 84, 0.11);
  }

  .store_details {
    margin-bottom: 1em;
  }

  .store_image {
    width: 50px;
    height: 50px;
    margin-right: 1em;
  }

  .item_image_container {
    width: 70px;
    height: 70px;
  }
  .item_image {
    width: 70px;
    height: 70px;
  }

  .return_request_actions {
    margin-top: 2em;
  }

  .item_image_container {
    float: left;
  }
  .item_desctiption {
    padding-left: 1em;
    height: 70px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 1em;
    width: calc(100% - 70px);
  }
  .description {
    white-space: nowrap;
    overflow: hidden;
  }

  .stopper {
    margin-top: 1em;
    border-top: 1px solid rgba(77, 78, 84, 0.11);
    clear: both;
  }
</style>
