<template>
  <transition v-if="showModal || showModalPropReactive" name="modal">
    <div class="modal-mask" @click="hideModal()">
      <div class="modal-wrapper">
        <div class="modal-container" @click.stop="doNothing()">
          <div class="main">
            <div v-if="item && !showPropetiesOnly" id="ic_container">
              <div class="image_gallery">
                <div class="top">
                  <div v-if="screen === 'create_set'" class="crease-set-item-name">
                    {{ item.name }}
                  </div>
                  <div class="thumbs">
                    <img
                      v-for="(image, index) in vfImages"
                      :key="index"
                      v-lazy-load :data-src="image"
                      :class="{'selected': image === mainImage}"
                      class="thumb box_container"
                      @mouseenter="mainImage = image"
                    >
                  </div>
                  <div class="main box_container">
                    <img v-lazy-load :data-src="mainImage" class="main_image">
                  </div>
                </div>
                <div v-if="screen === 'create_set'" class="available-sizes">
                  Available sizes: {{item.properties.map(prprty => this.$store.state.api.buckets.itemProperties[prprty.id].propertyValueId).map(pv => this.$store.state.api.buckets.propertyValues[pv].value).join(', ')}}
                </div>
              </div>
            </div>
            <div v-if="!$device.isMobileOrTablet" class="right">
              <BuyItemComponent
                :entity-id="itemProp ? itemProp.id : item.id + ''"
                :screen="screen"
                @itemAdded="itemAdded"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import BuyItemComponent from './BuyItemComponent'
import utils from '~/mixins/utils'

export default {
  name: 'ItemDialogComponent',
  components: {
    BuyItemComponent
  },
  mixins: [utils],
  props: {
    showModalProp: {
      default: false
    },
    itemProp: null
  },
  data () {
    return {
      showModalPropReactive: this.showModalProp,
      showModal: false,
      item: null,
      showPropetiesOnly: false,
      screen: null,
      itemComments: [],
      mainImage: '',
      vfOptions: {
        autoplay: false,
        enableGestures: true,
        autohideTime: this.item && !this.item.image2 ? 1500 : 0
      },
      vfImages: [],
      vfTransitions: ['slide']
    }
  },
  mounted () {
    this.item = this.itemProp
    this.$root.$on('show_item_dialog', (item, args) => {
      this.screen = (args && args.screen) || null
      this.showPropetiesOnly = (args && args.showPropetiesOnly) || false
      this.item = this.$store.state.api.buckets.items[item.sourceId]
      this.$fb.track('ViewContent', {
        page: 'ItemDialog',
        content_ids: item.id,
        content_name: item.name,
        content_type: 'product',
        currency: 'USD',
        value: item.price || 0,
        userId: this.$store.state.localStorage.currentUser.id
      })

      const sourceItem = this.$store.state.api.buckets.items[item.sourceId]
      const store = this.$store.state.api.buckets.users[sourceItem.userId]
      this.$store.dispatch('api/trackEvent', {
        eventName: 'item_view',
        params: {
          path: this.$route.path,
          page: 'item_dialog',
          itemId: this.item.id,
          brand: store.name,
          item_category: this.item.categories,
          item_name: this.item.name,
          // price: this.item.price || 0,
          price: (this.item.shopifySalePrice || this.item.shopifyPrice || this.item.saleitPrice || this.item.price || 0) - this.getUserCredit(),
          content_ids: this.item.id
        }
      }).then().catch()
      this.vfImages = []
      this.fetchComments(item.id)
      if (this.item.image) {
        this.vfImages.push(this.item.image)
      }
      if (this.item.image2) {
        this.vfImages.push(this.item.image2)
      }
      if (this.item.image3) {
        this.vfImages.push(this.item.image3)
      }
      if (this.item.image4) {
        this.vfImages.push(this.item.image4)
      }
      if (this.item.image5) {
        this.vfImages.push(this.item.image5)
      }
      this.mainImage = this.item.image
      this.showModal = true
    })
  },
  methods: {
    async fetchComments (id) {
      this.itemComments = await this.$store.dispatch('api/selectComments', {
        filter: 'ITEM',
        filterParam: id
      })
    },
    refreshComments (newComment) {
      this.itemComments.push(newComment)
    },
    hideModal () {
      this.showModal = false
      this.showModalPropReactive = false
    },
    doNothing () {
    },
    itemAdded (storeId) {
      this.$store.commit('api/setLastPath', this.$route.name)
      this.$store.commit('api/setItemStoreId', storeId)
      this.$router.push({
        path: '/cart'
      })
      this.showModal = false
    }
  }
}
</script>

<style scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .modal-container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    width: 1150px;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .mobile_design .modal-container {
    width: 100%;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .main {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
  }

  .image_gallery {
    display: flex;
    flex-direction: column;
  }

  .image_gallery .top, .image_gallery .bottom {
    display: flex;
    flex-direction: row;
  }

  .mobile_design .image_gallery .top {
    flex-direction: column;
    align-items: center;
  }

  .thumbs {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .thumb:first-child {
    margin-top: 0;
  }

  .thumb:last-child {
    margin-bottom: 0;
  }

  .main, .item_description {
    width: 568px;
    margin-left: 1em;
  }

  .mobile_design .main, .item_description {
    width: 100%;
    order: 1;
    margin-left: 0;
  }

  .main_image {
    height: 568px;
  }

  .mobile_design .main_image {
    height: 30%;
    min-height: 300px;
  }

  .main img {
    border-radius: 3px;
  }

  .thumb {
    margin-bottom: 17px;
    height: 100px;
  }

  .mobile_design .thumbs {
    order: 2;
    flex-direction: row;
    align-items: unset;
    width: unset;
    overflow-x: scroll;
  }

  .thumb.selected {
    border: 1px solid #ed4956;
  }

  .box_container {
    border-radius: 3px;
    background-color: white;
    border: 1px solid #efefef;
  }

  .description {
    background-color: white;
  }

  .item_description {
    margin-top: 1em;
    padding: 1em;
  }

  .remove-paginator .flux-pagination, .remove-paginator .flux-controls {
    display: none !important;
  }

  .right, .item_details {
    flex-grow: 1;
    min-width: 400px;
  }

  .close {
    position: relative;
    margin-left: -2em;
  }

  .crease-set-item-name {
    margin-bottom: 0.5em;
  }
</style>
