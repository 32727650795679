<template>
  <transition>
    <div>
      <div
        id="show-share"
        @click.stop=""
        v-if="itemChanged"
        :class="{'popup_visible':showModal, 'popup_invisible':!showModal}"
        class="more-share-popup more-share-popup-2 has-arrow"
        style="display: block;"
      >
        <h3 class="tit">
          Share
        </h3>
        <div v-if="type === 'item'" class="wantit_layout" @click.stop="wantit()">
          {{ itemChanged.extra.isWantit === "false" ? 'WANTit' : '√ WANTit' }}
        </div>
        <div v-if="type === 'user'" class="wantit_layout" @click.stop="onFollowClick()">
          {{ userChanged.extra.isFollowed === 'true' ? 'Unfollow' : 'Follow' }}
        </div>
        <div class="frm">
          <div id="more-share-sns" class="more-share-sns" style="display: block;">
            <a
              :href="'https://www.facebook.com/sharer.php?u=' + itemUrl"
              class="fb"
              target="_blank"
            ><img v-title="'share with facebook'" v-lazy-load data-src="/facebook.svg" alt="share with facebook"/></a>
            <a
              :href="'https://twitter.com/share?text=' + itemChanged.name + '&amp;url=' + itemUrl"
              class="tw"
              target="_blank"
            ><img v-title="'share with twitter'" v-lazy-load data-src="/twitter.svg" class="twitter" alt="share with twitter"/></a>
          </div>
        </div>
      </div>
      <div @click.stop="showModal = true" v-if="showDots">
        <img class="share" v-lazy-load data-src="/dots_menu.svg" alt="share menu">
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'PopupDialog',
  props: {
    item: Object,
    type: String,
    showDots: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModal: false,
      itemUrl: '',
      itemChanged: null,
      userChanged: null
    }
  },
  methods: {
    async wantit () {
      let wantit
      const params = {
        id: this.item.id,
        type: 'WANTIT'
      }
      let firebaseAction = 'item_wantit'
      if (!this.itemChanged.extra) {
        wantit = 'true'
      } else if (this.itemChanged.extra.isWantit === 'true') {
        wantit = 'false'
        firebaseAction = 'item_unwantit'
      } else {
        wantit = 'true'
      }
      this.$store.dispatch('api/trackEvent', {
        eventName: firebaseAction,
        params: {
          userId: this.$store.state.localStorage.currentUser.id,
          path: this.$route.path,
          itemId: this.entity
        }
      }).then().catch()
      params.typeParam = wantit
      this.itemChanged = await this.$store.dispatch('api/itemExecute', params)
      this.$emit('itemChanged', this.itemChanged)
    },
    async onFollowClick () {
      let isFollowed = 'true'
      if (this.userChanged.extra.isFollowed === 'true') {
        isFollowed = 'false'
      }
      const params = {
        id: this.userChanged.id,
        typeParam: isFollowed,
        type: 'FOLLOW'
      }
      this.userChanged = await this.$store.dispatch('api/userExecute', params)
      this.$store.dispatch('api/followEvent', {
        isFollowed
      }).then().catch()
    }
  },
  mounted () {
    switch (this.type) {
      case 'item':
        this.itemChanged = this.item
        break
      case 'user':
        this.userChanged = this.item
        break
    }

    this.$root.$on('background_clicked', () => {
      this.showModal = false
    })
    this.$root.$on('show_popup_dialog' + (this.item.id || ''), (item) => {
      this.itemChanged = item
      switch (this.type) {
        case 'item':
          this.itemUrl = 'https://gotitstores.com/items/' + this.item.id
          break
        case 'user':
          this.itemUrl = 'https://gotitstores.com/users/' + this.item.id
          break
      }
      this.showModal = true
    })
  }
}
</script>

<style scoped>

  .wantit_layout {
    padding: 1em;
    border-bottom: 1px solid #f1f1f2;
  }

  .wantit_layout:hover {
    background-color: rgba(204, 209, 223, 0.36);
  }

  .popup_visible {
    height: unset;
    opacity: 1;
    transition: all .3s ease;
  }

  .popup_invisible {
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  #overlay-thing .menu-container .has-arrow, #overlay-thing .menu-container #more-menu {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.2);
    left: auto;
    right: 0;
    background: #fff;
  }

  .more-share-popup {
    width: 200px;
    margin-top: -7em;
    padding: 0px 3px;
  }

  .has-arrow {
    position: absolute;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.2);
  }
  #show-share {
    background-color: white;
    z-index: 100000;
    text-align: left;
    right: 1em;
  }

  #show-share .tit {
    position: relative;
    display: block;
    border-bottom: 1px solid #f1f1f2;
    line-height: 44px;
    color: #414853;
    font-weight: 700;
    padding: 2px 13px 0;
    font-size: 14px;
    text-transform: none;
  }

  #show-share .frm {
    display: block;
    position: relative;
  }

  #show-share .more-share-sns {
    position: relative;
  }

  .more-share-sns {
    padding: 1em;
  }

  .more-share-sns img {
    width: 24px;
    height: 24px;
    margin-right: 0.5em;
    filter: invert(55%) sepia(7%) saturate(391%) hue-rotate(188deg) brightness(91%) contrast(83%);
  }

  .more-share-sns img:hover {
    filter: unset;
  }

  .twitter {
    border-radius: 7px;
  }

  .share {
    width: 25px;
    align-self: end;
    cursor: pointer;
    filter: invert(55%) sepia(7%) saturate(391%) hue-rotate(188deg) brightness(91%) contrast(83%);
  }
</style>
