<template>
  <transition v-if="showModal" name="modal">
    <div class="modal-mask" @click.stop="showModal = false">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="main">
            <div class="users_title">
              {{ title }}
            </div>
            <div v-for="(review, index) in reviews" :key="index" class="user" @click.stop="nothing()">
              <div><img v-lazy-load :data-src="getUser(review.userId).profilePhotoThumbnail || getUser(review.userId).image || 'https://s3.amazonaws.com/app.gotit.images/defult_pic.png'" class="pointer" @click.stop="onUserClick(review.userId)"></div>
              <div class="username">
                <div class="usr pointer" @click.stop="onUserClick(review.userId)">
                  {{ getUser(review.userId).name }}
                </div>
                <div><span v-for="rat in new Array(review.rating)" :key="rat">⭐</span></div>
                <div class="name">
                  {{ review.text }}
                </div>
              </div>
              <div class="date">
                {{ review.createdAt | moment('MM/DD/YYYY') }}
              </div>
              <div class="item pointer">
                <img v-lazy-load :data-src="getItem(review.itemId).itemPhotoThumbnail || getItem(review.itemId).image" @click.stop="onItemClick(review.itemId)">
              </div>
            </div>
            <button v-if="!noMoreScroll && !loading" class="gotit_button load_more" @click.stop="fetchNextPage()">
              Load More
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import utils from '../mixins/utils'
export default {
  name: 'StoreReviewsDialogComponent',
  mixins: [utils],
  data () {
    return {
      showModal: false,
      loading: false,
      title: 'GOTit',
      skip: 0,
      filter: '',
      entity: {},
      skipCounter: 0,
      limit: 20,
      reviews: [],
      noMoreScroll: false
    }
  },
  mounted () {
    this.$root.$on('show_reviews_dialog', async (entity, filter) => {
      this.skip = 0
      this.skipCounter = 0
      this.filter = filter
      this.loading = true
      this.entity = entity
      this.reviews = []
      this.noMoreScroll = false
      this.showModal = true
      this.title = 'GOTit'
      switch (filter) {
        case 'STORE':
          this.title = 'STORE REVIEWS'
          break
        case 'ITEM':
          this.title = 'ITEM REVIEWS'
          break
      }
      this.reviews = await this.$store.dispatch('api/getReviews', {
        filter,
        filterParam: this.entity.id,
        limit: this.limit,
        skip: this.skip
      })
      this.loading = false
      if (this.reviews.length < this.limit) {
        this.noMoreScroll = true
      }
    })
  },
  methods: {
    onUserClick (userId) {
      const user = this.getUser(userId)
      if (user.store) {
        this.$router.push({
          path: '/stores/' + this.encrypt(user.id)
        })
      } else {
        this.$router.push({
          path: '/users/' + this.encrypt(user.id)
        })
      }
    },
    getUser (userId) {
      return this.$store.state.api.buckets.users[userId]
    },
    onItemClick (itemId) {
      this.addItemDetailsToEventsLayer('select_item', itemId)
      this.$router.push({
        path: '/items/' + this.encrypt(itemId)
      })
    },
    getItem (itemId) {
      return this.$store.state.api.buckets.items[itemId]
    },
    getCurrentUser () {
      return this.$store.state.localStorage.currentUser
    },
    async onFollowClick (user, index) {
      let isFollowed = 'true'
      if (user.extra.isFollowed === 'true') {
        isFollowed = 'false'
        user.extra.isFollowed = 'true'
      } else {
        user.extra.isFollowed = 'false'
      }
      const params = {
        id: user.id,
        typeParam: isFollowed,
        type: 'FOLLOW'
      }
      user = await this.$store.dispatch('api/userExecute', params)
      this.users.splice(index, 1, user)
      this.$emit('onFollowChanged', user)
      this.$store.dispatch('api/followEvent', {
        entityId: user.id,
        isFollowed
      }).then().catch()
    },
    nothing () {
    },
    async fetchNextPage () {
      if (!this.loading && !this.noMoreScroll) {
        this.loading = true
        this.skipCounter++
        this.skip = this.limit * this.skipCounter
        this.loading = true
        const reviews = await this.$store.dispatch('api/getReviews', {
          filter: this.filter,
          filterParam: this.entity.id,
          limit: this.limit,
          skip: this.skip
        })
        if (reviews.length === 0) {
          this.noMoreScroll = true
          this.loading = false
        } else {
          if (reviews.length < this.limit) {
            this.noMoreScroll = true
          }
          this.reviews = this.reviews.concat(reviews)
          setTimeout(() => {
            this.loading = false
          }, 1000)
        }
      }
    }
  }
}
</script>

<style scoped>

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .modal-container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    width: 1150px;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .mobile_design .modal-wrapper, .mobile_design .modal-mask {
    display: flex;
    align-items: flex-end;
  }

  .mobile_design .modal-container {
    width: 100%;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .modal-container {
    height: 460px;
    width: 500px;
    padding: unset;
    border-radius: 5px;
  }

  .mobile_design .modal-container {
    width: 100%;
  }

  .main {
    width: 100%;
    overflow: scroll;
    height: 460px;
  }

  .mobile_design .main {
    color: black
  }

  .users_title {
    border-bottom: 1px solid #7F828B;
    padding: 0.5em;
  }

  .user {
    display: flex;
    padding: 0.5em;
    align-items: center;
    font-size: 14px;
  }

  .user img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 0.5em;
  }

  .username {
    text-align: left;
    width: 250px;
  }
  .usr {
    font-weight: 600;
  }

  .date {
    align-self: start;
    flex-grow: 1;
  }

  .item img {
    width: 40px;
    height: 40px;
    border-radius: 0;
  }

  .load_more {
    margin-top: 2em;
    margin-bottom: 3em;
  }
</style>
