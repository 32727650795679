<template>
  <transition v-if="showModal" name="modal">
    <div @click.stop="showModal = false" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="main" ref="main">
            <div class="users_title">
              {{ title }}
            </div>
            <CommentsComponent
              v-if="showModal && comments.length > 0"
              :entity-id="entity.id + ''"
              :reverse="false"
              :dialogMode="true"
              :comments="comments"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CommentsComponent from '~/components/CommentsComponent'
import utils from '~/mixins/utils'

export default {
  name: 'ItemCommentsDialogComponent',
  components: { CommentsComponent },
  mixins: [utils],
  data () {
    return {
      showModal: false,
      loading: false,
      title: 'GOTit',
      skip: 0,
      filter: '',
      entity: {},
      skipCounter: 0,
      limit: 20,
      comments: []
    }
  },
  mounted () {
    // eslint-disable-next-line require-await
    this.$root.$on('show_item_comments_dialog', async (params) => {
      this.skip = 0
      this.skipCounter = 0
      this.filter = params.type
      this.loading = true
      this.comments = []
      this.noMoreScroll = false
      this.showModal = true
      this.title = 'COMMENTS'
      this.entity = params.entity
      this.comments = await this.$store.dispatch('api/selectComments', {
        filter: 'ITEM',
        filterParam: this.entity.id
      })
      this.loading = false
      this.scrollToBottom()
    })
  },
  methods: {
    onUserClick (userId) {
      const user = this.getUser(userId)
      if (user.store) {
        this.$router.push({
          path: '/stores/' + this.encrypt(user.id)
        })
      } else {
        this.$router.push({
          path: '/users/' + this.encrypt(user.id)
        })
      }
    },
    scrollToBottom () {
      setTimeout(() => {
        const main = this.$refs.main
        if (main) {
          main.scrollTop = main.scrollHeight
        }
      }, 10)
    },
    getUser (userId) {
      return this.$store.state.api.buckets.users[userId]
    },
    onItemClick (itemId) {
      this.addItemDetailsToEventsLayer('select_item', itemId)
      this.$router.push({
        path: '/items/' + this.encrypt(itemId)
      })
    },
    getItem (itemId) {
      return this.$store.state.api.buckets.items[itemId]
    },
    getCurrentUser () {
      return this.$store.state.localStorage.currentUser
    },
    async onFollowClick (user, index) {
      let isFollowed = 'true'
      if (user.extra.isFollowed === 'true') {
        isFollowed = 'false'
        user.extra.isFollowed = 'true'
      } else {
        user.extra.isFollowed = 'false'
      }
      const params = {
        id: user.id,
        typeParam: isFollowed,
        type: 'FOLLOW'
      }
      user = await this.$store.dispatch('api/userExecute', params)
      this.users.splice(index, 1, user)
      this.$emit('onFollowChanged', user)
      this.$store.dispatch('api/followEvent', {
        isFollowed
      }).then().catch()
    },
    nothing () {
    }
  }
}
</script>

<style scoped>

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .mobile_design .modal-wrapper, .mobile_design .modal-mask {
    display: flex;
    align-items: flex-end;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .modal-container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
    height: 460px;
    width: 500px;
    padding: unset;
    border-radius: 5px;
  }

  .mobile_design .modal-container {
    max-width: 100% !important;
    position: fixed;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .main {
    width: 100%;
    overflow: scroll;
    height: 460px;
  }

  .users_title {
    border-bottom: 1px solid #7F828B;
    padding: 0.5em;
  }

  .user {
    display: flex;
    padding: 0.5em;
    align-items: center;
    font-size: 14px;
  }

  .user img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 0.5em;
  }

  .username {
    text-align: left;
    width: 250px;
  }

  .usr {
    font-weight: 600;
  }

  .date {
    align-self: start;
    flex-grow: 1;
  }

  .item img {
    width: 40px;
    height: 40px;
  }
</style>
