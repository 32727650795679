export const state = () => ({
  checkout: null
})

export const mutations = {
  setCheckout (state, checkout) {
    state.checkout = checkout
  }
}

export const getters = {
  getCheckout: (state) => {
    return state.checkout
  }
}
