<!-- eslint-disable -->
<template>
  <div
    class="menu_container"
    :class="{'hide_menu': !showMenu}"
    @click="showMenu = false"
  >
    <div class="dialog">
      <div>
        <div class="dialog_container">
          <button v-for="(item, index) in menuTypes[type]" :key="index" class="menu_item" @click="itemAction(item)">
            {{ item.text }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupMenuComponent',
  props: {
    settings: Array
  },
  data () {
    return {
      showMenu: false,
      type: 'profile',
      menuTypes: {
        profile: [
          {
            text: 'Edit Profile',
            action: () => {
              this.$router.push({
                path: '/profile/edit'
              })
            }
          },
          {
            text: 'Log Out',
            action: async () => {
              // eslint-disable-next-line no-unused-vars
              const data = await this.$store.dispatch('api/logout')
              this.$store.commit('localStorage/clear')
              this.$router.push({
                path: '/'
              })
            }
          }
        ],
        store: [],
        user: []
      }
    }
  },
  mounted () {
    this.$root.$on('popup_menu_show', (type) => {
      this.type = type
      this.showMenu = true
    })
  },
  methods: {
    itemAction (item) {
      if (item.action) {
        item.action()
      } else {
        console.log('no action yet')
      }
    }
  }
}
</script>

<style scoped>
  .menu_container {
    visibility: visible;
    opacity: 1;
    max-height: 100%;
    -webkit-transition: visibility 0.2s, opacity 0.2s linear;
    transition: visibility 0.2s, opacity 0.2s linear;
    display: flex;
    align-items: center;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, .65);
    bottom: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    left: 0;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 200;
  }

  .dialog {
    width: 260px;
    position: relative;
    background-color: #fff;
    background-color: rgba(var(--f23, 255, 255, 255), 1);
    -webkit-animation: IGCoreModalShow .1s ease-out;
    animation: IGCoreModalShow .1s ease-out;
    border-radius: 12px;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    margin: auto;
    max-height: calc(100% - 40px);
  }

  .dialog_container:only-child .menu_item:first-child {
    border-top: none;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .menu_item:last-child {
    border-bottom: none;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .menu_item {
    background-color: transparent;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-top: 1px solid #dbdbdb;
    border-top: 1px solid rgba(var(--b6a, 219, 219, 219), 1);
    cursor: pointer;
    line-height: 1.5;
    margin: 0;
    min-height: 48px;
    padding: 4px 8px;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    width: 100%;
  }

  .hide_menu {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0.2s, opacity 0.2s linear;
    transition: visibility 0.2s, opacity 0.2s linear;
  }

  .menu_item:hover {
    background-color: rgba(137, 134, 135, 0.25);
  }
</style>
