<!-- eslint-disable -->
<template>
  <div :class="{'white_text': whiteText}" @click="onBuyItClick()">
    <div
      v-if="sourceItem && sourceItem.soldOut"
      class="mfi_buy_it sold_out"
    >
      Sold Out
    </div>
    <div v-if="screen === 'create_set'">
      ${{ parseInt(item.price) }}
    </div>
    <div
      v-else-if="sourceItem && !getSaleitPriceRange() && !getPriceRange() && ! sourceItem.saleitPrice && getUserCredit() === 0"
      class="pointer final_price"
      :class="{'mfi_buy_it':showBorder, 'final_price_border' : showFinalPriceBorder}"
    >
      <span v-if="showBuyIt">Buy it</span>
      {{ getItemFinalPrice(item.shopifySalePrice || item.shopifyPrice || item.saleitPrice || item.price) }}
    </div>
    <div
      v-else-if="sourceItem && !getSaleitPriceRange() && !getPriceRange() && !sourceItem.saleitPrice && getUserCredit() > 0"
      class="pointer final_price"
      :class="{'mfi_buy_it':showBorder, 'final_price_border' : showFinalPriceBorder}"
    >
      <span v-if="showBuyIt">Buy it </span>
      <span class="old_price">{{ getItemFinalPriceOriginal(sourceItem.shopifySalePrice || sourceItem.shopifyPrice || sourceItem.saleitPrice || sourceItem.price) }}</span>
      <span class="sale_price">{{ getItemFinalPrice(sourceItem.shopifySalePrice || sourceItem.shopifyPrice || sourceItem.saleitPrice || sourceItem.price) }}</span>
    </div>

    <div
      v-else-if="sourceItem && !getSaleitPriceRange() && !getPriceRange()"
      class="long_price_range"
      :class="{'mfi_buy_it':showBorder, 'final_price_border' : showFinalPriceBorder}"
    >
      <span v-if="showBuyIt">Buy it</span>
      <span class="old_price">${{ parseInt(sourceItem.price) }}</span> <span class="sale_price">${{ sourceItem.saleitPrice - getUserCredit() }}</span>
    </div>

    <div
      v-else-if="sourceItem && getSaleitPriceRange() && getPriceRange()"
      class="long_price_range pointer"
      :class="{'mfi_buy_it':showBorder, 'final_price_border' : showFinalPriceBorder}"
    >
      <span v-if="showBuyIt">Buy it</span>
      <span
      class="old_price"
    >{{ getPriceRange() }}</span> <span class="sale_price">{{ getSaleitPriceRange() }}</span>
    </div>

    <div
      v-else-if="sourceItem && getSaleitPriceRange() && !getPriceRange()"
      class="long_price_range pointer"
      :class="{'mfi_buy_it':showBorder, 'final_price_border' : showFinalPriceBorder}"
    >
      <span v-if="showBuyIt">Buy it</span> <span
      class="old_price"
    >${{ item.price }}</span> <span class="sale_price">{{ getSaleitPriceRange() }}</span>
    </div>

    <div
      v-else-if="sourceItem && !!sourceItem.saleitPrice && !getSaleitPriceRange() && getPriceRange()"
      class="pointer"
      :class="{'mfi_buy_it':showBorder}"
    >
      <span v-if="showBuyIt">Buy it</span>
      {{ getPriceRange() }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PriceButton',
  props: {
    item: Object,
    showBuyIt: Boolean,
    showBorder: Boolean,
    screen: {
      default: null
    },
    whiteText: {
      default: false
    },
    showFinalPriceBorder: {
      default: false
    }
  },
  watch: {
    item (newItem, oldItem) {
      this.presentItemPrice()
    }
  },
  data () {
    return {
      sourceItem: this.item,
      saleitPriceRange: null,
      priceRange: null,
      productMarkupValue: 0,
      productMarkupValueTmp: 0
    }
  },
  mounted () {
    this.presentItemPrice()
  },
  methods: {
    presentItemPrice () {
      if (!this.item.source) {
        this.sourceItem = this.$store.state.api.buckets.items[this.item.sourceId]
      }
      if (this.item.saleitPriceRange) {
        const strings = this.item.saleitPriceRange
          .replace('$', '')
          .replace('$', '')
          .split(' - ')
        this.saleitPriceRange = new Array(2)
        const credit = this.getUserCredit()
        this.saleitPriceRange[0] = this.getPriceBeforeMarkup(parseInt(strings[0], 10) - credit)
        this.saleitPriceRange[1] = this.getPriceBeforeMarkup(parseInt(strings[1], 10) - credit)
      }

      if (this.item.priceRange) {
        const strings = this.item.priceRange
          .replace('$', '')
          .replace('$', '')
          .split(' - ')
        this.priceRange = new Array(2)
        const credit = this.getUserCredit()
        this.priceRange[0] = this.getPriceBeforeMarkup(parseInt(strings[0], 10) - credit)
        this.priceRange[1] = this.getPriceBeforeMarkup(parseInt(strings[1], 10) - credit)
      }

      if (this.item.markupValue > -1) {
        this.productMarkupValue = this.item.markupValue
      } else {
        this.productMarkupValue = this.item.defaultMarkupValue
      }
      this.productMarkupValue = this.productMarkupValue || 0
      this.productMarkupValueTmp = this.productMarkupValue
    },
    getSaleitPriceRange () {
      if (this.saleitPriceRange) {
        const credit = this.getUserCredit()
        const min = this.getItemFinalPrice(this.saleitPriceRange[0]) - credit
        const max = this.getItemFinalPrice(this.saleitPriceRange[1]) - credit
        return min + ' - ' + max
      } else {
        return ''
      }
    },
    getUserCredit () {
      return this.$store.state.localStorage.currentUser && this.$store.state.localStorage.currentUser.extra.credit ? parseInt('' + this.$store.state.localStorage.currentUser.extra.credit) : 0
    },
    getPriceRange () {
      if (this.priceRange) {
        const credit = this.getUserCredit()
        const min = this.getItemFinalPrice(this.priceRange[0]) - credit
        const max = this.getItemFinalPrice(this.priceRange[1]) - credit
        return min + ' - ' + max
      } else {
        return ''
      }
    },
    getPriceBeforeMarkup (price) {
      const markupType = this.item.markupType || this.markupType
      if (this.markupValue === 0 || this.markupValue === -1) {
        if (markupType === 'absolute') {
          price = price - (this.item.defaultMarkupValue || 0)
        } else {
          price = price - (this.item.defaultMarkupValue || 0)
        }
      } else {
        switch (markupType) {
          case 'absolute':
            price = price - (this.markupValue || 0)
            break
          case 'custom':
            price = price - (this.productMarkupValueTmp || 0)
            break
        }
      }
      const credit = this.getUserCredit()
      return Math.round(price) - credit
    },
    getItemFinalPriceOriginal (itemPrice) {
      itemPrice = parseInt(itemPrice)
      if (this.item) {
        if (this.markupValue === 0) {
          if (this.item.markupType === 'absolute') {
            itemPrice = itemPrice + (this.item.defaultMarkupValue || 0)
          } else {
            itemPrice = itemPrice + (this.item.defaultMarkupValue || 0)
          }
        } else {
          switch (this.markupType) {
            case 'absolute':
              // itemPrice = itemPrice + (itemPrice / 100 * this.markupValue);
              if (this.markupValue !== -1) {
                itemPrice += this.markupValue
              } else {
                itemPrice += (this.item.defaultMarkupValue || 0)
              }
              break
            case 'custom':
              if (this.productMarkupValueTmp !== -1) {
                itemPrice += this.productMarkupValueTmp
              } else {
                itemPrice += (this.item.defaultMarkupValue || 0)
              }
              break
          }
        }
        return '$' + (Math.round(itemPrice))
      } else {
        return '$0'
      }
    },
    getItemFinalPrice (itemPrice) {
      const credit = this.getUserCredit()
      itemPrice = parseInt(itemPrice)
      if (this.item) {
        if (this.markupValue === 0) {
          if (this.item.markupType === 'absolute') {
            itemPrice = itemPrice + (this.item.defaultMarkupValue || 0)
          } else {
            itemPrice = itemPrice + (this.item.defaultMarkupValue || 0)
          }
        } else {
          switch (this.markupType) {
            case 'absolute':
              // itemPrice = itemPrice + (itemPrice / 100 * this.markupValue);
              if (this.markupValue !== -1) {
                itemPrice += this.markupValue
              } else {
                itemPrice += (this.item.defaultMarkupValue || 0)
              }
              break
            case 'custom':
              if (this.productMarkupValueTmp !== -1) {
                itemPrice += this.productMarkupValueTmp
              } else {
                itemPrice += (this.item.defaultMarkupValue || 0)
              }
              break
          }
        }
        return '$' + (Math.round(itemPrice) - credit)
      } else {
        return '$0'
      }
    },
    onBuyItClick () {
      if (this.item.soldOut) {
        return
      }
      if (this.showBuyIt) {
        this.$store.dispatch('api/trackEvent', {
          eventName: 'buy_item_button_click',
          params: {
            path: this.$route.path,
            itemId: this.item.id
          }
        }).then().catch()
        if (this.$device.isMobileOrTablet) {
          this.$emit('buy_click', this.item)
          this.$root.$emit('show_item_bottom_sheet', this.item)
        } else {
          this.$root.$emit('buy_it', this.item)
          this.$root.$emit('show_item_dialog', this.item)
        }
      }
    }
  }
}
</script>

<style>
  .sale_price {
    color: red;
  }

  .final_price_border .sale_price, .final_price {
    border: 1px solid #ed4956;
    border-radius: 15px;
    padding: 3px 6px;
  }

  .white_text .sale_price {
    /*font-family: HKGroteskBold;*/
    font-weight: bold;
    color: white !important;
  }

  .white_text .final_price_border .sale_price, .final_price {
    border: 1px solid white;
    border-radius: 15px;
    padding: 2px 4px;
  }

  .mobile_design .white_text .final_price_border .sale_price, .final_price {
    border: unset;
    padding: 0;
  }
  .sold_out {
    background-color: unset !important;
    color: unset !important;
  }
</style>
