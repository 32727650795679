import * as Sentry from '~/plugins/sentry'
const PACKAGE_VERSION = '2.0.2'
export default function ({ $axios, redirect }) {
  $axios.interceptors.request.use((value) => {
    try {
      value.data += '&version=' + PACKAGE_VERSION + '&platform=W'
    } catch (e) {
    }
    return value
  })
  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)
    let message = error.message
    if (!message) {
      message = 'interceptor network error'
    }
    if (!isNaN(code)) {
      Sentry.default.captureEvent({
        message: `${message} ${error.response.status}`
      })
    } else {
      Sentry.default.captureEvent({
        message: error.message
      })
    }
  })
}
