<template>
  <span>
    <span v-if="!readMoreActivated && text" v-html="text.slice(0, maxChar)"/>
    <span v-if="readMoreActivated" v-html="text" />
    <span class="read_more_btn" @click="toggleReadMore" v-if="text && maxChar < text.length">
      {{ !readMoreActivated ? 'more' : 'less' }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'ReadMore',
  props: {
    text: String,
    maxChar: Number
  },
  data () {
    return {
      readMoreActivated: false
    }
  },
  methods: {
    toggleReadMore () {
      this.readMoreActivated = !this.readMoreActivated
    }
  }
}
</script>
