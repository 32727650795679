<template>
  <div id="comments_container">
    <div ref="comments" :class="{'extended': viewMoreComments || dialogMode, 'dialog_mode': dialogMode} " class="users_comments_container">
      <div v-for="(comment, index) of reverseComments" :key="comment.id" class="mfi_comment" @mouseenter="showReplayAtIndex(index)" @mouseleave="showReplayAtIndex(-1)">
        <div v-if="$store.state.api.buckets.users[$store.state.api.buckets.comments[comment.id].userId]" class="mfi_comment_comment">
          <span class="username pointer" @click="showUser($store.state.api.buckets.users[$store.state.api.buckets.comments[comment.id].userId])">{{ $store.state.api.buckets.users[$store.state.api.buckets.comments[comment.id].userId].username }}</span>
          <span @click="handleClick">
            <ReadMore
              :text="$store.state.api.buckets.users[$store.state.api.buckets.comments[comment.id].userId].store ? replaceNumbersWithTags($store.state.api.buckets.comments[comment.id].comment) : $store.state.api.buckets.comments[comment.id].comment"
              :max-char="$store.state.api.buckets.users[$store.state.api.buckets.comments[comment.id].userId].store && replaceNumbersWithTags($store.state.api.buckets.comments[comment.id].comment).includes('item-id') ? 200 : 130"
            />
          </span>
        </div>
        <div v-else class="mfi_comment_comment">
          <span class="username pointer">GOTit User</span>
          <span @click="handleClick">
            <ReadMore
              :text="$store.state.api.buckets.users[$store.state.api.buckets.comments[comment.id].userId].store ? replaceNumbersWithTags($store.state.api.buckets.comments[comment.id].comment) : $store.state.api.buckets.comments[comment.id].comment"
              :max-char="$store.state.api.buckets.users[$store.state.api.buckets.comments[comment.id].userId].store && replaceNumbersWithTags($store.state.api.buckets.comments[comment.id].comment).includes('item-id') ? 200 : 130"
            />
          </span>
        </div>
        <div :class="{'show_replay': $store.state.localStorage.currentUser && $store.state.localStorage.currentUser.id > 0 && (index === replayAtIndex || $device.isMobileOrTablet)}" class="replay" @click.stop="replay(comment)">
          reply
        </div>
      </div>
    </div>
    <div v-if="$store.state.localStorage.currentUser && $store.state.localStorage.currentUser.id > 0" class="mfi_leave_comment">
      <input ref="comment" v-model="userComment" type="text" placeholder="Add a comment" @keyup.enter="onEnter">
      <div :class="{'disabled': !userComment, 'enabled': userComment}" class="post" @click="sendComment()">
        Send
      </div>
    </div>
  </div>
</template>

<script>
import utils from '~/mixins/utils'
import ReadMore from '~/components/ReadMore'

export default {
  name: 'CommentsComponent',
  components: { ReadMore },
  mixins: [utils],
  props: {
    entityId: {
      type: String
    },
    comments: {
      type: Array
    },
    reverse: {
      type: Boolean
    },
    dialogMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      userComment: '',
      replayAtIndex: -1,
      viewMoreComments: false
    }
  },
  computed: {
    reverseComments () {
      if (this.comments) {
        if (this.reverse) {
          return this.comments.slice().reverse()
        } else {
          return this.comments
        }
      } else {
        return []
      }
    }
  },
  watch: {
    comments (newVal, oldVal) {
      if (newVal) {
        this.viewMoreComments = newVal.length > 3
        this.scrollToBottom()
      }
    }
  },
  mounted () {
    this.viewMoreComments = this.comments && this.comments.length > 3
    this.scrollToBottom()
  },
  methods: {
    scrollToBottom () {
      setTimeout(() => {
        const comments = this.$refs.comments
        if (comments) {
          comments.scrollTop = comments.scrollHeight
        }
      }, 50)
    },
    getItemIdTag (itemId) {
      return `<span @click='showItem(${itemId})'>${itemId}</span>`
    },
    replaceNumbersWithTags (content) {
      return content.replace(/\b\d+\b/g, (match) => {
        return `<span class="item-id"><b>${match}</b></span>`
      })
    },
    handleClick (event) {
      const clickedElement = event.target.closest('.item-id')
      if (clickedElement) {
        const itemId = clickedElement.innerText
        this.$router.push({
          path: '/items/' + this.encrypt(itemId)
        })
      }
    },
    showReplayAtIndex (index) {
      this.replayAtIndex = index
    },
    replay (comment) {
      if (!this.userComment) {
        this.userComment = '@' + this.$store.state.api.buckets.users[this.$store.state.api.buckets.comments[comment.id].userId].username + ' '
      }
      if (this.$refs.comment) {
        this.$refs.comment.focus()
      }
    },
    showUser (user) {
      if (user.store) {
        this.$router.push({
          path: '/stores/' + this.encrypt(user.id)
        })
      } else {
        this.$router.push({
          path: '/users/' + this.encrypt(user.id)
        })
      }
    },
    async sendComment () {
      if (this.userComment) {
        const newComment = await this.$store.dispatch('api/sendComment', {
          text: this.userComment,
          type: 'ITEM',
          senderId: this.$store.state.localStorage.currentUser.id,
          objectId: this.entityId
        })
        this.$root.$emit('refresh-comments', newComment)
        this.userComment = ''
        setTimeout(this.scrollToBottom, 200)
        this.scrollToBottom()
      }
    },
    onEnter () {
      this.sendComment()
    }
  }
}
</script>

<style scoped>
  #comments_container {
    background-color: white;
    padding-bottom: 1em;
  }

  .users_comments_container {
    max-height: 78px;
    overflow: scroll;
  }

  .dialog_mode {
    max-height: unset !important;
  }

  .comment_icon {
    width: 26px;
    height: 26px;
    opacity: 0.4;
    margin-right: 0.5em;
  }

  .mfi_comment {
    display: flex;
    align-items: center;
    flex-direction: row;
    text-align: left;
    margin-right: 1em;
    margin-left: 1em;
  }
  .mfi_comment_comment {
    flex-grow: 1;
  }

  .replay {
    /*font-family: HKGroteskBold;*/
    font-weight: bold;
    align-self: start;
    opacity: 0;
    cursor: pointer;
  }

  .show_replay {
    opacity: 1;
  }
  .mfi_leave_comment {
    display: flex;
    border-top: 1px solid rgba(127, 130, 139, 0.24);
    align-items: center;
    margin-top: 0.7em;
    padding-right: 0.7em;
    padding-top: 0.7em;
  }

  .mfi_leave_comment .post {
    color: #ed4956;
    /*font-family: HKGroteskBold;*/
    font-weight: bold;
    cursor: pointer;
  }

  .mfi_leave_comment .post.disabled {
    opacity: 0.5;
    cursor: unset;
  }

  .mfi_leave_comment img svg path {
    fill:#ed4956 !important;
  }

  .mfi_leave_comment input {
    flex-grow: 1;
    border: none;
    /*background-color: rgba(242, 242, 242, 1);*/
    line-height: 32px;
    height: 32px;
    font-weight: 400;
    font-size: 13px;
    color: #333333;
    padding-left: 0.7em;
    padding-right: 0.7em;
    margin-left: 0.7em;
  }

  .view_more_comments {
    text-align: center;
    /*font-family: 'HKGroteskBold';*/
    font-weight: bold;
  }

  .username {
    /*font-family: 'HKGroteskBold';*/
    font-weight: bold;
  }

  .extended {
    max-height: 156px;
  }
  .users_comments_container {
    overflow-y: auto;
  }

  .users_comments_container::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .users_comments_container::-webkit-scrollbar:vertical {
    width: 11px;
  }

  .users_comments_container::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  .users_comments_container::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
  }
</style>
