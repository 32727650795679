import Vue from 'vue'
import VuePlyr from 'vue-plyr/dist/vue-plyr.ssr.js'
import 'vue-plyr/dist/vue-plyr.css'

import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'

import videojs from 'video.js'

Vue.use(VueVideoPlayer /* {
  options: global default options,
  events: global videojs events
} */)

Vue.use(VuePlyr, {
  plyr: {
    fullscreen: {
      enabled: true,
      controls: []
    }
  },
  emit: ['ended']
})

// videojs plugin
const Plugin = videojs.getPlugin('plugin')
class ExamplePlugin extends Plugin {
  // something...
}
videojs.registerPlugin('examplePlugin', ExamplePlugin)

// videojs language
videojs.addLanguage('es', {
  Pause: 'Pausa'
  // something...
})
