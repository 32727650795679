import Vue from 'vue'
import numeral from 'numeral'
import numFormat from 'vue-filter-number-format'
// import PayPal from 'vue-paypal-checkout'
import StarRating from 'vue-star-rating'
import VueCodeDiff from 'vue-code-diff'
import VLazyImage from 'v-lazy-image'
import VueEllipseProgress from 'vue-ellipse-progress'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
// import drag from 'v-drag'
import VueObserveVisibility from 'vue-observe-visibility'

Vue.filter('numFormat', numFormat(numeral))
Vue.component('StarRating', StarRating)
Vue.component('CodeDiff', VueCodeDiff)
// Vue.component('PaypalCheckout', PayPal)
Vue.component('VLazyImage', VLazyImage)
Vue.component('VueGoogleAutocomplete', VueGoogleAutocomplete)
Vue.use(VueEllipseProgress)
// Vue.use(drag, {})
Vue.use(VueObserveVisibility)
