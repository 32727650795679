// eslint-disable-next-line no-unused-vars
import Vue from 'vue'

export default {
  methods: {
    encrypt: (itemId) => {
      return Buffer.from('' + itemId).toString('base64')
      /* const decrypted = Vue.CryptoJS.AES.encrypt('' + itemId, 'itemIdDecrypt', {
        mode: Vue.CryptoJS.mode.ECB,
        padding: Vue.CryptoJS.pad.Pkcs7
      }).toString().replace(/\//g, '%2F')
      return decrypted */
    },
    decrypt: (encryptedItemId) => {
      if (encryptedItemId) {
        try {
          return Buffer.from(encryptedItemId, 'base64').toString('ascii').replace(/%2F/g, '\\/')
        } catch (e) {
          return encryptedItemId
        }
      } else {
        return encryptedItemId
      }
      /* return Vue.CryptoJS.AES.decrypt(encryptedItemId, 'itemIdDecrypt', {
        mode: Vue.CryptoJS.mode.ECB,
        padding: Vue.CryptoJS.pad.Pkcs7
      }).toString(Vue.CryptoJS.enc.Utf8).replace(/%2F/g, '\\/') */
    },
    getShippingTypeFnc (id) {
      return this.$store.state.api.buckets.shippingTypes[id]
    },
    getItem (itemId) {
      return this.$store.state.api.buckets.items[itemId]
    },
    getOffer (offerId) {
      return this.$store.state.api.buckets.offers[offerId]
    },
    getUser (userId) {
      return this.$store.state.api.buckets.users[userId]
    },
    getUserCredit () {
      const currentUser = this.$store.state.localStorage.currentUser
      const credit = currentUser && currentUser.extra.credit ? parseInt('' + currentUser.extra.credit) : 0
      return credit
    },
    addItemDetailsToEventsLayer (eventName, itemId) {
      if (window.dataLayer) {
        const item = this.$store.state.api.buckets.items[itemId]
        const sourceItem = this.$store.state.api.buckets.items[item.sourceId]
        const store = this.$store.state.api.buckets.users[sourceItem.userId]
        this.$gtag('event', 'select_item', {
          event: eventName,
          item_list_id: 'related_products',
          item_list_name: 'Related products',
          items: [{
            item_id: item.id,
            item_name: item.name,
            item_brand: store.name,
            item_category: item.categories,
            // price: (sourceItem.price || 0) - this.getUserCredit(),
            price: (sourceItem.shopifySalePrice || sourceItem.shopifyPrice || sourceItem.saleitPrice || sourceItem.price || 0) - this.getUserCredit(),
            quantity: 1,
            currency: 'USD'
          }]
        })
      }
      this.$store.dispatch('api/trackEvent', {
        eventName,
        params: {
          path: this.$route.path
        }
      }).then().catch()
    },
    addItemDetailsToEventsLayerMultipleItems (itemListName, eventName, items) {
      if (window.dataLayer) {
        // view_item_list
        const arr = []
        items.forEach((item) => {
          const sourceItem = this.$store.state.api.buckets.items[item.sourceId]
          const store = this.$store.state.api.buckets.users[sourceItem.userId]
          arr.push({
            item_id: item.id,
            item_name: item.name,
            item_brand: store.name,
            item_category: item.categories,
            // price: (sourceItem.price || 0) - this.getUserCredit(),
            price: (sourceItem.shopifySalePrice || sourceItem.shopifyPrice || sourceItem.saleitPrice || sourceItem.price || 0) - this.getUserCredit(),
            item_list_id: 'related_products',
            item_list_name: itemListName,
            quantity: 1,
            currency: 'USD'
          })
        })
        this.$gtag('event', eventName, {
          currency: 'USD',
          item_list_id: 'related_products',
          item_list_name: itemListName,
          items: arr
        })
      }

      this.$store.dispatch('api/trackEvent', {
        eventName,
        params: {
          path: this.$route.path
        }
      }).then().catch()
    },
    getPropertyName (key) {
      if (!this.$store.state.api.buckets.propertyTypes[key]) {
        this.$store.dispatch('api/logSentryError', 'property not exists ' + key).then().catch()
        // return ''
      }
      return this.$store.state.api.buckets.propertyTypes[key].name
    }
  }
}
