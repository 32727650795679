<template>
  <transition v-if="showModal" name="modal">
    <div @click="showModal = false" class="modal-mask">
      <div class="modal-wrapper">
        <div @click.stop="doNothing()" class="modal-container">
          <div v-if="state === 'main'" class="main_container">
            <div v-for="(share, i) in mainOptions" @click="onMainMenuItemClick(share.name)" class="menu_item" :key="'share' + i">
              <div>
                {{ share.name }}
              </div>
            </div>
          </div>
          <div v-if="state === 'share'" class="share_container">
            <div class="share_dialog_title">
              Share
            </div>
            <div v-for="(share, j) in shareOptions" @click="onShareMenuItemClick(share.name)" class="menu_item" :key="'share2' + j">
              <div class="icon">
                <img v-lazy-load :data-src="share.icon" v-if="share.icon">
              </div>
              <div>{{ share.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import utils from '~/mixins/utils'

export default {
  name: 'ContextMenuDialogComponent',
  mixins: [utils],
  data () {
    return {
      showModal: false,
      state: 'main',
      item: {},
      mainOptions: [
        {
          name: 'Share'
        },
        {
          name: 'Go to Item'
        },
        {
          name: 'Copy Link'
        },
        {
          name: 'Cancel'
        }
      ],
      shareOptions: [
        {
          name: 'Share to Facebook',
          icon: '/facebook_2.svg'
        },
        {
          name: 'Share to Messenger',
          icon: '/messenger.svg'
        },
        {
          name: 'Share to Twitter',
          icon: '/twitter_2.svg'
        },
        {
          name: 'Share via Email',
          icon: '/email.svg'
        },
        {
          name: 'Copy Link',
          icon: '/link.svg'
        },
        {
          name: 'Cancel'
        }
      ]
    }
  },
  computed: {},
  mounted () {
    this.$root.$on('show_share_menu', (arg) => {
      this.showModal = true
      this.state = 'main'
      this.item = arg.item
    })
  },
  methods: {
    onMainMenuItemClick (itemName) {
      switch (itemName) {
        case 'Share':
          this.state = 'share'
          break
        case 'Go to Item':
          this.addItemDetailsToEventsLayer('select_item', this.item.id)
          this.$router.push({
            path: '/items/' + this.encrypt(this.item.id)
          })
          this.showModal = false
          break
        case 'Copy Link':
          this.copyToClipboard('https://gotitstores.com/items/' + this.item.id)
          this.$toast.show('Copied to clip board!')
          setTimeout(() => {
            this.$toast.clear()
          }, 2000)
          this.showModal = false
          break
        case 'Cancel':
          this.showModal = false
          break
      }
    },
    onShareMenuItemClick (itemName) {
      const shareUrl = 'https://gotitstores.com/items/' + this.item.id
      switch (itemName) {
        case 'Share to Facebook':
          window.open('https://www.facebook.com/sharer/sharer.php?u=' + shareUrl, 'pop', 'width=600, height=400, scrollbars=no')
          break
        case 'Share to Messenger':
          window.open('https://www.facebook.com/dialog/send?app_id=718831318242652&link=' + encodeURIComponent(shareUrl) + '&redirect_uri=' + encodeURIComponent(shareUrl) + '&app_id=' + encodeURIComponent('718831318242652'))
          break
        case 'Share to Twitter':
          window.open('https://twitter.com/intent/tweet?text=' + encodeURIComponent('See this item on GOTit: ' + shareUrl), 'pop', 'width=600, height=400, scrollbars=no')
          break
        case 'Share via Email':
          window.open('mailto:?Subject=' + encodeURIComponent('Check this out') + '&body=' + encodeURIComponent('See this item on GOTit: ' + shareUrl), 'pop', 'width=600, height=400, scrollbars=no')
          break
        case 'Cancel':
          this.showModal = false
          break
      }
    },
    doNothing () {
    },
    copyToClipboard (str) {
      const el = document.createElement('textarea')
      el.value = str
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    }
  }
}
</script>

<style scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 410px;
    margin: 0px auto;
    /*font-family: 'HKGroteskRegular', serif;*/
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    padding-bottom: 0.4em;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .share_dialog_title {
    text-align: center;
    /*font-family: HKGroteskBold;*/
    font-weight: bold;
    padding: 0.8em;
    font-size: 16px;
    border-bottom: 1px solid rgba(206, 210, 225, 0.32);
  }

  .menu_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.8em 0.8em 0.8em 1em;
    cursor: pointer;
    /*border-bottom: 1px solid rgba(206, 210, 225, 0.32);*/
  }

  .menu_item:hover {
    background-color: rgba(77, 78, 84, 0.03);
  }

  .menu_item:active {
    background-color: rgba(77, 78, 84, 0.06);
  }

  .icon {
    width: 25px;
    margin-right: 1em;
  }

  .icon img {
    width: 100%;
  }

  .main_container .menu_item {
    display: block;
    text-align: center;
    border-bottom: 1px solid rgba(206, 210, 225, 0.32);
  }
</style>
