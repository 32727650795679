<template>
  <div
    :class="{'mobile_design': $device && $device.isMobileOrTablet,'is_ios': $device && $device.isIos}"
    class="root_container"
  >
    <v-navigation-drawer
      v-if="$device.isMobileOrTablet"
      v-model="drawer"
      :color="color"
      absolute
      dark
    >
      <v-list
        dense
        nav
        class="py-0"
      >
        <v-list-item v-if="user && user.name.toLowerCase() !== 'guest'" class="login_section" two-line>
          <v-list-item-avatar>
            <img v-lazy-load :data-src="user.profilePhotoThumbnail" alt="profile" @click.stop="navigate('/profile')">
          </v-list-item-avatar>

          <v-list-item-content class="profile_details">
            <v-list-item-title class="black_text" @click.stop="navigate('/profile')">
              {{ user.name }}
            </v-list-item-title>
            <v-list-item-subtitle class="black_text" @click.stop="navigate('/profile')">
              {{ user.username }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-content v-else class="login_section">
          <div class="gotit_button loginnn_button" @click.stop="registerGotit()">
            Register / Login
          </div>
        </v-list-item-content>
        <v-divider />
        <client-only>
          <v-list-item class="mobile_menu">
            <div class="browse_gotit">
              Browse GOTit
            </div>
            <vue-tree-navigation
              v-if="$device.isMobileOrTablet"
              :items="categories"
              :default-open-level="0"
            />
            <div class="get_the_app" @click.stop="getTheApp()">
              <img v-lazy-load data-src="/gotit-logo.jpeg" class="get_the_app_icon" alt="gotit logo">
              <div class="texts">
                <div class="get_the_app_name">
                  GOTit
                </div>
                <div class="get_the_app_description">
                  download the app to shop<br>and checkout faster
                </div>
              </div>
              <div class="spacer" />
              <div class="get_the_app_button">
                Get App
              </div>
            </div>
            <div class="social_layout">
              <a href="https://www.facebook.com/GOTitApplication/"><img v-lazy-load data-src="/icons/facebook.png" class="social" alt="gotit on facbook"></a>
              <div class="social_space" />
              <a href="https://www.instagram.com/gotit_app"><img v-lazy-load data-src="/icons/instagram.png" class="social" alt="gotit on instagram"></a>
            </div>
            <div class="sub_menu">
              <md-list :md-expand-single="expandSingle">
                <md-list-item md-expand :md-expanded.sync="expandNews">
                  <span class="md-list-item-text">Get Help</span>
                  <md-list slot="md-expand">
                    <md-list-item class="md-inset" @click.stop="goTrackOrder()">
                      Track Your Order
                    </md-list-item>
                    <md-list-item class="md-inset" @click.stop="goPaymentMethods()">
                      Accepted payment methods
                    </md-list-item>
                    <md-list-item class="md-inset" @click.stop="goShippingInfo()">
                      Shipping info
                    </md-list-item>
                    <md-list-item class="md-inset" @click.stop="goReturnsPolicy()">
                      Returns Policy
                    </md-list-item>
                    <md-list-item class="md-inset" @click.stop="goAboutUs()">
                      About us
                    </md-list-item>
                    <md-list-item class="md-inset" @click.stop="goContactUs()">
                      Contact Us
                    </md-list-item>
                  </md-list>
                </md-list-item>
              </md-list>
            </div>
            <md-list-item class="md-inset pp" @click.stop="goPrivacyPolicy()">
              Privacy Policy
            </md-list-item>
            <div v-if="Intl.DateTimeFormat().resolvedOptions().timeZone === 'Asia/Jerusalem'" class="version">
              v{{ version }}
              <div v-if="user">
                id: {{ user.id }}
              </div>
            </div>
          </v-list-item>
        </client-only>
      </v-list>
    </v-navigation-drawer>

    <ItemDialogComponent />
    <LoginComponent />
    <CreditTimerDialog />
    <SelectGenderComponent />
    <SlimItemPropertiesDialogComponent />
    <DownloadAppComponent
      v-if="$device.isMobileOrTablet"
    />
    <PopupMenuComponent />
    <ChatComponent />
    <ReturnRequestDialog />
    <LeaveFeedbackDialog />
    <ContextMenuDialogComponent />
    <ItemCommentsDialogComponent />
    <nuxt data-app />
    <client-only>
      <div v-if="$device.isMobileOrTablet && !hideTabs" class="tabs_layout">
        <div
          v-for="(tab, i) in tabs"
          :key="i"
          :class="{'selected' :isSelected(tab)}"
          class="ripple"
          @click.stop="onTabClick(tab)"
        >
          <span
            v-if="tab.name == 'cart' && cartCount"
            class="cart_badge"
            :class="{'anonymouse': !user || user.id < 0, 'cart_badge_long': cartCount > 9}"
          >{{ cartCount }}</span>
          <img class="navigation-tab" v-lazy-load :data-src="tab.icon" alt="cart tab">
        </div>
      </div>
      <md-snackbar :md-position="'center'" :md-duration="Infinity" :md-active.sync="showSnackbar" md-persistent>
        <span>Connection Lost</span>
        <button class="md-primary" @click="checkInternetState()">
          Try again
        </button>
      </md-snackbar>
    </client-only>
    <v-btn v-if="canShowChat" fab class="fab-chat" @click.stop="showChat()">
      <img class="lock" v-lazy-load data-src="/icons/chat.svg" alt="chat">
    </v-btn>
  </div>
</template>
<style>
html {
  font-family: -apple-system, BlinkMacSystemFont,
  Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

.button--green {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #3b8070;
  color: #3b8070;
  text-decoration: none;
  padding: 10px 30px;
}

.button--green:hover {
  color: #fff;
  background-color: #3b8070;
}

.button--grey {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #35495e;
  color: #35495e;
  text-decoration: none;
  padding: 10px 30px;
  margin-left: 15px;
}

.button--grey:hover {
  color: #fff;
  background-color: #35495e;
}

.login_section {
  margin-bottom: 0.7em;
  /*border-bottom: 1px solid #454545;*/
  border-bottom: 1px solid rgb(215, 215, 215);
}

.loginnn_button {
  background-color: white;
  color: #454545;
}

.profile_details {
  margin-left: 0.5em;
}

.NavigationItem a {
  color: white;
  /*font-family: 'HKGroteskBold', serif;*/
  font-weight: bold;
}

.v-list-item__content {
  margin-left: 0.5em;
}

.cart_badge {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  right: calc(50% - 1.5em);
  bottom: calc(2em);
  text-align: center;
  background-color: #ed4956;
  color: #fefefe;
  line-height: 100%;
  padding-top: 1.7px;
  padding-left: 0.5px;
  top: 0 !important;
  font-size: 11px;
}

.mobile_design .cart_badge {
  top: 0.6em !important;
}

.cart_badge_long {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  right: calc(50% - 1.5em);
  bottom: calc(2em);
  text-align: center;
  background-color: #ed4956;
  color: #fefefe;
  line-height: 100%;
  padding-top: 2.5px;
  padding-left: 0.3px;
  top: 0.6em !important;
  font-size: 10px;
}

.cart_badge.anonymouse {
  right: calc(37% - 1.5em);
}

.tabs_layout {
  position: fixed;
  bottom: 0;
  display: flex;
  background-color: #ededed;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 12;
}

.tabs_layout > div {
  flex-grow: 1;
  text-align: center;
  height: 100%;
}

.tabs_layout .selected .v-icon {
  color: #ff5b5f;
}

.tabs_layout > div:hover {
  background-color: rgba(238, 237, 237);
}

.v-tabs-bar {
  background-color: white !important;
}

.ripple {
  background-position: center;
  transition: background 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ripple:hover {
  background: #cecece radial-gradient(circle, transparent 1%, #cecece 1%) center/15000%;
}

.ripple:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}

.black_text {
  color: black !important;
}

.navigation-tab {
  width: 24px;
  height: 24px;
  filter: invert(50%) sepia(0%) saturate(0%) hue-rotate(122deg) brightness(90%) contrast(86%);
}

.selected .navigation-tab {
  filter: invert(35%) sepia(66%) saturate(1925%) hue-rotate(330deg) brightness(98%) contrast(89%);
}

.version {
  color: black;
}

.browse_gotit {
  color: black;
  font-weight: bold;
  font-size: 1.2em;
  margin-left: 1.4em;
  margin-bottom: 0.2em;
}

.mobile_menu {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.get_the_app {
  margin-top: 1em;
  color: black;
  font-size: 1.2em;
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 1px solid rgb(215, 215, 215);
  padding-top: 0.8em;
}

.get_the_app_icon {
  width: 50px;
  height: 50px !important;
  margin-right: 0.5em;
  border-radius: 10px;
}

.get_the_app_name {
  font-size: 15px;
  color: #4c505a;
}

.get_the_app_description {
  font-size: 11px !important;
  line-height: 11px;
  color: #5a5d63;
}

.get_the_app_button {
  background-color: #f61948;
  color: white;
  height: 2em;
  border-radius: 20px;
  font-weight: bold;
  padding-left: 0.8em;
  padding-right: 0.8em;
  padding-top: 0.2em;
  font-size: 13px;
}

.social_layout {
  display: flex;
  justify-content: center;
  /*border-top: 1px solid #454545;*/
  margin-top: 1em;
  width: 100%;
}

.sub_menu {
  width: 100%;
}

.social_space {
  width: 20px;
}

.social {
  width: 24px;
}

.md-list {
  color: black;
}

.md-list-item.md-inset .md-list-item-content {
  padding-left: 2em !important;
}

.md-list-item.md-inset.pp .md-list-item-content {
  padding-left: 1em !important;
  color: black;
}

.md-list-item-text {
  font-weight: bold;
}

.log_in_to_track {
  text-decoration: underline;
}

.fab-chat {
  position: fixed;
  bottom: 4em;
  width: 48px !important;
  height: 48px !important;
  right: 1em;
  z-index: 1;
}
.v-navigation-drawer {
  width: unset !important;
}
</style>
<script>
// import * as delay from 'delay'
import PopupMenuComponent from '../components/PopupMenuComponent'
import LoginComponent from '../components/LoginComponent'
import ItemDialogComponent from '../components/ItemDialogComponent'
import ChatComponent from '../components/ChatComponent'
import ContextMenuDialogComponent from '../components/ContextMenuDialogComponent'
import ReturnRequestDialog from '../components/ReturnRequestDialog'
import ItemCommentsDialogComponent from '~/components/ItemCommentsDialogComponent'
import LeaveFeedbackDialog from '~/components/LeaveFeedbackDialog'
import DownloadAppComponent from '~/components/DownloadAppComponent'
import SelectGenderComponent from '~/components/SelectGenderComponent'
import CreditTimerDialog from '~/components/CreditTimerDialogComponent'
import SlimItemPropertiesDialogComponent from '~/components/SlimItemPropertiesDialogComponent'

export default {
  components: {
    CreditTimerDialog,
    SelectGenderComponent,
    SlimItemPropertiesDialogComponent,
    DownloadAppComponent,
    LeaveFeedbackDialog,
    ItemCommentsDialogComponent,
    ReturnRequestDialog,
    ContextMenuDialogComponent,
    ChatComponent,
    ItemDialogComponent,
    LoginComponent,
    PopupMenuComponent
  },
  data () {
    return {
      expandNews: false,
      expandSingle: false,
      user: null,
      canShowChat: false,
      socket: null,
      drawer: false,
      color: '#454545',
      showSnackbar: false,
      appOnline: true,
      version: '2.0.5',
      lastTabName: '',
      navigationItems: [
        { title: 'Home', icon: 'mdi-home', path: '/' },
        { title: 'Cart', icon: 'mdi-cart', path: '/cart' },
        { title: 'Explore', icon: 'mdi-view-dashboard', path: '/explore' }
      ],
      categories: [
        {
          name: 'Recommended',
          id: '25',
          children: [
            { name: "Men's", id: '21', path: '/search?category=25,21', breadcrumb: [] }, {
              name: "Women's",
              id: '68',
              path: '/search?category=25,68',
              breadcrumb: []
            }],
          path: '/search?category=25',
          breadcrumb: ['Recommended', "Men's", "Women's"]
        }, {
          name: 'Matching Sets',
          id: '102',
          children: [
            {
              name: 'Create your custom set',
              id: '-1',
              path: '/create-set',
              // external: 'https://gotitstores.com/create-set',
              breadcrumb: []
            },
            {
              name: 'Shirt & Pants Sets',
              id: '103',
              path: '/search?category=102,103',
              breadcrumb: []
            },
            { name: 'Tracksuits', id: '104', path: '/search?category=102,104', breadcrumb: [] },
            {
              name: 'Shorts Sets',
              id: '105',
              path: '/search?category=102,105',
              breadcrumb: []
            }],
          path: '/search?category=102',
          breadcrumb: ['Matching Sets', 'Shirt & Pants Sets', 'Tracksuits', 'Shorts Sets']
        }, {
          name: "Men's",
          id: '21',
          children: [
            {
              name: 'Clothing',
              id: '53',
              children: [{
                name: 'Shirts',
                id: '95',
                path: '/search?category=21,95',
                breadcrumb: []
              },
              { name: 'Jackets & Coats', id: '13', path: '/search?category=21,13', breadcrumb: [] },
              {
                name: 'Sweaters',
                id: '45',
                path: '/search?category=21,45',
                breadcrumb: []
              }, {
                name: 'T-Shirt',
                id: '40',
                children: [{
                  name: 'Polo shirts',
                  id: '15',
                  path: '/search?category=40,21,15',
                  breadcrumb: []
                }, { name: 'Long Sleeve', id: '91', path: '/search?category=40,21,91', breadcrumb: [] }, {
                  name: 'Tanks',
                  id: '67',
                  path: '/search?category=40,21,67',
                  breadcrumb: []
                }, { name: 'Short Sleeve', id: '24', path: '/search?category=40,21,24', breadcrumb: [] }, {
                  name: 'Polo shirts',
                  id: '15',
                  path: '/search?category=40,21,15',
                  breadcrumb: []
                }],
                path: '/search?category=21,40',
                breadcrumb: ['T-Shirt', 'Polo shirts', 'Long Sleeve', 'Tanks', 'Short Sleeve', 'Polo shirts']
              }, { name: 'Sweatshirts', id: '69', path: '/search?category=21,69', breadcrumb: [] }, {
                name: 'Underwear',
                id: '5',
                path: '/search?category=21,5',
                breadcrumb: []
              }, { name: 'Shorts', id: '89', path: '/search?category=21,89', breadcrumb: [] }, {
                name: 'Pants',
                id: '43',
                children: [{ name: 'Joggers', id: '7', path: '/search?category=7', breadcrumb: [] }, {
                  name: 'Chinos',
                  id: '34',
                  path: '/search?category=21,43,34',
                  breadcrumb: []
                }, { name: 'Jeans', id: '29', path: '/search?category=21,43,29', breadcrumb: [] }],
                path: '/search?category=21,43',
                breadcrumb: ['Pants', 'Joggers', 'Chinos', 'Jeans']
              }],
              path: '/search?category=21,53',
              breadcrumb: ['Clothing', 'Shirts', 'Jackets & Coats', 'Sweaters', 'Sweatshirts', 'Underwear', 'Shorts']
            }, {
              name: 'Accessories',
              id: '39',
              children: [{ name: 'Wallets', id: '96', path: '/search?category=39,21,96', breadcrumb: [] }, {
                name: 'Watches',
                id: '64',
                path: '/search?category=39,21,64',
                breadcrumb: []
              }, { name: 'Glasses', id: '26', path: '/search?category=39,21,26', breadcrumb: [] }, {
                name: 'Beauty & Perfumes',
                id: '48',
                path: '/search?category=39,21,48',
                breadcrumb: []
              }, { name: 'Bags', id: '44', path: '/search?category=39,21,44', breadcrumb: [] }, {
                name: 'Jewlery',
                id: '85',
                path: '/search?category=39,21,85',
                breadcrumb: []
              }, { name: 'Caps', id: '80', path: '/search?category=39,21,80', breadcrumb: [] }, {
                name: 'Others',
                id: '42',
                path: '/search?category=31,21,42',
                breadcrumb: []
              }],
              path: '/search?category=21,39',
              breadcrumb: ['Accessories', 'Wallets', 'Watches', 'Glasses', 'Beauty & Perfumes', 'Bags', 'Jewlery', 'Caps', 'Others']
            }, {
              name: 'Shoes',
              id: '38',
              children: [
                { name: 'Sports', id: '90', path: '/search?category=38,21,90', breadcrumb: [] }, {
                  name: 'Sandals',
                  id: '37',
                  path: '/search?category=38,21,37',
                  breadcrumb: []
                }, { name: 'Boots', id: '70', path: '/search?category=38,21,70', breadcrumb: [] }, {
                  name: 'Sneakers',
                  id: '93',
                  path: '/search?category=38,21,93',
                  breadcrumb: []
                }, { name: 'Dress', id: '58', path: '/search?category=38,21,58', breadcrumb: [] }, {
                  name: 'Sports',
                  id: '90',
                  path: '/search?category=38,21,90',
                  breadcrumb: []
                }],
              path: '/search?category=21,38',
              breadcrumb: ['Shoes', 'Sports', 'Sandals', 'Boots', 'Sneakers', 'Dress', 'Sports']
            }],
          path: '/search?category=21',
          breadcrumb: ["Men's"]
        }, {
          name: "Women's",
          id: '68',
          children: [
            { name: 'Beauty', id: '49', path: '/search?category=49', breadcrumb: [] }, {
              name: 'Accessories',
              id: '39',
              children: [{ name: 'Others', id: '42', path: '/search?category=39,68,53,42', breadcrumb: [] }, {
                name: 'Bags',
                id: '44',
                path: '/search?category=44',
                breadcrumb: []
              }, { name: 'Hats', id: '65', path: '/search?category=39,68,53,65', breadcrumb: [] }, {
                name: 'Watches',
                id: '64',
                path: '/search?category=64',
                breadcrumb: []
              }, { name: 'Belts', id: '73', path: '/search?category=39,68,53,73', breadcrumb: [] }, {
                name: 'Jewlery',
                id: '85',
                children: [{ name: 'Earrings', id: '88', path: '/search?category=39,68,53,88', breadcrumb: [] }, {
                  name: 'Rings',
                  id: '60',
                  path: '/search?category=60',
                  breadcrumb: []
                }, { name: 'Necklace', id: '36', path: '/search?category=39,68,53,36', breadcrumb: [] }, {
                  name: 'Bracelet',
                  id: '86',
                  path: '/search?category=39,68,53,86',
                  breadcrumb: []
                }],
                path: '/search?category=85',
                breadcrumb: ['Jewlery', 'Earrings', 'Rings', 'Necklace', 'Bracelet']
              }, { name: 'Sunglasses', id: '16', path: '/search?category=39,68,53,16', breadcrumb: [] }, {
                name: 'Hair',
                id: '66',
                path: '/search?category=39,68,53,66',
                breadcrumb: []
              }],
              path: '/search?category=68,53,39',
              breadcrumb: ['Accessories', 'Others', 'Bags', 'Hats', 'Watches', 'Belts', 'Sunglasses', 'Hair']
            }, {
              name: 'Clothing',
              id: '53',
              children: [{
                name: 'Bras, Panties & Shapewear',
                id: '32',
                path: '/search?category=68,53,32',
                breadcrumb: []
              }, {
                name: 'Tops',
                id: '31',
                children: [{ name: 'Long Sleeve', id: '91', path: '/search?category=31,68,53,91', breadcrumb: [] }, {
                  name: 'Short',
                  id: '2',
                  path: '/search?category=2',
                  breadcrumb: []
                }, { name: 'Dress Shirts', id: '35', path: '/search?category=31,68,53,35', breadcrumb: [] }, {
                  name: 'Tanks',
                  id: '67',
                  path: '/search?category=31,68,53,67',
                  breadcrumb: []
                }],
                path: '/search?category=68,53,31',
                breadcrumb: ['Tops', 'Long Sleeve', 'Short', 'Dress Shirts', 'Tanks']
              }, { name: 'Sweats and Hoodie', id: '76', path: '/search?category=68,53,76', breadcrumb: [] }, {
                name: 'Skirts',
                id: '8',
                path: '/search?category=68,53,8',
                breadcrumb: []
              }, { name: 'Swimwear', id: '6', path: '/search?category=68,53,6', breadcrumb: [] }, {
                name: 'Jumpsuits & Rompers',
                id: '72',
                path: '/search?category=68,53,72',
                breadcrumb: []
              }, { name: 'Jackets & Coats', id: '18', path: '/search?category=68,53,18', breadcrumb: [] }, {
                name: 'Dresses',
                id: '84',
                path: '/search?category=68,53,84',
                breadcrumb: []
              }, {
                name: 'Bottoms',
                id: '79',
                children: [{ name: 'Jeans', id: '29', path: '/search?category=79,68,53,29', breadcrumb: [] }, {
                  name: 'Shorts',
                  id: '89',
                  path: '/search?category=79,68,53,89',
                  breadcrumb: []
                }, { name: 'Joggers', id: '7', path: '/search?category=79,68,53,7', breadcrumb: [] }, {
                  name: 'Pants',
                  id: '43',
                  path: '/search?category=79,68,53,43',
                  breadcrumb: []
                }],
                path: '/search?category=79,68,53,',
                breadcrumb: ['Bottoms', 'Jeans', 'Shorts', 'Joggers', 'Pants']
              }],
              path: '/search?category=68,53',
              breadcrumb: ['Clothing', 'Bras, Panties & Shapewear', 'Sweats and Hoodie', 'Skirts', 'Swimwear', 'Jumpsuits & Rompers', 'Jackets & Coats', 'Dresses']
            }, {
              name: 'Shoes',
              id: '38',
              children: [{ name: 'Sneakers', id: '93', path: '/search?category=38,68,93', breadcrumb: [] }, {
                name: 'High Heels',
                id: '83',
                path: '/search?category=68,38,83',
                breadcrumb: []
              }, { name: 'Boots', id: '70', path: '/search?category=38,68,70', breadcrumb: [] }, {
                name: 'Sports',
                id: '90',
                path: '/search?category=38,68,90',
                breadcrumb: []
              }, { name: 'Flats', id: '97', path: '/search?category=38,68,97', breadcrumb: [] }],
              path: '/search?category=68,38',
              breadcrumb: ['Shoes', 'Sneakers', 'High Heels', 'Boots', 'Sports', 'Flats']
            }, {
              name: 'Beauty',
              id: '49',
              children: [
                { name: 'Make Up', id: '57', path: '/search?category=49,68,57', breadcrumb: [] },
                { name: 'Fragrance', id: '23', path: '/search?category=49,68,23', breadcrumb: [] }, {
                  name: 'Others',
                  id: '42',
                  path: '/search?category=49,68,42',
                  breadcrumb: []
                }, { name: 'Skin Care', id: '30', path: '/search?category=49,68,30', breadcrumb: [] }],
              path: '/search?category=68,49',
              breadcrumb: ['Beauty', 'Make Up', 'Make Up', 'Fragrance', 'Others', 'Skin Care']
            }],
          path: '/search?category=68',
          breadcrumb: ["Women's", 'Beauty']
        }, {
          name: 'Gadgets',
          id: '19',
          children: [{
            name: 'Laptop Accessories',
            id: '50',
            path: '/search?category=19,50',
            breadcrumb: []
          },
          { name: 'Laptop Accessories', id: '50', path: '/search?category=19,50', breadcrumb: [] }, {
            name: 'Other',
            id: '33',
            path: '/search?category=19,33',
            breadcrumb: []
          }, { name: 'Camera', id: '78', path: '/search?category=19,78', breadcrumb: [] }, {
            name: 'Phone Accessories',
            id: '17',
            path: '/search?category=19,17',
            breadcrumb: []
          }, { name: 'Kitchen ', id: '20', path: '/search?category=19,20', breadcrumb: [] }, {
            name: 'Audio',
            id: '63',
            path: '/search?category=19,63',
            breadcrumb: []
          }],
          path: '/search?category=19',
          breadcrumb: ['Gadgets', 'Laptop Accessories', 'Laptop Accessories', 'Other', 'Camera', 'Phone Accessories', 'Kitchen ', 'Audio']
        }, {
          name: 'Plus Size (New)',
          id: '100',
          children: [{
            name: "Men's",
            id: '21',
            children: [{
              name: 'Clothing',
              id: '53',
              children: [{
                name: 'T-Shirt',
                id: '40',
                children: [{
                  name: 'Short Sleeve',
                  id: '24',
                  path: '/search?category=100,53,40,24',
                  breadcrumb: []
                }, { name: 'Long Sleeve', id: '91', path: '/search?category=100,53,40,91', breadcrumb: [] }, {
                  name: 'Tanks',
                  id: '67',
                  path: '/search?category=100,53,40,67',
                  breadcrumb: []
                }, { name: 'Polo shirts', id: '15', path: '/search?category=100,53,40,15', breadcrumb: [] }],
                path: '/search?category=100,53,40',
                breadcrumb: ['T-Shirt', 'Short Sleeve', 'Long Sleeve', 'Tanks', 'Polo shirts']
              }, {
                name: 'Pants',
                id: '43',
                children: [{ name: 'Jeans', id: '29', path: '/search?category=29', breadcrumb: [] }, {
                  name: 'Chinos',
                  id: '34',
                  path: '/search?category=34',
                  breadcrumb: []
                }, { name: 'Jeans', id: '29', path: '/search?category=100,53,43,29', breadcrumb: [] }, {
                  name: 'Joggers',
                  id: '7',
                  path: '/search?category=100,53,43,7',
                  breadcrumb: []
                }],
                path: '/search?category=100,53,43',
                breadcrumb: ['Pants', 'Jeans', 'Chinos', 'Jeans', 'Joggers']
              }, { name: 'Shirts', id: '95', path: '/search?category=100,53,95', breadcrumb: [] }, {
                name: 'Shorts',
                id: '89',
                path: '/search?category=100,53,89',
                breadcrumb: []
              }, { name: 'Sweatshirts', id: '69', path: '/search?category=100,53,69', breadcrumb: [] }, {
                name: 'Sweaters',
                id: '45',
                path: '/search?category=100,53,45',
                breadcrumb: []
              }, { name: 'Jackets & Coats', id: '13', path: '/search?category=100,53,13', breadcrumb: [] }, {
                name: 'Underwear',
                id: '5',
                path: '/search?category=100,53,5',
                breadcrumb: []
              }],
              path: '/search?category=100,53',
              breadcrumb: ['Clothing', 'Shirts', 'Shorts', 'Sweatshirts', 'Sweaters', 'Jackets & Coats', 'Underwear']
            }, {
              name: 'Shoes',
              id: '38',
              children: [{ name: 'Sneakers', id: '93', path: '/search?category=38,93', breadcrumb: [] }, {
                name: 'Dress',
                id: '58',
                path: '/search?category=38,58',
                breadcrumb: []
              }, { name: 'Boots', id: '70', path: '/search?category=38,70', breadcrumb: [] }, {
                name: 'Sports',
                id: '90',
                path: '/search?category=38,90',
                breadcrumb: []
              }, { name: 'Sandals', id: '37', path: '/search?category=38,37', breadcrumb: [] }],
              path: '/search?category=38',
              breadcrumb: ['Shoes', 'Sneakers', 'Dress', 'Boots', 'Sports', 'Sandals']
            }, {
              name: 'Accessories',
              id: '39',
              children: [{
                name: 'Glasses',
                id: '26',
                path: '/search?category=26',
                breadcrumb: []
              }, { name: 'Beauty & Perfumes', id: '48', path: '/search?category=48', breadcrumb: [] }, {
                name: 'Jewlery',
                id: '85',
                path: '/search?category=85',
                breadcrumb: []
              }, { name: 'Bags', id: '44', path: '/search?category=44', breadcrumb: [] }, {
                name: 'Watches',
                id: '64',
                path: '/search?category=64',
                breadcrumb: []
              }, { name: 'Wallets', id: '96', path: '/search?category=96', breadcrumb: [] }, {
                name: 'Caps',
                id: '80',
                path: '/search?category=80',
                breadcrumb: []
              }, { name: 'Others', id: '42', path: '/search?category=42', breadcrumb: [] }],
              path: '/search?category=39',
              breadcrumb: ['Accessories', 'Glasses', 'Beauty & Perfumes', 'Jewlery', 'Bags', 'Watches', 'Wallets', 'Caps', 'Others']
            }],
            path: '/search?category=21',
            breadcrumb: ["Men's"]
          }, {
            name: "Women's",
            id: '74',
            children: [{
              name: 'Clothing',
              id: '53',
              children: [{
                name: 'T-Shirt',
                id: '40',
                children: [{
                  name: 'Short Sleeve',
                  id: '24',
                  path: '/search?category=100,53,74,40,24',
                  breadcrumb: []
                }, { name: 'Long Sleeve', id: '91', path: '/search?category=40,100,74,91', breadcrumb: [] }, {
                  name: 'Tanks',
                  id: '67',
                  path: '/search?category=40,100,74,67',
                  breadcrumb: []
                }, { name: 'Polo shirts', id: '15', path: '/search?category=40,100,74,15', breadcrumb: [] }],
                path: '/search?category=100,74,40',
                breadcrumb: ['T-Shirt', 'Short Sleeve', 'Long Sleeve', 'Tanks', 'Polo shirts']
              }, {
                name: 'Pants',
                id: '43',
                children: [{ name: 'Chinos', id: '34', path: '/search?category=43,100,68,34', breadcrumb: [] }, {
                  name: 'Jeans',
                  id: '29',
                  path: '/search?category=43,100,68,29',
                  breadcrumb: []
                }, { name: 'Joggers', id: '7', path: '/search?category=43,100,68,7', breadcrumb: [] }],
                path: '/search?category=43',
                breadcrumb: ['Pants', 'Chinos', 'Jeans', 'Joggers']
              }, { name: 'Shirts', id: '95', path: '/search?category=100,68,95', breadcrumb: [] }, {
                name: 'Shorts',
                id: '89',
                path: '/search?category=100,68,89',
                breadcrumb: []
              }, { name: 'Sweatshirts', id: '69', path: '/search?category=100,68,69', breadcrumb: [] }, {
                name: 'Jackets & Coats',
                id: '18',
                path: '/search?category=100,68,18',
                breadcrumb: []
              }, { name: 'Underwear', id: '5', path: '/search?category=5', breadcrumb: [] }],
              path: '/search?category=100,68,53',
              breadcrumb: ['Clothing', 'Shirts', 'Shorts', 'Sweatshirts', 'Jackets & Coats', 'Underwear']
            }],
            path: '/search?category=100,68',
            breadcrumb: ["Women's"]
          }],
          path: '/search?category=100',
          breadcrumb: ['Plus Size (New)']
        }, {
          name: 'Kids',
          id: '59',
          children: [{
            name: 'Boys',
            id: '28',
            children: [{ name: 'Tops', id: '31', path: '/search?category=59,28,31', breadcrumb: [] }],
            path: '/search?category=59,28',
            breadcrumb: ['Boys', 'Tops']
          }],
          path: '/search?category=59',
          breadcrumb: ['Kids']
        }, { name: 'Subscriptions', id: '101', path: '/search?category=101', breadcrumb: [] }],
      cartCount: 0,
      hideTabs: false,
      tab: null,
      tabs: [
        {
          name: 'home',
          icon: '/icons/home.svg',
          path: '/'
        },
        {
          name: 'explore',
          icon: '/icons/view-dashboard.svg',
          path: '/explore'
        },
        {
          name: 'cart',
          icon: '/icons/cart.svg',
          path: '/cart'
        },
        {
          name: 'news',
          icon: '/icons/bell.svg',
          path: '/news'
        },
        {
          name: 'profile',
          icon: '/icons/account.svg',
          path: '/profile'
        }
      ]
    }
  },
  watch: {
    $route (to, from) {
      if (from.name === 'cart') {
        if (this.$store.state.localStorage.activationToPresent) {
          this.$root.$emit('show_credit_timer_dialog', {
            activation: this.$store.state.localStorage.activationToPresent,
            delay: 2000
          })
          this.$store.commit('localStorage/setActivationToPresent', null)
        }
        // user leave cart page
        // check if activation popup should presented
      }
    }
  },
  mounted () {
    this.$store.commit('localStorage/setActivationPresented', false)
    this.user = this.$store.state.localStorage.currentUser
    this.cartCount = this.$store.state.api.globals.cartCount
    if (this.user && this.user.id < 0 && this.tabs.length === 5) {
      this.tabs.splice(3, 1)
    }
    if (this.$device.isMobileOrTablet) {
      this.$root.$on('show_nivigation_drower', () => {
        this.drawer = true
      })
      this.$root.$on('hide_tabs', (hide) => {
        this.hideTabs = hide
      })
    }
    if (this.user) {
      this.$root.$on('on_globals_ready', () => {
        this.initSocket()
        this.canShowChat = this.$store.state.api.globals && this.$store.state.api.globals.customerServiceOn === 'true'
        this.cartCount = this.$store.state.api.globals.cartCount
        if (!this.$store.state.localStorage.activationPresented && this.$store.state.api.globals.activation && this.$device.isMobileOrTablet) {
          const activations = JSON.parse(this.$store.state.api.globals.activation)
          const sessionStartActivation = activations.filter(actvtn => actvtn.type === 'session_start')[0]
          if (sessionStartActivation) {
            this.$root.$emit('show_credit_timer_dialog', {
              activation: sessionStartActivation
            })
          }
        }
        this.startSpacialTimer()
      })
      this.$store.dispatch('api/getGlobals')
        .then((globals) => {
          this.$root.$emit('on_globals_ready')
          this.cartCount = this.$store.state.api.globals.cartCount
          if (!this.$store.state.localStorage.activationPresented && this.$store.state.api.globals.activation && this.$device.isMobileOrTablet) {
            const activations = JSON.parse(this.$store.state.api.globals.activation)
            const sessionStartActivation = activations.filter(actvtn => actvtn.type === 'session_start')[0]
            if (sessionStartActivation) {
              this.$root.$emit('show_credit_timer_dialog', {
                activation: sessionStartActivation
              })
            }
          }
        })
      this.sendAdClick()
      this.startCreditTimer()
      if (this.$store.state.localStorage.activationTimerTimestamp) {
        this.startActivationTimer()
      }
      setTimeout(() => {
        this.$store.dispatch('api/sendLogMessage', 'chat_auto_opened').then().catch()
        const data = {
          type: 'CART_CHAT',
          user: {
            id: 344535,
            profilePhotoThumbnail: 'https://s3.amazonaws.com/app.gotit.parse/tfss-72809fa4-553f-4473-91f5-af9b31802200-thumbnail.png',
            name: 'GOTit Customer service'
          },
          description: 'Hey 👋🏽 Customer care here and we will be happy to answer any question you may have today. We are real people and not a bot 😀 If you need anything please write us back!',
          autoOpen: true
        }
        this.$root.$emit('show_chat', data)
      }, 1000 * 60 * 3)
    }
    this.$root.$on('on_user_ready', () => {
      this.$store.dispatch('api/getGlobals')
        .then((globals) => {
          this.canShowChat = this.$store.state.api.globals && this.$store.state.api.globals.customerServiceOn === 'true'
          this.initSocket()
          this.$root.$emit('on_globals_ready')
          if (!this.$store.state.localStorage.activationPresented && this.$store.state.api.globals.activation && this.$device.isMobileOrTablet) {
            const activations = JSON.parse(this.$store.state.api.globals.activation)
            const sessionStartActivation = activations.filter(actvtn => actvtn.type === 'session_start')[0]
            if (sessionStartActivation) {
              this.$root.$emit('show_credit_timer_dialog', {
                activation: sessionStartActivation
              })
            }
          }
          this.startSpacialTimer()
        })
      this.user = this.$store.state.localStorage.currentUser
      this.sendAdClick()
      this.startCreditTimer()
      this.startSpacialTimer()
      if (this.$store.state.localStorage.activationTimerTimestamp) {
        this.startActivationTimer()
      }
    })
    if (this.$store.state.localStorage.currentUser != null && this.$store.state.localStorage.currentUser.id === 550296) {
      setTimeout(() => {
        if (location.pathname !== '/cart') {
          this.$root.$emit('show_download_app_popup', {})
        }
      }, 10 * 1000)
    } else {
      setTimeout(() => {
        if (location.pathname !== '/cart' && this.$store.state.localStorage.currentUser != null && this.$store.state.localStorage.currentUser.id > 0) {
          this.$root.$emit('show_download_app_popup', {})
          this.$store.dispatch('api/sendLogMessage', 'short_link_presented').then().catch()
          this.$store.dispatch('api/trackEvent', {
            eventName: 'short_link_presented'
          }).then().catch()
        }
      }, 5 * 60 * 1000)
    }
    this.$root.$on('open_navigation_menu', () => {
      this.drawer = true
    })
    this.$store.dispatch('api/handleIdentify').then().catch()
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (location.hostname !== 'localhost' && location.hostname !== '127.0.0.1' && process.client) {
      this.$fb.enable()
    }
    document.addEventListener('click', (e) => {
      if (e.target && e.target.className === 'v-overlay__scrim') {
        this.$root.$emit('hide_item_bottom_sheet')
      }
      this.$root.$emit('background_clicked')
    })
    this.$store.dispatch('api/loadCacheBuckets').then().catch()
    window.addEventListener('offline', (event) => {
      // Sentry.default.captureEvent({
      //   message: 'user lost connection'
      // })
      this.$store.dispatch('api/trackEvent', {
        eventName: 'network_state_lost'
      }).then().catch()
      this.showSnackbar = true
      this.appOnline = false
    })
    window.addEventListener('online', (event) => {
      this.$store.dispatch('api/trackEvent', {
        eventName: 'network_state_available'
      }).then().catch()
      this.showSnackbar = false
      this.appOnline = true
    })
    if (this.$route.query.redirect && this.$device.isIos) {
      // window.location.href = 'http://l.gotitapp.net/?' + this.generateParams(this.$route.query)
      window.location.href = 'https://itunes.apple.com/us/app/gotit-social-shopping/id1003017825?ls=1&mt=8'
      return
    }
    if (this.$route.query.openApp) {
      setTimeout(() => {
        this.openApp()
      }, 500)
    } else if (this.$route.query.rank) {
      if (this.$device.isIos) {
        window.location = 'https://itunes.apple.com/us/app/gotit-social-shopping/id1003017825'
      } else if (this.$device.isAndroid) {
        window.location = 'https://play.google.com/store/apps/details?id=gotit.com.gotit'
      }
    }
    this.$store.commit('localStorage/setFirstVisitDate', { firstVisit: new Date().getTime() })

    if (this.$store.state.localStorage.currentUser != null && this.$store.state.localStorage.currentUser.id > 0 && (this.$device.isIos || this.$device.isAndroid)) {
      if (!this.$store.state.localStorage.shortLink) {
        try {
          this.$store.dispatch('api/generatePin')
            .then((pinRes) => {
              this.$store.dispatch('api/generateShortLink', { pin: pinRes.objects.pin })
                .then((res) => {
                  // this.$store.commit('localStorage/setShortLink', '')
                  this.$store.dispatch('api/sendLogMessage', 'short_link_created').then().catch()
                  this.$store.dispatch('api/trackEvent', {
                    eventName: 'short_link_created'
                  }).then().catch()
                  this.$store.commit('localStorage/setShortLink', res.shortLink)
                })
            })
        } catch (e) {
        }
      }
    }
  },
  methods: {
    initSocket () {
      this.socket = this.$nuxtSocket({
        name: 'main',
        reconnection: true,
        query: { sessionToken: this.$store.state.localStorage.sessionToken }
      })
      this.socket.on(this.$store.state.localStorage.currentUser.id.toString(), (data) => {
        this.showChat()
      })
      this.socket.on(this.$store.state.localStorage.sessionToken, (data) => {
        this.showChat()
      })
    },
    goPrivacyPolicy () {
      this.$router.push({
        path: '/privacy-policy'
      })
    },
    goContactUs () {
      this.$router.push({
        path: '/contact-us'
      })
    },
    goAboutUs () {
      this.drawer = false
      this.$root.$emit('show_dialog', {
        title: 'About Us',
        showCloseButton: true,
        showMenuOnClose: true,
        icon: '/gotit-logo.jpeg',
        message: 'At GOTit Social Shopping, we believe that shopping is more than just buying things. It\'s about discovering new products, exploring different styles, and connecting with like-minded individuals who share your passion for fashion and lifestyle.</br></br>' +
          'Our platform is designed to make shopping a social and engaging experience. With GOTit, you can browse and shop from a wide range of products, curated by our team of experts, and discover new brands and trends. You can also connect with friends, influencers, and fashion enthusiasts from around the world, share your own style inspiration, and get feedback and recommendations from our vibrant community.</br></br>' +
          'Since we started in 2015, we are committed to providing our customers with the best possible shopping experience, with features like seamless checkout, fast and reliable shipping, and world-class customer support. With millions of users in the US we are the fastest growing street fashion site in the US.</br></br>' +
          'GOTit Social  Shopping has everything you need to elevate your style game and connect with the world of fashion and lifestyle. Join us today and let\'s shop together!'
      })
      // this.$router.push({
      //   path: '/about-us'
      // })
    },
    goHelpCenter () {
      this.$router.push({
        path: '/help-center'
      })
    },
    goTrackOrder () {
      // if logged in show orders
      // if u place
      // 1. purchase + logged in - you can click here to track your orders (show my orders)
      // 2. not logged in  - log in to track your orders
      // 3. not purchase + logged in - if you placed with us an order, you can click in here to track your order
      this.drawer = false
      const loggedIn = this.$store.state.localStorage.currentUser != null && this.$store.state.localStorage.currentUser.id > 0
      const bought = this.$store.state.localStorage.currentUser != null && this.$store.state.localStorage.currentUser.lastOrderAt !== null
      let trackOrderMessage = ''
      if (loggedIn) {
        if (bought) {
          trackOrderMessage = 'you can <a href="' + location.origin + '/orders">click here</a> to track your orders</br></br>'
        } else {
          trackOrderMessage = 'if you placed with us an order, you can <a href="' + location.origin + '/orders">click here</a> to track your orders</br></br>'
        }
      } else {
        trackOrderMessage = '<span onclick="window.$nuxt.$emit(\'toggle_login_menu\', {redirectUrl: \'/orders\'})" class="log_in_to_track">log in</span> to track your orders</br></br>'
      }
      this.$root.$emit('show_dialog', {
        title: 'Orders',
        showCloseButton: true,
        showMenuOnClose: true,
        message: 'We understand the importance of keeping track of your order and knowing its delivery status. That\'s why we offer a comprehensive order tracking system to help you stay informed about your purchase.</br></br>' +
          trackOrderMessage +
          '      <b>Tracking Your Order:</b> Once your order has been processed and shipped, you will receive an email confirmation with a tracking number and a link to our carrier\'s website. Simply click on the link or enter the tracking number into the carrier\'s website to view your order\'s current status.</br></br>' +
          '      <b>Order Status Updates:</b> Our carrier will provide you with real-time updates on your order\'s status, including when it is in transit, out for delivery, or has been delivered. You can also opt-in to receive email or text message notifications on your order\'s status updates.</br></br>' +
          '      <b>Delivery Confirmation:</b> Once your order has been delivered, our carrier will provide confirmation of delivery. If you have any concerns about your order\'s delivery status, please contact our customer service team immediately.</br></br>' +
          '      <b>Contact Us:</b> If you have any questions or concerns about your order\'s tracking information, please do not hesitate to contact us at through your order on GOTit or send us an email to <a href="mailto:info@gotitapp.net">info@gotitapp.net</a> and our customer service team is here to assist you and ensure you have a positive shopping experience with us.'
      })
      // this.$router.push({
      //   path: '/track-order'
      // })
    },
    goPaymentMethods () {
      this.drawer = false
      this.$root.$emit('show_dialog', {
        title: 'Accepted payment methods',
        showCloseButton: true,
        showMenuOnClose: true,
        message: 'We offer a range of payment options to make your online shopping experience convenient and secure. Please review our payment policy below for more information.</br></br>' +
          'We accept the following payment methods:\n' +
          '<br/>\n' +
          '<br/>\n' +
          '<ul style="margin-left: 1em">\n' +
          '  <li>Credit cards (Visa, Mastercard, American Express, Discover)</li>\n' +
          '  <li>Debit cards</li>\n' +
          '  <li>PayPal</li>\n' +
          '  <li>Apple Pay</li>\n' +
          '  <li>Google Pay</li>\n' +
          '  <li>Klarna</li>\n' +
          '</ul>\n' +
          '<br/>\n' +
          '<b>Payment Security:</b> We take payment security very seriously and use industry-standard encryption and fraud prevention technologies to ensure your payment information is secure. All transactions are processed through a secure payment gateway, and we do not store your payment information on our servers</br></br>' +
          'If your payment does not successfully process or if it is declined when trying to complete your purchase, you will have an opportunity to enter a new card number or alternative form of payment prior to completing your purchase.</br></br>' +
          'Regrettably, GOTit does not have details as to why this happened and suggest you reach out directly to your financial institution for any details regarding your declined payment.'
      })
      // this.$router.push({
      //   path: '/payment-methods'
      // })
    },
    goShippingInfo () {
      this.drawer = false
      this.$root.$emit('show_dialog', {
        title: 'Shipping Info',
        showCloseButton: true,
        showMenuOnClose: true,
        message: '<b>Processing Time:</b> once your payment Is cleared orders are processed within the same day. Once your order has been processed and shipped, you will receive a confirmation email with tracking information.</br></br><b>Shipping Options:</b> All orders are shipped with official carriers - we use USPS, UPS, Fedex for orders that are shipped in the US. Once you add the item to the cart, you will be able to see and select the shipping options for the item. Shipping costs and time vary based on different stores on GOTit. Most items are delivered within 3-7 business days.'
      })
      // this.$router.push({
      //   path: '/shipping-info'
      // })
    },
    goReturnsPolicy () {
      this.$root.$emit('show_dialog', {
        title: 'Return Policy',
        showCloseButton: true,
        showMenuOnClose: true,
        message: 'Thank you for considering shopping with us! We strive to provide you with the best online shopping experience possible. However, if you are not entirely satisfied with your purchase, we are here to help.</br></br>' +
          '      <b>Returns:</b> You have 30 calendar days to return an item from the date you received it. To be eligible for a return, your item must be unused, in the same condition that you received it, and in the original packaging.</br></br>' +
          '      <b>Refunds:</b> Once we receive your item, we will inspect it and notify you that we have received your returned item. We will immediately notify you on the status of your refund after inspecting the item. If your return is approved, we will initiate a refund to your original method of payment. You will receive the credit within a certain amount of days, depending on your card issuer\'s policies.</br></br>' +
          '      <b>Contact Us:</b> If you have any questions on how to return your item to us, please contact us through your order or press here to contact our customer care. You can also email us to <a href="mailto:info@gotitapp.net">info@gotitapp.net</a>  and our customer service team will be happy to assist you.'
      })
      // this.$router.push({
      //   path: '/returns-policy'
      // })
    },
    openApp () {
      setTimeout(function () {
        window.location = 'https://gotitstores.com'
      }, 100)
      if (this.$device.isIos) {
        window.location = 'gotit://'
      } else if (this.$device.isAndroid) {
        window.location = 'gotitapp://'
      }
    },
    getTheApp () {
      if ((this.$device.isAndroid || this.$device.isIos) && this.$store.state.localStorage.shortLink && this.$store.state.localStorage.currentUser != null && this.$store.state.localStorage.currentUser.id > 0) {
        this.$store.dispatch('api/sendLogMessage', 'short_link_clicked').then().catch()
        this.$store.dispatch('api/trackEvent', {
          eventName: 'short_link_clicked'
        }).then().catch()
        window.location = this.$store.state.localStorage.shortLink
        this.$store.commit('localStorage/setShortLink', '')
      } else {
        setTimeout(() => {
          if (this.$device.isIos) {
            window.location = 'https://apps.apple.com/sg/app/gotit-social-shopping/id1003017825'
          } else {
            window.location = 'https://xj45h.app.goo.gl/tWib'
          }
        }, 25)
        if (this.$device.isIos) {
          window.location = 'gotit://'
        } else if (this.$device.isAndroid) {
          window.location = 'gotitapp://'
        }
      }
    },
    showChat () {
      this.$store.dispatch('api/sendLogMessage', 'chat_clicked').then().catch()
      const data = {
        type: 'CART_CHAT',
        user: {
          id: 344535,
          profilePhotoThumbnail: 'https://s3.amazonaws.com/app.gotit.parse/tfss-72809fa4-553f-4473-91f5-af9b31802200-thumbnail.png',
          name: 'GOTit Customer service'
        }
      }
      this.$root.$emit('show_chat', data)
    },
    checkInternetState () {
      this.appOnline = navigator.onLine
      this.showSnackbar = !navigator.onLine
    },
    generateParams (params) {
      let result = ''
      Object.getOwnPropertyNames(params).forEach((param) => {
        if (params[param] !== null && params[param] !== undefined && param !== 'endpoint') {
          result += param + '=' + encodeURIComponent(params[param]) + '&'
        }
      })
      return result.slice(0, -1)
    },
    startCreditTimer () {
      if (this.user && this.user.extra.credit) {
        if (this.$store.state.localStorage.firstVisit) {
          const date1 = new Date(this.$store.state.localStorage.firstVisit)
          const currentDate = new Date()
          const diffTime = Math.abs(date1.valueOf() - currentDate.valueOf())
          const days = diffTime / (24 * 60 * 60 * 1000)
          // eslint-disable-next-line no-constant-condition
          if (days >= 1 && !((this.$store.state.api.globals.creditTimer || this.$store.state.localStorage.activationTimerTimestamp) && this.$store.state.localStorage.timerShowedupInCart)) {
            let timeLeft
            let timeLeftJson
            const activationCreditActive = (this.$store.state.api.globals.creditTimer || this.$store.state.localStorage.activationTimerTimestamp) && this.$store.state.localStorage.timerShowedupInCart
            if (activationCreditActive) {
              timeLeft = this.getDiff(parseInt(this.$store.state.api.globals.creditTimer || this.user.extra.creditValidThru))
              timeLeftJson = this.getDiffJson(parseInt(this.$store.state.api.globals.creditTimer || this.user.extra.creditValidThru))
            } else {
              timeLeft = this.getDiff(parseInt(this.user.extra.creditValidThru))
              timeLeftJson = this.getDiffJson(parseInt(this.user.extra.creditValidThru))
            }

            if (timeLeftJson.days === 1) {
              timeLeft = 'Last day'
            } else if (timeLeftJson.days > 1) {
              timeLeft = timeLeftJson.days + ' days left'
            }
            timeLeftJson.creditValidThru = this.user.extra.creditValidThru
            this.$root.$emit('credit_time_left', timeLeft, timeLeftJson)
            // no need interval while days more the 1
            setTimeout(() => {
              if (this.$store.state.api.globals.activation) {
                setInterval(() => {
                  const activationCreditActive = (this.$store.state.api.globals.creditTimer || this.$store.state.localStorage.activationTimerTimestamp) && this.$store.state.localStorage.timerShowedupInCart
                  if (activationCreditActive) {
                    timeLeft = this.getDiff(parseInt(this.$store.state.api.globals.creditTimer || this.user.extra.creditValidThru))
                    timeLeftJson = this.getDiffJson(parseInt(this.$store.state.api.globals.creditTimer || this.user.extra.creditValidThru))
                  } else {
                    timeLeft = this.getDiff(parseInt(this.user.extra.creditValidThru))
                    timeLeftJson = this.getDiffJson(parseInt(this.user.extra.creditValidThru))
                  }

                  if (timeLeftJson.days === 1) {
                    timeLeft = 'Last day'
                  } else if (timeLeftJson.days > 1) {
                    timeLeft = timeLeftJson.days + ' days left'
                  }
                  timeLeftJson.creditValidThru = this.user.extra.creditValidThru
                  this.$root.$emit('credit_time_left', timeLeft, timeLeftJson)
                }, 1000)
              }
            }, 1000 * 5)
          } else {
            const date1 = new Date(this.$store.state.localStorage.firstVisit)
            if ((this.$store.state.api.globals.creditTimer || this.$store.state.localStorage.activationTimerTimestamp) && this.$store.state.localStorage.timerShowedupInCart) {
              date1.setTime(this.$store.state.api.globals.creditTimer || this.$store.state.localStorage.activationTimerTimestamp)
            }
            const diffTime = Math.abs(date1.valueOf() - new Date().valueOf())
            const a = new Date()
            if ((this.$store.state.api.globals.creditTimer || this.$store.state.localStorage.activationTimerTimestamp) && this.$store.state.localStorage.timerShowedupInCart) {
              a.setTime(this.$store.state.api.globals.creditTimer || this.$store.state.localStorage.activationTimerTimestamp)
            } else {
              a.setTime(a.getTime() - diffTime + (1000 * 60 * 60 * 24))
            }
            let firstVisit = a.getTime()
            const timeLeft = this.getDiff(parseInt(firstVisit))
            const timeLeftJson = this.getDiffJson(parseInt(firstVisit))
            this.$root.$emit('credit_time_left', timeLeft, timeLeftJson)
            setInterval(() => {
              const diffTime = Math.abs(date1.valueOf() - new Date().valueOf())
              const a = new Date()
              if ((this.$store.state.api.globals.creditTimer || this.$store.state.localStorage.activationTimerTimestamp) && this.$store.state.localStorage.timerShowedupInCart) {
                a.setTime(this.$store.state.api.globals.creditTimer || this.$store.state.localStorage.activationTimerTimestamp)
              } else {
                a.setTime(a.getTime() - diffTime + (1000 * 60 * 60 * 24))
              }
              firstVisit = a.getTime()
              const timeLeft = this.getDiff(parseInt(firstVisit))
              const timeLeftJson = this.getDiffJson(parseInt(firstVisit))
              this.$root.$emit('credit_time_left', timeLeft, timeLeftJson)
            }, 1000)
          }
        }
      }
    },
    startSpacialTimer () {
      let date1 = new Date()
      date1.setTime(this.$store.state.api.globals.globalTimer)
      date1 = new Date(date1.toLocaleString('en', { timeZone: 'Asia/Jerusalem' }))
      let currentDate = new Date()
      if (date1 - currentDate > 0) {
        let a = new Date()
        a.setTime(this.$store.state.api.globals.globalTimer)
        a = new Date(date1.toLocaleString('en', { timeZone: 'Asia/Jerusalem' }))
        a.setTime(date1.setTime(this.$store.state.api.globals.globalTimer))
        let firstVisit = a.getTime()
        const timeLeft = this.getDiff(parseInt(firstVisit))
        const timeLeftJson = this.getDiffJson(parseInt(firstVisit))
        timeLeftJson.creditValidThru = date1.getTime()
        this.$root.$emit('spacial_credit_time_left', timeLeft, timeLeftJson)
        const interval = setInterval(() => {
          currentDate = new Date()
          const diffTime = Math.abs(currentDate.valueOf() - date1.valueOf())
          const a = new Date()
          a.setTime(a.getTime() - diffTime)
          firstVisit = a.getTime()
          const timeLeft = this.getDiff(parseInt(firstVisit))
          const timeLeftJson = this.getDiffJson(parseInt(firstVisit))
          timeLeftJson.creditValidThru = date1.getTime()
          this.$root.$emit('spacial_credit_time_left', timeLeft, timeLeftJson)
          if (date1 - currentDate < 0) {
            if (this.$store.state.localStorage.timerShowedupInCart) {
              this.$store.commit('localStorage/setTimerShowedupInCart', false)
              this.$store.commit('localStorage/setActivationTimerTimestamp', '')
            } else {
              clearInterval(interval)
            }
          }
        }, 1000)
      } else {

      }
    },
    startActivationTimer () {
      const date1 = new Date()
      if (this.$store.state.localStorage.activationTimerTimestamp) {
        date1.setTime(this.$store.state.localStorage.activationTimerTimestamp)
      }
      let currentDate = new Date()
      if (date1 - currentDate > 0) {
        const diffTime = Math.abs(date1.valueOf() - currentDate.valueOf())
        const a = new Date()
        if (this.$store.state.localStorage.activationTimerTimestamp) {
          date1.setTime(this.$store.state.localStorage.activationTimerTimestamp)
        }
        a.setTime(a.getTime() - diffTime)
        let firstVisit = a.getTime()
        const timeLeft = this.getDiff(parseInt(firstVisit))
        const timeLeftJson = this.getDiffJson(parseInt(firstVisit))
        timeLeftJson.creditValidThru = date1.getTime()
        this.$root.$emit('activation_timer', timeLeft, timeLeftJson)
        const interval = setInterval(() => {
          currentDate = new Date()
          if (this.$store.state.localStorage.activationTimerTimestamp) {
            date1.setTime(this.$store.state.localStorage.activationTimerTimestamp)
          }
          const diffTime = Math.abs(currentDate.valueOf() - date1.valueOf())
          const a = new Date()
          a.setTime(a.getTime() - diffTime)
          firstVisit = a.getTime()
          const timeLeft = this.getDiff(parseInt(firstVisit))
          const timeLeftJson = this.getDiffJson(parseInt(firstVisit))
          timeLeftJson.creditValidThru = date1.getTime()
          this.$root.$emit('activation_timer', timeLeft, timeLeftJson)
          if (date1 - currentDate < 0) {
            this.$store.commit('localStorage/setActivationTimestamp', null)
            this.$store.commit('localStorage/setActivationTimerTimestamp', '')
            clearInterval(interval)
          }
        }, 1000)
      }
    },
    getDiff (date, dateToCompare) {
      const date1 = new Date(date)
      const currentDate = dateToCompare || new Date()
      const diffTime = Math.abs(date1.valueOf() - currentDate.valueOf())
      const days = diffTime / (24 * 60 * 60 * 1000)
      let hours = (days % 1) * 24 + (Math.floor(days) * 24)
      let minutes = (hours % 1) * 60
      let secs = (minutes % 1) * 60
      hours = Math.floor(hours)
      minutes = Math.floor(minutes)
      secs = Math.floor(secs)
      if (hours < 10) {
        hours = '0' + hours
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (secs < 10) {
        secs = '0' + secs
      }
      return `${hours}:${minutes}:${secs}`
    },
    getDiffJson (date, dateToCompare) {
      const date1 = new Date(date)
      const currentDate = dateToCompare || new Date()
      const diffTime = Math.abs(date1.valueOf() - currentDate.valueOf())
      const days = diffTime / (24 * 60 * 60 * 1000)
      let hours = (days % 1) * 24 + (Math.floor(days) * 24)
      let minutes = (hours % 1) * 60
      let secs = (minutes % 1) * 60
      hours = Math.floor(hours)
      minutes = Math.floor(minutes)
      secs = Math.floor(secs)
      if (hours < 10) {
        hours = '0' + hours
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (secs < 10) {
        secs = '0' + secs
      }
      return { days: Math.floor(days), hours, minutes, secs }
    },
    sendAdClick () {
      if (this.$route.query.fbclid || this.$route.query.utm_campaign || this.$route.query.utm_source || this.$route.query.campaignId) {
        const adRequest = {
          campaignId: this.$route.query.campaignId || this.$route.query.utm_campaign || this.$route.query.utm_source || '',
          link: window.location.href,
          userId: this.user.id
        }
        this.$store.dispatch('api/adClick', adRequest).then().catch()
        this.$store.dispatch('api/trackEvent', {
          eventName: 'ad_click',
          params: {
            link: window.location.href,
            path: this.$route.path,
            userId: this.user.id
          }
        }).then().catch()
      }
      if (this.user.id < 0 && this.tabs.length === 5) {
        this.tabs.splice(3, 1)
      }
    },
    onTabClick (tab) {
      this.lastTabName = tab.name
      this.navigate(tab.path)
    },
    isSelected (tab) {
      if (this.lastTabName) {
        return this.lastTabName === tab.name
      } else if (this.$router.currentRoute.name === 'index') {
        return tab.path === '/'
      } else {
        return tab.path.includes(this.$router.currentRoute.name)
      }
    },
    registerGotit () {
      this.drawer = false
      this.$root.$emit('toggle_login_menu', {
        state: 'register'
      })
    },
    navigate (path) {
      this.$router.push({
        path
      })
    },
    processCategories (categoryTree, categories) {
      const map = {}
      const main = {}
      main.name = 'All'
      const categorieNames = {}
      Object.values(categories).forEach((category) => {
        categorieNames[category.id] = category.name
      })
      const categoryEntries = {}
      const categoryEntriesList = []
      Object.values(categoryTree).forEach((categoryTree) => {
        const categoryEntry = {}
        categoryEntry.name = categorieNames[categoryTree.categoryId]
        categoryEntry.id = categoryTree.categoryId + ''
        categoryEntries[categoryTree.id] = { first: categoryEntry, second: categoryTree.parentId }
        categoryEntriesList.push({ first: categoryEntry, second: categoryTree.parentId })
        map[categoryEntry.id] = categoryEntry.name
      })
      categoryEntriesList.forEach((categoryEntryPair) => {
        if (categoryEntryPair.second != null) {
          const parentCategoryPair = categoryEntries[categoryEntryPair.second]
          if (parentCategoryPair) {
            const parentCategoryEntry = parentCategoryPair.first
            if (!parentCategoryEntry.children) {
              parentCategoryEntry.children = []
              parentCategoryEntry.children = []
            }
            parentCategoryEntry.children.push(categoryEntryPair.first)
            parentCategoryEntry.children.push(categoryEntryPair.first)
          }
        } else {
          if (!main.children) {
            main.children = []
            main.children = []
          }
          main.children.push(categoryEntryPair.first)
          main.children.push(categoryEntryPair.first)
        }
      })
      this.$store.commit('localStorage/setCategoryTree', main)
      this.$store.commit('localStorage/setCategoryMap', map)
      return main
    },
    addPaths (categoryTree) {
      if (categoryTree.children) {
        if (!categoryTree.path) {
          categoryTree.path = '/search?category=' + categoryTree.id
        }
        if (!categoryTree.breadcrumb) {
          categoryTree.breadcrumb = []
          categoryTree.breadcrumb.push(categoryTree.name)
        }
        for (const child of categoryTree.children) {
          if (!child.children) {
            child.path = '/search?category=' + child.id
            if (!child.breadcrumb) {
              child.breadcrumb = []
              categoryTree.breadcrumb.push(child.name)
            }
          }
        }
        for (const child of categoryTree.children) {
          this.addPaths(child)
        }
      } else if (!categoryTree.path) {
        categoryTree.path = '/search?category=' + categoryTree.id
        if (!categoryTree.breadcrumb) {
          categoryTree.breadcrumb = []
          categoryTree.breadcrumb.push(categoryTree.name)
        }
      }
    }
  }
}
</script>
