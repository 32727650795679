<template>
  <transition v-if="showModal" name="modal">
    <div class="modal-mask" @click.stop="dismiss()">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="main">
            <div class="left_credit_timer" @click.stop="nothing()">
              <div class="timer_title">
                <div class="clock icon">
                  <img v-lazy-load data-src="/clock.png">
                </div>
                <h3>{{ title }}</h3>
                <div class="close icon" @click.stop="dismiss()">
                  <img v-lazy-load data-src="/icons/close.svg">
                </div>
              </div>
              <div class="message">
                {{ message }}
              </div>
              <div class="timer_container">
                <vue-ellipse-progress
                  class="progress_bar"
                  color="#ed4956"
                  :progress="progress"
                  :size="$device.isMobileOrTablet ? 170 : 200"
                  :legend-formatter="({ currentValue }) => `<div class='inner_timer'>${oldTimer}</div><div class='times'>Hrs&nbsp;&nbsp;&nbsp;Mins&nbsp;&nbsp;Secs</div>`"
                  :reverse="true"
                />
              </div>
              <div class="gotit_button apply_now" @click.stop="onOkClick()">
                {{ activation.button }}
              </div>
            </div>
            <!--            <div class="right_credit_timer" @click.stop="nothing()">-->
            <!--              <div class="timer" v-if="false">-->
            <!--                <div class="timer_box">-->
            <!--                  <div>{{ timeLeft.days }}</div>-->
            <!--                  <div>days</div>-->
            <!--                </div>-->
            <!--                <div class="timer_box">-->
            <!--                  <div>{{ timeLeft.hours }}</div>-->
            <!--                  <div>hours</div>-->
            <!--                </div>-->
            <!--                <div class="timer_box">-->
            <!--                  <div>{{ timeLeft.minutes }}</div>-->
            <!--                  <div>minutes</div>-->
            <!--                </div>-->
            <!--                <div class="timer_box">-->
            <!--                  <div>{{ timeLeft.secs }}</div>-->
            <!--                  <div>seconds</div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import utils from '~/mixins/utils'

export default {
  name: 'CreditTimerDialog',
  mixins: [utils],
  data () {
    return {
      alreadyLaunched: [],
      showModal: false,
      loading: false,
      progress: 0,
      oldTimer: '',
      title: '',
      message: '',
      timeLeft: {},
      start: new Date(),
      now: new Date(),
      end: new Date(),
      timeout: null,
      activation: null
    }
  },
  mounted () {
    this.$root.$on('show_credit_timer_dialog', (args) => {
      if (this.$store.state.localStorage.numOfActivationPresentedToday !== undefined && this.$store.state.localStorage.numOfActivationPresentedToday >= 2) {
        if (this.getDiffJson(parseInt(this.$store.state.localStorage.lastActivationPresented)).days === 0) {
          return
        } else {
          this.$store.commit('localStorage/resetLastActivationPresented', { lastActivationPresented: new Date().getTime() })
          this.$store.commit('localStorage/resetNumOfActivationPresentedToday')
        }
        // do not show activation popup more than twice a day
      }
      if (args.activation) {
        if (this.alreadyLaunched.includes(args.activation.type)) {
          return
        } else {
          this.alreadyLaunched.push(args.activation.type)
        }
        this.activation = args.activation
        if (this.timeout) {
          clearTimeout(this.timeout)
          this.timeout = null
        }
        this.title = args.activation.title
        this.message = args.activation.message
        this.timeout = setTimeout(() => {
          if (location.pathname !== '/cart') {
            this.$store.commit('localStorage/setActivationPresented', true)
            let timerTimestamp = ''
            // args.activation.timer = 1
            if (args.activation.timer > 0) {
              timerTimestamp = (new Date().getTime() + (1000 * 60 * args.activation.timer)).toString()
              args.activation.timestamp = timerTimestamp
            } else if (args.activation.timestamp > 0) {
              timerTimestamp = args.activation.timestamp.toString()
            }

            this.$store.commit('localStorage/setActivationTimestamp', { type: args.activation.type, timestamp: timerTimestamp })
            this.$store.commit('localStorage/setActivationTimerTimestamp', timerTimestamp)

            if (args.activation.timestamp - new Date().getTime() < 0) {
              timerTimestamp = (new Date().getTime() + (1000 * 60 * args.activation.timer)).toString()
              this.$store.commit('localStorage/setActivationTimestamp', { type: args.activation, timestamp: timerTimestamp })
              this.$store.commit('localStorage/setActivationTimerTimestamp', timerTimestamp)
            }
            this.startActivationTimer()
            this.presentDialog()
          } else {
            this.$store.commit('localStorage/setActivationToPresent', args.activation)
            // args.activation
          }
        }, 1000 * args.activation.time)
        // }, args.delay || (args.activation.type === 'session_start' ? (1000 * 20) : (1000 * args.activation.time)))
      } else {
        this.activation = null
        this.presentDialog()
      }
    })
  },
  methods: {
    getCurrentUser () {
      return this.$store.state.localStorage.currentUser
    },
    nothing () {
    },
    onOkClick () {
      this.dismiss()
      if (this.activation.uri && this.activation.uri === '12') {
        this.$router.push({
          path: '/cart'
        })
      }
    },
    dismiss () {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.showModal = false
    },
    presentDialog () {
      this.$store.commit('localStorage/increaseNumOfActivationPresentedToday')
      this.$store.commit('localStorage/setLastActivationPresented', { lastActivationPresented: new Date().getTime() })
      this.loading = true
      this.showModal = true
      this.loading = false
      this.now = new Date()
      this.start = new Date()
      this.end = new Date()
      this.start.setTime(this.start.getTime() - 1000 * 60 * 3)
      // end.setTime(timeLeft.creditValidThru)
      this.end.setTime(this.now.getTime() + 1000 * 60 * 2)
      if (this.activation) {
        this.$store.dispatch('api/activationApi', { api: this.activation.api }).then().catch()
      }
    },
    renderTimer (oldTimer, timeLeft) {
      this.oldTimer = oldTimer
      this.timeLeft = timeLeft
      this.now = new Date()
      const start = new Date()
      if (this.$store.state.localStorage.activationTimerTimestamp) {
        start.setTime(this.$store.state.localStorage.activationTimerTimestamp)
      }
      const end = new Date()
      if (this.activation) {
        end.setTime(start.getTime() + this.activation.timer * (1000 * 60))
      }
      const q = Math.abs(this.now - start)
      const d = Math.abs(end - start)
      this.progress = Math.round((q / d) * 100)
    },
    startActivationTimer () {
      const date1 = new Date()
      if (this.$store.state.localStorage.activationTimerTimestamp) {
        date1.setTime(this.$store.state.localStorage.activationTimerTimestamp)
      }
      let currentDate = new Date()
      if (date1 - currentDate > 0) {
        const diffTime = Math.abs(date1.valueOf() - currentDate.valueOf())
        const a = new Date()
        if (this.$store.state.localStorage.activationTimerTimestamp) {
          date1.setTime(this.$store.state.localStorage.activationTimerTimestamp)
        }
        a.setTime(a.getTime() - diffTime)
        let firstVisit = a.getTime()
        const timeLeft = this.getDiff(parseInt(firstVisit))
        const timeLeftJson = this.getDiffJson(parseInt(firstVisit))
        timeLeftJson.creditValidThru = date1.getTime()
        this.renderTimer(timeLeft, timeLeftJson)
        // this.$root.$emit('activation_timer', timeLeft, timeLeftJson)
        const interval = setInterval(() => {
          currentDate = new Date()
          if (this.$store.state.localStorage.activationTimerTimestamp) {
            date1.setTime(this.$store.state.localStorage.activationTimerTimestamp)
          }
          const diffTime = Math.abs(currentDate.valueOf() - date1.valueOf())
          const a = new Date()
          a.setTime(a.getTime() - diffTime)
          firstVisit = a.getTime()
          const timeLeft = this.getDiff(parseInt(firstVisit))
          const timeLeftJson = this.getDiffJson(parseInt(firstVisit))
          timeLeftJson.creditValidThru = date1.getTime()
          this.renderTimer(timeLeft, timeLeftJson)
          // this.$root.$emit('activation_timer', timeLeft, timeLeftJson)
          if (date1 - currentDate < 0) {
            this.$store.commit('localStorage/setActivationTimestamp', null)
            this.$store.commit('localStorage/setActivationTimerTimestamp', '')
            clearInterval(interval)
          }
        }, 1000)
      }
    },
    getDiff (date, dateToCompare) {
      const date1 = new Date(date)
      const currentDate = dateToCompare || new Date()
      const diffTime = Math.abs(date1.valueOf() - currentDate.valueOf())
      const days = diffTime / (24 * 60 * 60 * 1000)
      let hours = (days % 1) * 24 + (Math.floor(days) * 24)
      let minutes = (hours % 1) * 60
      let secs = (minutes % 1) * 60
      hours = Math.floor(hours)
      minutes = Math.floor(minutes)
      secs = Math.floor(secs)
      if (hours < 10) {
        hours = '0' + hours
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (secs < 10) {
        secs = '0' + secs
      }
      return `${hours}:${minutes}:${secs}`
    },
    getDiffJson (date, dateToCompare) {
      const date1 = new Date(date)
      const currentDate = dateToCompare || new Date()
      const diffTime = Math.abs(date1.valueOf() - currentDate.valueOf())
      const days = diffTime / (24 * 60 * 60 * 1000)
      let hours = (days % 1) * 24 + (Math.floor(days) * 24)
      let minutes = (hours % 1) * 60
      let secs = (minutes % 1) * 60
      hours = Math.floor(hours)
      minutes = Math.floor(minutes)
      secs = Math.floor(secs)
      if (hours < 10) {
        hours = '0' + hours
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (secs < 10) {
        secs = '0' + secs
      }
      return { days: Math.floor(days), hours, minutes, secs }
    }
  }
}
</script>

<style scoped>

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .modal-container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    width: 1150px;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .mobile_design .modal-wrapper, .mobile_design .modal-mask {
    display: flex;
    align-items: center;
    padding: 1em;
  }

  .mobile_design .modal-container {
    width: 100%;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .modal-container {
    height: 460px;
    width: 800px;
    padding: unset;
    border-radius: 5px;
  }

  .main {
    width: 100%;
    overflow: scroll;
    height: 460px;
    color: #3d6b64;
  }

  .users_title {
    border-bottom: 1px solid #7F828B;
    padding: 0.5em;
  }

  .user {
    display: flex;
    padding: 0.5em;
    align-items: center;
    font-size: 14px;
  }

  .user_image {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
  }

  .username {
    flex-grow: 1;
    padding-left: 1em;
    text-align: left;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .usr {
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .name {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .mobile_design .main {
    color: black
  }

  .mobile_design .follow_button_wrapper {
    height: 30px;
  }
  .mobile_design .follow_button {
    height: 30px;
    background: white !important;
    color: #b53a44;
    border: 1px solid #b53a44;
    border-radius: 2px;
    width: 90px;
    line-height: 28px;
  }

  .main > div {
    float: left;
    width: 50%;
  }

  .mobile_design .main > div {
    float: unset;
    height: 100%;
    width: unset;
  }
  .timer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .timer > div {
    /*flex-grow: 1;*/
    width: calc(100%/5);
  }

  .logo {
    width: 50%;
  }

  .left_credit_timer {
    /*background-color: rgb(218,241, 250);*/
    background-color: white;
    padding: 1em;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .right_credit_timer {
    /*background-color: rgb(195, 226, 239);*/
    background-color: white;
    padding: 2em;
    height: 100%;
  }

  .left_credit_timer img {
    margin-bottom: 3em;
  }

  .mobile_design .left_credit_timer img {
    margin-bottom: 1.5em;
  }

  .timer_box {
    background-color: rgb(218,241, 250);
    /*margin: 0.5em;*/
    width: 3em;
    border-radius: 0.2em;
    padding: 0.5em;
  }

  .timer_box > div:first-child {
    font-size: 1.7em;
    font-weight: bold;
  }

  .progress_bar {
    margin-top: 2em;
  }

  .mobile_design .timer_title {
    /*font-size: 1em;*/
    /*font-weight: bold;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #ed4956;
  }

  .mobile_design .message {
    font-size: 1.4em;
    color: grey;
    margin-top: 2em;
  }
  .mobile_design .timer_container {
    flex-grow: 1;
  }

  .mobile_design .icon {
    width: 2em !important;
    height: 2em !important;
    filter: invert(55%) sepia(60%) saturate(5951%) hue-rotate(328deg) brightness(95%) contrast(95%);
  }

  .mobile_design .gotit_button {
    font-size: 1.4em;
  }
</style>
